import { showGoogleReview, openPaySuperNow } from '../handlers'
import { setDisplayInviteClient } from '../reducers/subscriptionSlice'
import { postData } from '@payroller/pds'

angular
  .module('starter.services')
  .factory(
    'STPService',
    function (
      Service,
      $rootScope,
      GLOBALS,
      Analytics,
      $state,
      $ngRedux,
      Http,
      HttpPromiseAndLocalStorage,
      API_ENDPOINT,
      Http2,
      $timeout,
      CompanyAction,
      employeeModalService,
      DatepickerService
    ) {
      const openPaySuperProps = {
        $rootScope,
        $ngRedux,
        Service,
        $state,
        Http,
        Http2,
        $timeout,
        CompanyAction,
        employeeModalService,
        DatepickerService,
        Analytics
      }

      const openStpErrorMessageModal = function (options) {
        Service.modal(
          'stpErrorMessageModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }
              $scope.errorCode = options.errorCode
              $scope.errorShort = options.errorShort
              $scope.errorDetailed = options.errorDetailed
            }
          ],
          { size: 'lg' }
        )
      }

      const openStpValidationErrorModal = function (errorObj) {
        Service.modal(
          'stpValidationErrorModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
                openPaySuperNow(openPaySuperProps)
              }
              $scope.generalErrorText = errorObj.generalErrorText
              $scope.companyValidationError = errorObj.companyErrors
              $scope.employeeValidationError = errorObj.employeeErrors
            }
          ],
          { size: 'md' }
        )
      }

      const verify = function (options, successCallback) {
        // verification
        // your company verification

        var company = options.company
        var employees = options.employees
        var finalStpEmployeeArray = options.finalStpEmployeeArray

        var generalErrorText

        if (!company.stp || !company.stp.on || !company.si) {
          generalErrorText = 'You need to enable STP in Settings to lodge the STP report to ATO.'
        }

        var companyValidationError = []
        if (!company.abn && !company.wpn) {
          companyValidationError.push('Company ABN is required.')
        }
        if (company.abn && !Service.isABN(company.abn)) {
          companyValidationError.push('ABN is invalid.')
        }
        if (!company.isActiveAbn) {
          companyValidationError.push('ABN is not active.')
        }
        if (company.wpn && (company.wpn.substring(0, 2) != '00' || !Service.TFNCheck(company.wpn.substring(2)))) {
          companyValidationError.push('WPN is invalid.')
        }
        var phoneNumber =
          (company.stp.ph ? company.stp.ph.replace(/\D/g, '') : null) ||
          (company.ph ? company.ph.replace(/\D/g, '') : null)
        if (!phoneNumber) {
          companyValidationError.push('Phone number is required.')
        } else {
          if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(phoneNumber)) {
            companyValidationError.push('Phone number is invalid.')
          }
          if (phoneNumber.length > 16) {
            companyValidationError.push('Phone number cannot exceed 16 characters.')
          }
        }

        var textRegex = /^[a-zA-Z0-9!@#$%^&*)([\]?/;:'",+=._-\s]+$/i

        var address_1 = company.a_1 ? company.a_1.replace(/[^a-zA-Z0-9 ]/g, '') : null
        if (!address_1) {
          companyValidationError.push('Address Line 1 is required.')
        } else {
          if (address_1.length > 38) {
            companyValidationError.push('Address Line 1 cannot exceed 38 characters.')
          }
          if (!textRegex.test(address_1)) {
            companyValidationError.push('Address Line 1 is invalid. Some special characters are not allowed.')
          }
        }
        if (company.a_2 && company.a_2.length > 38) {
          companyValidationError.push('Address Line 2 cannot exceed 38 characters.')
        }
        if (company.a_2 && !textRegex.test(company.a_2)) {
          companyValidationError.push('Address Line 2 is invalid. Some special characters are not allowed.')
        }
        if (!company.a_c) {
          companyValidationError.push('Suburb is required.')
        }
        if (company.a_c && company.a_c.length > 46) {
          companyValidationError.push('Suburb cannot exceed 46 characters.')
        }
        if (company.a_c && !textRegex.test(company.a_c)) {
          companyValidationError.push('Suburb is invalid. Some special characters are not allowed.')
        }
        if (!company.a_s) {
          companyValidationError.push('State is required.')
        }
        if (company.a_s && !textRegex.test(company.a_s)) {
          companyValidationError.push('State is invalid. Some special characters are not allowed.')
        }
        if (!company.a_p) {
          companyValidationError.push('Postcode is required.')
        }
        if (company.a_p && !/^[0-9]{4}$/.test(company.a_p)) {
          companyValidationError.push('Postcode is invalid.')
        }

        // employees verification
        let employeeValidationError = []

        if (finalStpEmployeeArray) {
          finalStpEmployeeArray.map((eachFinalAdjust) => {
            const employee = eachFinalAdjust.employeeData
            const stpFigures = eachFinalAdjust.stpFigures

            var errorArray = []
            if (!employee.tx.no) {
              errorArray.push('Tax file is required.')
            }
            if (
              employee.tx.no &&
              !(
                Service.TFNCheck(employee.tx.no) ||
                employee.tx.no == '000000000' ||
                employee.tx.no == '111111111' ||
                employee.tx.no == '333333333' ||
                employee.tx.no == '444444444' ||
                employee.tx.no == '987654321'
              )
            ) {
              errorArray.push('Tax file number is invalid.')
            }
            // if(!employee.ph){ errorArray.push("Phone number is required!"); }
            if (!employee.f) {
              errorArray.push('First name is required.')
            }
            if (employee.f && !textRegex.test(employee.f)) {
              errorArray.push('First name is invalid. Some special characters are not allowed.')
            }
            if (!employee.e || !Service.validateEmail(employee.e)) {
              errorArray.push('Email address is invalid.')
            }
            if (!employee.l) {
              errorArray.push('Last name is required.')
            }
            if (employee.l && !textRegex.test(employee.l)) {
              errorArray.push('Last name is invalid. Some special characters are not allowed.')
            }

            var employee_address_1 = employee.a_1 ? employee.a_1.replace(/[^a-zA-Z0-9 ]/g, '') : null
            if (!employee_address_1) {
              errorArray.push('Address Line 1 is required.')
            } else {
              if (employee_address_1.length > 38) {
                errorArray.push('Address Line 1 cannot exceed 38 characters.')
              }
              if (!textRegex.test(employee_address_1)) {
                errorArray.push('Address Line 1 is invalid. Some special characters are not allowed.')
              }
            }
            if (employee.a_2 && employee.a_2.length > 38) {
              errorArray.push('Address Line 2 cannot exceed 38 characters.')
            }
            if (employee.a_2 && !textRegex.test(employee.a_2)) {
              errorArray.push('Address Line 2 is invalid. Some special characters are not allowed.')
            }
            if (!employee.a_c) {
              errorArray.push('Suburb is required.')
            }
            if (employee.a_c && employee.a_c.length > 46) {
              errorArray.push('Suburb cannot exceed 46 characters.')
            }
            if (employee.a_c && !textRegex.test(employee.a_c)) {
              errorArray.push('Suburb is invalid. Some special characters are not allowed.')
            }
            if (!employee.a_s) {
              errorArray.push('State is required.')
            }
            if (employee.a_s && !textRegex.test(employee.a_s)) {
              errorArray.push('State is invalid. Some special characters are not allowed.')
            }
            if (!employee.a_p) {
              errorArray.push('Postcode is required.')
            }
            if (employee.a_p && !/^[0-9]{4}$/.test(employee.a_p)) {
              errorArray.push('Postcode is invalid.')
            }

            if (stpFigures.grossPayment < 0) {
              errorArray.push('Gross Payments cannot be negative.')
            }
            if (stpFigures.payg < 0) {
              errorArray.push('Tax withheld cannot be negative.')
            }

            if (stpFigures.allowanceObj.car < 0) {
              errorArray.push('Car allowance cannot be negative.')
            }
            if (stpFigures.allowanceObj.transport < 0) {
              errorArray.push('Transport allowance cannot be negative.')
            }
            if (stpFigures.allowanceObj.laundry < 0) {
              errorArray.push('Laundry allowance cannot be negative.')
            }
            if (stpFigures.allowanceObj.meals < 0) {
              errorArray.push('Meals allowance cannot be negative.')
            }
            if (stpFigures.allowanceObj.travel < 0) {
              errorArray.push('Travel allowance cannot be negative.')
            }
            if (stpFigures.allowanceObj.other < 0) {
              errorArray.push('Other allowance cannot be negative.')
            }
            if (stpFigures.allowanceObj.jobKeeperTopUp < 0) {
              errorArray.push('JobKeeper TopUp cannot be negative.')
            }
            if (stpFigures.deductionObj.fees < 0) {
              errorArray.push('Fees deduction cannot be negative.')
            }
            if (stpFigures.deductionObj.workplaceGiving < 0) {
              errorArray.push('Work giving deduction cannot be negative.')
            }

            if (stpFigures.ordinary < 0) {
              errorArray.push('Ordinary Time Earnings (under Super) cannot be negative.')
            }
            if (stpFigures.super_g < 0) {
              errorArray.push('Super Guarantee cannot be negative.')
            }
            if (stpFigures.super_ec < 0) {
              errorArray.push('Reportable Employer Super Contribution cannot be negative.')
            }
            if (stpFigures.etp_r < 0) {
              errorArray.push('ETP Type R  cannot be negative.')
            }
            if (stpFigures.etp_r_tax < 0) {
              errorArray.push('ETP Type R PAYG cannot be negative.')
            }
            if (stpFigures.etp_o < 0) {
              errorArray.push('ETP Type O cannot be negative.')
            }
            if (stpFigures.etp_o_tax < 0) {
              errorArray.push('ETP Type O PAYG cannot be negative.')
            }
            if (stpFigures.lump_sum_a < 0) {
              errorArray.push('Lump Sum A cannot be negative.')
            }
            if (stpFigures.lump_sum_b < 0) {
              errorArray.push('Lump Sum B cannot be negative.')
            }
            if (stpFigures.lump_sum_d < 0) {
              errorArray.push('Lump Sum D cannot be negative.')
            }
            if (stpFigures.lump_sum_e < 0) {
              errorArray.push('Lump Sum E cannot be negative.')
            }
            if (stpFigures.RFBTaxable < 0) {
              errorArray.push('RFB Taxable cannot be negative.')
            }
            if (stpFigures.RFBExempt < 0) {
              errorArray.push('RFB Exempt cannot be negative.')
            }

            if (errorArray.length > 0) {
              employeeValidationError.push({
                _id: employee._id,
                name: employee.f + ' ' + employee.l,
                errors: errorArray
              })
            }
          })
        } else {
          options.payRun.psl.map((payslip) => {
            const indexforEmployee = Service.findIndexByKeyValue(employees, '_id', payslip.ei)
            const employee = employees[indexforEmployee]

            var errorArray = []
            if (!employee.tx.no) {
              errorArray.push('Tax file is required.')
            }
            if (
              employee.tx.no &&
              !(
                Service.TFNCheck(employee.tx.no) ||
                employee.tx.no == '000000000' ||
                employee.tx.no == '111111111' ||
                employee.tx.no == '333333333' ||
                employee.tx.no == '444444444' ||
                employee.tx.no == '987654321'
              )
            ) {
              errorArray.push('Tax file number is invalid.')
            }
            // if(!employee.ph){ errorArray.push("Phone number is required!"); }
            if (!employee.f) {
              errorArray.push('First name is required.')
            }
            if (employee.f && !textRegex.test(employee.f)) {
              errorArray.push('First name is invalid. Some special characters are not allowed.')
            }
            if (!employee.e || !Service.validateEmail(employee.e)) {
              errorArray.push('Email address is invalid.')
            }
            if (!employee.l) {
              errorArray.push('Last name is required.')
            }
            if (employee.l && !textRegex.test(employee.l)) {
              errorArray.push('Last name is invalid. Some special characters are not allowed.')
            }

            var employee_address_1 = employee.a_1 ? employee.a_1.replace(/[^a-zA-Z0-9 ]/g, '') : null
            if (!employee_address_1) {
              errorArray.push('Address Line 1 is required.')
            } else {
              if (employee_address_1.length > 38) {
                errorArray.push('Address Line 1 cannot exceed 38 characters.')
              }
              if (!textRegex.test(employee_address_1)) {
                errorArray.push('Address Line 1 is invalid. Some special characters are not allowed.')
              }
            }
            if (employee.a_2 && employee.a_2.length > 38) {
              errorArray.push('Address Line 2 cannot exceed 38 characters.')
            }
            if (employee.a_2 && !textRegex.test(employee.a_2)) {
              errorArray.push('Address Line 2 is invalid. Some special characters are not allowed.')
            }
            if (!employee.a_c) {
              errorArray.push('Suburb is required.')
            }
            if (employee.a_c && employee.a_c.length > 46) {
              errorArray.push('Suburb cannot exceed 46 characters.')
            }
            if (employee.a_c && !textRegex.test(employee.a_c)) {
              errorArray.push('Suburb is invalid. Some special characters are not allowed.')
            }
            if (!employee.a_s) {
              errorArray.push('State is required.')
            }
            if (employee.a_s && !textRegex.test(employee.a_s)) {
              errorArray.push('State is invalid. Some special characters are not allowed.')
            }
            if (!employee.a_p) {
              errorArray.push('Postcode is required.')
            }
            if (employee.a_p && !/^[0-9]{4}$/.test(employee.a_p)) {
              errorArray.push('Postcode is invalid.')
            }

            if (payslip.s_ec < 0) {
              errorArray.push('Employer Super Contribution cannot be negative.')
            }
            if (payslip.s_ss < 0) {
              errorArray.push('Salary Sacrifice Super cannot be negative.')
            }

            if (errorArray.length > 0) {
              employeeValidationError.push({
                _id: employee._id,
                name: employee.f + ' ' + employee.l,
                errors: errorArray
              })
            }
          })
        }

        if (
          (generalErrorText || companyValidationError.length > 0 || employeeValidationError.length > 0) &&
          !options.bulkSTP
        ) {
          // openStpValidationErrorModal(companyValidationError, employeeValidationError);
          openStpValidationErrorModal({
            generalErrorText: generalErrorText,
            companyErrors: companyValidationError,
            employeeErrors: employeeValidationError
          })
        } else {
          // 바로 payRollCtrl 에서 호출될때는 payRunId를 같이 보내고
          // 이미 만들어진 payrun modal에서 따로 보낼때는 payRunId를 보내지 않는다.
          // $scope.openStpDeclarationModal({payRunId: payRun._id});
          successCallback({
            generalErrorText: generalErrorText,
            companyErrors: companyValidationError,
            employeeErrors: employeeValidationError
          })
        }
      }

      const declarationModal = function (signatoryName, option, callback, valueInvite = {}) {
        // option : employer, agent, bulkAgent
        if (!signatoryName && GLOBALS.GUI_DEBUG) {
          signatoryName = 'Test Agent'
        }

        var modalFileName
        switch (option) {
          case 'agent':
            modalFileName = 'stpAgentDeclarationModal.html'
            break
          case 'bulkAgent':
            modalFileName = 'stpAgentBulkDeclarationModal.html'
            break
          case 'subscribe':
            modalFileName = 'ModalRequireSubscriptionAgent.html'
            break
          default:
            modalFileName = 'stpEmployerDeclarationModal.html'
            break
        }
        Service.modal(
          modalFileName,
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              Analytics.logEvent('STP Declaration Opened')
              $scope.reactState = {
                helpers: Service,
                HttpPromise: HttpPromiseAndLocalStorage,
                API_ENDPOINT: API_ENDPOINT,
                clientNoSubscription: valueInvite.clientNoSubscription,
                cancel: $scope.cancel
              }
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
                openPaySuperNow(openPaySuperProps)
              }

              $scope.message = () => {
                let message = ''
                if (valueInvite.bulkStpList.length === valueInvite.clientNoSubscription.length) {
                  message = valueInvite.clientNoSubscription.length
                } else {
                  message = `${valueInvite.clientNoSubscription.length} out of ${valueInvite.bulkStpList.length}`
                }
                return message
              }

              $scope.inviteClients = () => {
                Analytics.logEvent('Agent Invite clients', {
                  agent_id: window.localStorage.pr_agent_id
                })
                $ngRedux.dispatch(setDisplayInviteClient(true))
                // cancel()
              }

              $scope.signatoryName = signatoryName
              // if ($rootScope.isAgent){
              // 	$scope.signatoryName = $rootScope.agent && $rootScope.agent.f && $rootScope.agent.l ? ($rootScope.agent.f + " " + $rootScope.user.l) : null;
              // } else {
              // 	$scope.signatoryName = $rootScope.user.f && $rootScope.user.l ? ($rootScope.user.f + " " + $rootScope.user.l) : "";
              // }
              // console.log(new Date(new Date(moment().add(1,"d")).setHours(0,0,0,0)));
              // $scope.signatoryDate = $filter("date")(new Date(), "yyyy-MM-dd");

              const NO_PAYWALL = new Set(['business.jobmaker.reports', 'business.finaliseSTP'])
              $scope.submitSTP = function () {
                // Validation if subscrition invalid
                let appState = $ngRedux.getState().app
                let subscriptionState = $ngRedux.getState().subscription
                if (
                  !NO_PAYWALL.has($state.current.name) &&
                  !appState?.subscriptionStatus?.webapp.isValid &&
                  !subscriptionState.isSubscribed
                ) {
                  $uibModalInstance.dismiss()
                  Service.modal(
                    'ModalRequireSubscription.html',
                    [
                      '$scope',
                      '$uibModalInstance',
                      function ($scope, $uibModalInstance) {
                        $scope.turnOffModal = function () {
                          $uibModalInstance.dismiss()
                        }

                        $scope.goToSubscription = function () {
                          $uibModalInstance.dismiss()
                          $state.go('subscription')
                        }
                      }
                    ],
                    { size: 'sm', backdrop: true }
                  )
                  return
                }

                // validation
                var valid = true
                $scope.error = {}
                // console.log(!/^[a-z][a-z\s]*$/i.test($scope.signatoryName));
                if (!$scope.signatoryName || !/^[a-z][a-z\s]*$/i.test($scope.signatoryName)) {
                  $scope.error.signatoryName = true
                  valid = false
                }

                if (!valid) {
                  return false
                }
                Analytics.logEvent('STP Submitted')

                openPaySuperNow(openPaySuperProps)

                // $scope.cancel();
                callback($scope.signatoryName)

                try {
                  $uibModalInstance.dismiss()
                } catch (error) {
                  console.log(error)
                }
                // openStpMessageModal($scope.signatoryName);  // stp 되면 이것 활성화 하기
              }
            }
          ],
          { size: 'md' }
        )
      }

      const updateAbn = function (abn) {
        return Http2({
          method: 'post',
          api: '/v1/update-abn',
          data: {
            abn: abn
          }
        })
      }

      return {
        declarationModal: declarationModal,
        verify: verify,
        updateAbn: updateAbn,
        openStpErrorMessageModal: openStpErrorMessageModal
      }
    }
  )
