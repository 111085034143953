import isPrerenderForV2 from '../../constant/isPrerenderForV2'

export function addInterectionObserver({
  target,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
    remainAfterPassed: false
  },
  onVisible = (entry) => (entry.target.style.background = 'green'), // default function for debugging
  onHide = (entry) => (entry.target.style.background = 'yellow') // default function for debugging
}) {
  const { remainAfterPassed, ...intersctionOptions } = options
  if (target) {
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) onVisible(entry)
        else if (remainAfterPassed && entry.boundingClientRect.y > 0) onHide(entry)
        else if (!remainAfterPassed) onHide(entry)
      })
    }

    const myObserver = new IntersectionObserver(callback, intersctionOptions)
    myObserver.observe(target)
  }
}

if (!isPrerenderForV2) {
  window.addInterectionObserver = addInterectionObserver
}
