import { getXPublicHeader } from '../api/prepareHeaders'
export let ngPostData

angular
  .module('starter.services')
  .factory('RefreshToken', ($http, API_ENDPOINT, $window) => {
    return _.debounce(
      function () {
        const xPublic = getXPublicHeader()
        $http({
          method: 'get',
          url: API_ENDPOINT.url + '/v1/refresh-token',
          headers: {
            'x-app': window.location.hostname,
            'x-public': xPublic
          }
        })
          .then((response) => {
            if (response.status === 200) {
              // update localStorage
              const token = response.data
              $window.localStorage.pr_token = token.token
              $window.localStorage.pr_user = token.user
              $window.localStorage.pr_expires = token.expires
            }
          })
          .catch((error) => {})
      },
      15000,
      {
        leading: true,
        trailing: false
      }
    )
  })
  .factory('Http', ($rootScope, Service, API_ENDPOINT, $http, Logout, $state, $window, RefreshToken) => {
    return function (req, res, err) {
      const { noRefresh = false, api, method, data, headers = {} } = req

      const xPublic = getXPublicHeader()
      var fetchData = {
        method: method,
        url: api.startsWith('http') ? api : API_ENDPOINT.url + api,
        headers: {
          'x-app': window.location.hostname,
          'x-public': xPublic,
          'x-platform': 'web',
          ...headers
        }
      }

      if ($rootScope.company && $rootScope.company._id) {
        fetchData.headers = {
          ...fetchData.headers,
          'x-client-id': $rootScope.company._id
        }
      } else if ($window.localStorage.pr_client_id) {
        fetchData.headers = {
          ...fetchData.headers,
          'x-client-id': $window.localStorage.pr_client_id
        }
      }
      if (req.headers) {
        fetchData.headers = {
          ...fetchData.headers,
          ...req.headers
        }
      }
      if (method === 'post' || method === 'put') {
        fetchData.data = data
      }
      if (
        window.location.pathname.includes('/business/settings') &&
        window.location.search.includes('?open=open%3DclientSubscribe')
      ) {
        $window.localStorage.url_client_pay = window.location.search
      }
      $http(fetchData).then(
        function successCallback(response) {
          if (response.status == 200) {
            if ($window.localStorage.url_client_pay) {
              $state.go('business.settings')
            }
            res(response.data)
            if (!noRefresh) {
              RefreshToken()
            }
          } else {
            if (!req.disableErrorMsg) {
              Service.alert(null, 'Sorry, there was a problem processing your request. Please try again.', 'error')
            }
            err(response.data)
          }
        },
        function errorCallback(error) {
          if (error.status == 409) {
            delete $window.localStorage.pr_agent_id
            // logout
            const wasLoggedIn = Logout()
            if (window.location.pathname.includes('/agent/connect-client')) {
              $state.go('signup', {
                redirect: window.location.pathname,
                search: window.location.search.substr(1),
                variant: 'checkExistingUser'
              })
            } else {
              // 바로위에서 disableErrorMsg true 가 아닌것에 대해서 에러를 띄웠기 때문에
              if (wasLoggedIn && req.disableErrorMsg) {
                Service.alert(
                  'Alert',
                  error && error.data && error.data.message
                    ? error.data.message
                    : 'Sorry, there was a problem processing your request. Please try again.',
                  'error'
                )
              }
              const params = new URLSearchParams(window.location.search)
              params.delete('t')
              params.delete('e')
              params.delete('u')
              $state.go('login', {
                redirect: window.location.pathname,
                search: params.toString()
              })
            }
          } else if (error.status == 423) {
            Service.alert(
              'Alert',
              error && error.data && error.data.message
                ? error.data.message
                : 'Sorry, there was a problem processing your request. Please try again.',
              'error'
            )
            if ($window.localStorage.pr_agent_id) {
              $state.go('agent.clients')
            } else {
              // logout
              Logout()
              $state.go('login', {
                redirect: window.location.pathname,
                search: window.location.search.substr(1)
              })
            }
          } else if (error.status == 406) {
            Service.alert(
              'Alert',
              error && error.data && error.data.message
                ? error.data.message
                : 'Sorry, there was a problem processing your request. Please try again.',
              'error'
            )
            delete $window.localStorage.pr_agent_id
            $rootScope.isAgent = false
            $state.go('business.run.overview')
          } else {
            if (!window.location.pathname.includes('/agent/connect-client')) {
              if (!req.disableErrorMsg) {
                Service.alert(
                  error && error.data && error.data.title ? error.data.title : null,
                  error && error.data && error.data.message
                    ? error.data.message
                    : 'Sorry, there was a problem processing your request. Please try again.',
                  'error'
                )
              }
            }
            err(error.data)
          }
        }
      )
    }
  })
  .factory('HttpFile', ($rootScope, Service, API_ENDPOINT, $http, $window, Logout, $state, RefreshToken) => {
    return function (req, res, err) {
      var formData = new FormData()
      formData.append('file', req.imageBase64)
      formData.append('postdata', JSON.stringify(req.data))

      $.ajax({
        url: API_ENDPOINT.url + req.api,
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        beforeSend: function (request) {
          request.setRequestHeader('Authorization', 'Bearer ' + $window.localStorage.pr_token)

          if (
            $window.localStorage.pr_agent_id &&
            ($window.localStorage.pr_client_id || ($rootScope.company && $rootScope.company._id))
          ) {
            // client id를 server 에 넘겨 줌으로 agent 가 client 의 패널을 마음껏 건드릴수 있도록 한다.
            request.setRequestHeader('x-agent-id', $window.localStorage.pr_agent_id)

            if ($rootScope.company && $rootScope.company._id) {
              request.setRequestHeader('x-client-id', $rootScope.company._id)
            } else {
              request.setRequestHeader('x-client-id', $window.localStorage.pr_client_id)
            }
          }
        },
        success: function (response) {
          res(response)
          RefreshToken()
        },
        error: function (error) {
          if (error.status == 409) {
            Service.alert('Alert', error.message, 'danger')
            Logout()
            $state.go('login')
          } else {
            if (error.responseText && Service.isJSON(error.responseText)) {
              if (!req.disableErrorMsg) {
                Service.alert('', JSON.parse(error.responseText).message, 'error')
              }

              err(JSON.parse(error.responseText))
            } else {
              Service.alert()
              err()
            }
          }
        },
        complete: function () {
          Service.hideLoading()
          // $scope.sendDisabled=false; //prevent double submission
        }
      })
    }
  })
  .factory('Http2', ($rootScope, Service, API_ENDPOINT, $http, Logout, $state, $window, RefreshToken) => {
    return (reqData) => {
      return new Promise((resolve, reject) => {
        console.debug(reqData)
        const xPublic = getXPublicHeader()

        let fetchData = {
          method: reqData.method,
          url: reqData.api.startsWith('http') ? reqData.api : API_ENDPOINT.url + reqData.api,
          headers: {
            'x-app': window.location.hostname,
            'x-public': xPublic,
            'x-platform': 'web',
            ...reqData?.headers
          }
        }

        if ($rootScope.company && $rootScope.company._id) {
          fetchData.headers = {
            ...fetchData.headers,
            'x-client-id': $rootScope.company._id
          }
        } else if ($window.localStorage.pr_client_id) {
          fetchData.headers = {
            ...fetchData.headers,
            'x-client-id': $window.localStorage.pr_client_id
          }
        }

        if (reqData.method === 'post' || reqData.method === 'put') {
          fetchData.data = reqData.data
        }

        $http(fetchData)
          .then((response) => {
            if (response.status === 200) {
              RefreshToken()
              return resolve(response.data)
            } else {
              if (!reqData.disableErrorMsg) {
                Service.alert(null, 'Sorry, there was a problem processing your request. Please try again.', 'error')
              }
              return reject(response.data)
            }
          })
          .catch((error) => {
            if (error.status === 409) {
              delete $window.localStorage.pr_agent_id
              // logout
              const wasLoggedIn = Logout()
              if (window.location.pathname.includes('/agent/connect-client')) {
                return $state.go('signup', {
                  redirect: window.location.pathname,
                  search: window.location.search.substr(1),
                  variant: 'checkExistingUser'
                })
              } else {
                if (wasLoggedIn && reqData.disableErrorMsg) {
                  Service.alert(
                    'Alert',
                    error && error.data && error.data.message
                      ? error.data.message
                      : 'Sorry, there was a problem processing your request. Please try again.',
                    'error'
                  )
                }

                return $state.go('login', {
                  redirect: window.location.pathname,
                  search: window.location.search.substr(1)
                })
              }
            } else if (error.status === 423) {
              Service.alert(
                'Alert',
                error && error.data && error.data.message
                  ? error.data.message
                  : 'Sorry, there was a problem processing your request. Please try again.',
                'error'
              )
              if ($window.localStorage.pr_agent_id) {
                $state.go('agent.clients')
              } else {
                // logout
                Logout()
                return $state.go('login', {
                  redirect: window.location.pathname,
                  search: window.location.search.substr(1)
                })
              }
            } else if (error.status === 406) {
              Service.alert(
                'Alert',
                error && error.data && error.data.message
                  ? error.data.message
                  : 'Sorry, there was a problem processing your request. Please try again.',
                'error'
              )
              delete $window.localStorage.pr_agent_id
              $rootScope.isAgent = false
              return $state.go('business.run.overview')
            } else {
              if (!window.location.pathname.includes('/agent/connect-client')) {
                if (!reqData.disableErrorMsg) {
                  Service.alert(
                    error && error.data && error.data.title ? error.data.title : null,
                    error && error.data && error.data.message
                      ? error.data.message
                      : 'Sorry, there was a problem processing your request. Please try again.',
                    'error'
                  )
                }
              }
              return reject(error.data)
            }
          })
      })
    }
  })
  .factory(
    'HttpPromiseAndLocalStorage',
    ($rootScope, Service, API_ENDPOINT, $http, Logout, $state, $window, RefreshToken) => {
      function handler(req) {
        return new Promise((resolve, reject) => {
          const xPublic = getXPublicHeader()
          var fetchData = {
            method: req.method,
            url: req.api.startsWith('http') ? req.api : API_ENDPOINT.url + req.api,
            headers: {}
          }
          // that use pr_client_id in localStorage, not rootScope
          if ($window.localStorage.pr_client_id) {
            fetchData.headers = {
              'x-client-id': $window.localStorage.pr_client_id
            }
          } else if ($rootScope.company && $rootScope.company._id) {
            fetchData.headers = {
              'x-client-id': $rootScope.company._id
            }
          }

          fetchData.headers['x-public'] = xPublic

          if (req.method === 'post' || req.method === 'put') {
            fetchData.data = req.data
          }
          if (req.responseType) {
            fetchData.responseType = req.responseType
          }

          $http(fetchData).then(
            function successCallback(response) {
              if (response.status == 200) {
                resolve(response.data)
                RefreshToken()
              } else {
                if (!req.disableErrorMsg) {
                  Service.alert(null, 'Sorry, there was a problem processing your request. Please try again.', 'error')
                }
                reject(response.data)
              }
            },
            function errorCallback(error) {
              if (error.status == 409) {
                delete $window.localStorage.pr_agent_id
                // logout
                const wasLoggedIn = Logout()
                if (window.location.pathname.includes('/agent/connect-client')) {
                  $state.go('signup', {
                    redirect: window.location.pathname,
                    search: window.location.search.substr(1),
                    variant: 'checkExistingUser'
                  })
                } else {
                  // 바로위에서 disableErrorMsg true 가 아닌것에 대해서 에러를 띄웠기 때문에
                  if (wasLoggedIn && req.disableErrorMsg) {
                    Service.alert(
                      'Alert',
                      error && error.data && error.data.message
                        ? error.data.message
                        : 'Sorry, there was a problem processing your request. Please try again.',
                      'error'
                    )
                  }

                  $state.go('login', {
                    redirect: window.location.pathname,
                    search: window.location.search.substr(1)
                  })
                }
              } else if (error.status == 423) {
                Service.alert(
                  'Alert',
                  error && error.data && error.data.message
                    ? error.data.message
                    : 'Sorry, there was a problem processing your request. Please try again.',
                  'error'
                )
                if ($window.localStorage.pr_agent_id) {
                  $state.go('agent.clients')
                } else {
                  // logout
                  Logout()
                  $state.go('login', {
                    redirect: window.location.pathname,
                    search: window.location.search.substr(1)
                  })
                }
              } else if (error.status == 406) {
                Service.alert(
                  'Alert',
                  error && error.data && error.data.message
                    ? error.data.message
                    : 'Sorry, there was a problem processing your request. Please try again.',
                  'error'
                )
                delete $window.localStorage.pr_agent_id
                $rootScope.isAgent = false
                $state.go('business.run.overview')
              } else {
                if (!window.location.pathname.includes('/agent/connect-client')) {
                  if (!req.disableErrorMsg) {
                    Service.alert(
                      error && error.data && error.data.title ? error.data.title : null,
                      error && error.data && error.data.message
                        ? error.data.message
                        : 'Sorry, there was a problem processing your request. Please try again.',
                      'error'
                    )
                  }
                }
                reject(error.data)
              }
            }
          )
        })
      }

      ngPostData = ({ api, data = {} }) => handler({ method: 'post', api, data, disableErrorMsg: false })
      return handler
    }
  )
