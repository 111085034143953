import React, { useEffect, useState } from 'react'
import { gaEvent, isGuiDebug } from '../react-subscription/utils/share'

export function GaEvent({ name, items, description, exipre = false }) {
  // log GA events when this component is mounted
  useEffect(() => gaEvent({ name, items, description }), [])

  const ExpireComp = exipre ? Expire : 'div'

  if (isGuiDebug) {
    return (
      <ExpireComp>
        <div title={JSON.stringify(items, null, 4)} style={{ background: 'hsl(218, 100%, 70%)', color: 'white' }}>
          🔫 <span style={{ color: 'hsl(119, 94%, 80%)', fontWeight: 500 }}>{`${name} / ${description}`}</span> (GaEvent
          Triggered)
        </div>
      </ExpireComp>
    )
  }

  return null
}

const Expire = ({ after = 5000, children }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, after)
    return () => clearTimeout(timer)
  }, [after])

  return visible ? <div>{children}</div> : <div />
}
