import uniq from 'lodash/uniq'

export function getFiscalYear(date) {
  const jsDate = new Date(date)
  return jsDate.getFullYear() - 1 + (jsDate.getMonth() > 5)
}

export function getFiscalYears(payRuns, field = 'pd') {
  const taxYears = payRuns?.map((payRun) => getFiscalYear(payRun[field])) || []

  return uniq(taxYears)
}

export function getLatestFiscalYear(payruns, field = 'pd') {
  const taxYears = getFiscalYears(payruns, field)

  return Math.max(...taxYears)
}
