angular.module('starter.services').factory('bsb2code', function () {
  return {
    '012': 'ANZ',
    '013': 'ANZ',
    '014': 'ANZ',
    '015': 'ANZ',
    '016': 'ANZ',
    '017': 'ANZ',
    '032': 'WBC',
    '033': 'WBC',
    '034': 'WBC',
    '035': 'WBC',
    '036': 'WBC',
    '037': 'WBC',
    '062': 'CBA',
    '063': 'CBA',
    '064': 'CBA',
    '065': 'CBA',
    '066': 'CBA',
    '067': 'CBA',
    '081': 'NAB',
    '082': 'NAB',
    '083': 'NAB',
    '084': 'NAB',
    '085': 'NAB',
    '086': 'NAB',
    '087': 'NAB',
    '092': 'RBA',
    '093': 'RBA',
    '094': 'RBA',
    '095': 'RBA',
    '096': 'RBA',
    '097': 'RBA',
    102: 'BSA',
    103: 'BSA',
    104: 'BSA',
    105: 'BSA',
    106: 'BSA',
    112: 'STG',
    113: 'STG',
    114: 'STG',
    115: 'STG',
    116: 'STG',
    117: 'STG',
    118: 'STG',
    119: 'STG',
    122: 'BQL',
    123: 'BQL',
    124: 'BQL',
    125: 'BQL',
    126: 'BQL',
    127: 'BQL',
    142: 'PIB',
    152: 'T&C',
    153: 'T&C',
    154: 'T&C',
    155: 'T&C',
    156: 'T&C',
    157: 'T&C',
    182: 'MBL',
    183: 'MBL',
    184: 'MBL',
    185: 'MBL',
    186: 'MBL',
    187: 'MBL',
    192: 'BOM',
    193: 'BOM',
    194: 'BOM',
    195: 'BOM',
    196: 'BOM',
    197: 'BOM',
    212: 'CMB',
    213: 'CMB',
    214: 'CMB',
    215: 'CMB',
    216: 'CMB',
    217: 'CMB',
    219: 'CMB',
    222: 'BNP',
    226: 'BNP',
    232: 'BAL',
    234: 'BAL',
    242: 'CTI',
    243: 'CTI',
    244: 'CTI',
    245: 'CTI',
    246: 'CTI',
    248: 'CTI',
    255: 'BPS',
    262: 'BTA',
    292: 'BOT',
    293: 'BOT',
    296: 'BOT',
    302: 'BWA',
    303: 'BWA',
    304: 'BWA',
    305: 'BWA',
    306: 'BWA',
    313: 'MCU',
    325: 'BYB',
    330: 'SGP',
    332: 'SGP',
    333: 'SGP',
    334: 'SGP',
    335: 'SGP',
    336: 'SGP',
    342: 'HBA',
    343: 'HBA',
    344: 'HBA',
    345: 'HBA',
    346: 'HBA',
    352: 'BOC',
    353: 'BOC',
    402: 'CST',
    403: 'CST',
    404: 'CST',
    405: 'CST',
    406: 'CST',
    407: 'CST',
    412: 'DBA',
    413: 'DBA',
    427: 'TBT',
    452: 'OCB',
    453: 'OCB',
    462: 'ADV',
    472: 'CBL',
    473: 'CBL',
    474: 'CBL',
    475: 'CBL',
    476: 'CBL',
    482: 'MET',
    483: 'MET',
    484: 'MET',
    510: 'CNA',
    512: 'CFC',
    514: 'QTM',
    517: 'VOL',
    527: 'TBT',
    533: 'BCC',
    552: 'BML',
    553: 'BML',
    554: 'BML',
    555: 'BML',
    556: 'BML',
    572: 'ASL',
    579: 'ASL',
    610: 'ADL',
    611: 'SEL',
    630: 'ABS',
    632: 'BAE',
    633: 'BBL',
    634: 'UFS',
    637: 'GBS',
    638: 'HBS',
    639: 'HOM',
    640: 'HUM',
    641: 'IMB',
    642: 'ADC',
    645: 'MPB',
    646: 'MMB',
    647: 'IMB',
    650: 'NEW',
    653: 'PPB',
    654: 'ECU',
    655: 'ROK',
    656: 'BAY',
    659: 'GCB',
    664: 'SUN',
    670: 'YOU',
    676: 'GTW',
    702: 'CUS',
    703: 'CUS',
    704: 'CUS',
    705: 'CUS',
    706: 'CUS',
    707: 'CUS',
    721: 'HCC',
    722: 'SNX',
    723: 'HIC',
    724: 'RCU',
    728: 'SCU',
    732: 'WBC',
    733: 'WBC',
    734: 'WBC',
    735: 'WBC',
    736: 'WBC',
    737: 'WBC',
    762: 'CBA',
    763: 'CBA',
    764: 'CBA',
    765: 'CBA',
    766: 'CBA',
    767: 'CBA',
    775: 'XIN',
    777: 'PNB',
    800: 'CRU',
    801: 'CRU',
    802: 'CRU',
    803: 'CRU',
    804: 'CRU',
    805: 'CRU',
    806: 'CRU',
    807: 'CRU',
    808: 'CRU',
    809: 'CRU',
    812: 'TCU',
    813: 'CAP',
    814: 'CUA',
    815: 'PCU',
    817: 'WCU',
    818: 'COM',
    819: 'IBK',
    823: 'ENC',
    824: 'STH',
    825: 'SKY',
    833: 'DBL',
    880: 'HBS',
    882: 'MMP',
    888: 'CCB',
    902: 'APO',
    903: 'APO',
    904: 'APO',
    911: 'SMB',
    913: 'SSB',
    917: 'ARA',
    918: 'MCB',
    922: 'UOB',
    923: 'ING',
    931: 'ICB',
    932: 'RAB',
    936: 'GNI',
    939: 'AMP',
    941: 'BCY',
    942: 'LBA',
    943: 'TBB',
    944: 'MEB',
    946: 'UBS',
    951: 'INV',
    969: 'MSL',
    980: 'BCA',
    985: 'HSB'
  }
})
