import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './prepareHeaders'

const endpoint = process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE

export const employeeApi = createApi({
  reducerPath: 'employeeApi',
  baseQuery: fetchBaseQuery({ baseUrl: endpoint, prepareHeaders }),
  endpoints: (build) => ({
    create: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/v1/employee',
        method: 'POST',
        body: patch
      })
    }),
    addSeats: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/v1/subscription/webapp',
        method: 'POST',
        body: patch
      })
    })
  })
})

export const { useMutation: useCreateMutation } = employeeApi.endpoints.create
export const { useMutation: useAddSeatsMutation } = employeeApi.endpoints.addSeats
