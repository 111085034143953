angular.module('starter.services').factory('DatepickerService', function ($rootScope, Service) {
  return function (selectedDate, successCallback) {
    Service.modal(
      'datepickerModal.html',
      [
        '$scope',
        '$uibModalInstance',
        function ($scope, $uibModalInstance) {
          $scope.cancel = function () {
            $uibModalInstance.dismiss()
          }

          if (selectedDate) {
            $scope.selectedDate = selectedDate
          }

          $scope.OnSetTime = () => {
            $scope.cancel()
            successCallback($scope.selectedDate)
          }
        }
      ],
      { size: 'datepicker' }
    )
  }
})
