import { agentInit } from '/src/scheduling/react-subscription/utils/share'
import { DEFAULT_COMPANY_NAME } from '../controllers/consts'
const { setCurrentClient } = require('/src/reducers/subscriptionSlice')

angular
  .module('starter.services')
  .factory(
    'AgentClientInvitationModal',
    function ($rootScope, $state, Service, $ngRedux, $window, Analytics, Http2, Http) {
      const openInvitationModal = function () {
        Service.modal(
          'agent/sendInvitationModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.addManually = () => {
                if (!$scope.clientFirstName) {
                  Service.alert('Error', 'First name field is required!', 'error')
                } else if (!$scope.clientEmail) {
                  Service.alert('Error', 'Email field is required!', 'error')
                } else if (!Service.validateEmail($scope.clientEmail)) {
                  Service.alert('Error', 'Email address is invalid!', 'error')
                } else {
                  $scope.loading = true

                  const composedClient = new Array(14)
                  composedClient[0] = $scope.clientFirstName
                  composedClient[2] = $scope.clientEmail
                  // Default company name
                  composedClient[5] = DEFAULT_COMPANY_NAME
                  // Send verification email
                  composedClient[13] = 'Yes'

                  Http(
                    {
                      method: 'post',
                      api: '/v1/agent/import-clients',
                      data: {
                        clients: [composedClient],
                        url: $rootScope.agent.ag_u
                      }
                    },
                    () => {
                      $ngRedux
                        .dispatch(agentInit({ Http2 }))
                        .unwrap()
                        .then((res) => {
                          $scope.cancel()
                          $scope.loading = false
                          Service.alert('Success!', 'Successfully created!', 'success')
                          const newClient = res.clients.find((employee) => employee.e === $scope.clientEmail)
                          $scope.agentData = { clients: res.clients }
                          const clientId = newClient._id
                          $window.localStorage.pr_client_id = clientId
                          $ngRedux.dispatch(setCurrentClient($scope.agentData.clients.find((x) => x._id === clientId)))
                          $state.go('business.run.overview')
                        })
                    },
                    () => {
                      $scope.loading = false
                    }
                  )
                }
              }

              $scope.sendInvitation = function () {
                // verification
                if (!$scope.clientFirstName) {
                  Service.alert('Error', 'First name field is required!', 'error')
                } else if (!$scope.clientEmail) {
                  Service.alert('Error', 'Email field is required!', 'error')
                } else if (!Service.validateEmail($scope.clientEmail)) {
                  Service.alert('Error', 'Email address is invalid!', 'error')
                } else {
                  $scope.loading = true

                  // agent 자체 detail server 에서 사용하기 위해
                  // localStorage.pr_client_id 를 삭제 하면 agent 자체 데이터를 사용할수 있다.
                  // 이해가 안가면 validateRequest.js (server)를 볼 것
                  delete $window.localStorage.pr_client_id

                  Http(
                    {
                      method: 'post',
                      api: '/v1/agent/send-invitation/',
                      data: {
                        email: $scope.clientEmail,
                        firstName: $scope.clientFirstName,
                        agentId: $rootScope.agentUser.dci
                      }
                    },
                    (res) => {
                      $scope.loading = false
                      Service.alert('Success!', 'Invitation has been sent successfully!', 'success')
                      $scope.cancel()
                      Analytics.logEvent('Agent Send Invitation')
                    },
                    (err) => {
                      $scope.error = true
                      $scope.errorMessage = err.message || 'Something went wrong!'
                      $scope.loading = false
                    }
                  )
                }
              }
            }
          ],
          { size: 'sm' }
        )
      }
      return {
        openInvitationModal
      }
    }
  )
