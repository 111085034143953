import React, { useState } from 'react'
import * as s from './styles'
import { logEvent } from '../../../../../../modules/analytics'

const CardExtend = ({ id, title, content }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={s.cardExtend}>
      <div>
        <div
          className={s.extendContainer}
          onClick={() => {
            setOpen(!open)
            logEvent(open ? 'Collapse FAQ' : 'Expand FAQ', { id, title })
          }}
        >
          <h3 className={s.cardTitle}>{title}</h3>
          <img width="18px" height="10px" src={open ? '/img/paywall/collapse.png' : '/img/paywall/expand.png'} />
        </div>
        {open && <p className={s.cardDescription} dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
    </div>
  )
}

export default CardExtend
