"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
exports.__esModule = true;
exports.cx = exports.getSearchParams = exports.calcSomeTax = exports.log = void 0;
var log = function (str) {
    console.log('logger: ' + str);
};
exports.log = log;
var calcSomeTax = function () {
    var taxRate = 0.68;
    console.log('calcSomeTax! - ' + taxRate);
    return taxRate;
};
exports.calcSomeTax = calcSomeTax;
function getSearchParams() {
    var urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
}
exports.getSearchParams = getSearchParams;
__exportStar(require("./postData"), exports);
__exportStar(require("./appConfig"), exports);
__exportStar(require("./fetch"), exports);
__exportStar(require("./sleep"), exports);
__exportStar(require("./helpers"), exports);
__exportStar(require("./values"), exports);
__exportStar(require("./constants"), exports);
var cx = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(function (a) { return !!a; }).join(' ');
};
exports.cx = cx;
