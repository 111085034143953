import { setModalSubscription } from '/src/scheduling/react-subscription/utils/share'
import { logEvent } from '/src/modules/analytics'
import { setModals } from '/reducers/appSlice'

angular
  .module('starter.business.controllers')
  .controller(
    'addonCtrl',
    function ($scope, $rootScope, Service, $state, Http, $timeout, $ngConfirm, CompanyAction, AddonService, $ngRedux) {
      $ngRedux.connect((state) => state.subscription)($scope.subscription)
      $scope.demoMsg = function () {
        // Service.infoPopup({
        // 	title: " You are currently in demo mode",
        // 	contents: "Add-ons cannot be connected in demo mode."
        // });

        Service.infoPopup({
          title: "We'll turn this on soon!",
          contents: 'All amazing add-ons will be available soon.'
        })
      }

      $scope.integrateXero = function () {
        if ($scope.subscription.errorCode === 'SUBSCRIPTION_NO_SUB') {
          if ($rootScope.gbFeatures.delayed_tokenisation) {
            $ngRedux.dispatch(setModals([{ kind: 'FreeTrial' }]))
          } else {
            $ngRedux.dispatch(
              setModalSubscription({
                source: 'integrateXero'
              })
            )
            logEvent('click the trigger points', {
              trigger_point: 'xero connect',
              company_id: $rootScope.company._id
            })
          }
          return
        }
        AddonService.connectXero()
      }

      function openXeroSettings() {
        $scope.openXeroSettings()
      }

      $scope.checkXeroConnectionStatus = () => {
        var xeroObj = $scope.company.xero || false
        if (!xeroObj || !xeroObj.tokenSet || !xeroObj.tokenSet.access_token) {
          return {
            btnStyle: 'btn-primary',
            btnName: 'Connect',
            btnAction: $scope.integrateXero,
            connected: false
          }
          // "notConnected";
        } else if (
          xeroObj.exportAs &&
          xeroObj.accounts &&
          ((xeroObj.exportAs === 'manualJournal' &&
            xeroObj.accounts.payment &&
            xeroObj.accounts.payment.code &&
            xeroObj.accounts.payment.name) ||
            (xeroObj.exportAs === 'accountPayable' &&
              xeroObj.accounts.wagePayable &&
              xeroObj.accounts.wagePayable.code &&
              xeroObj.accounts.wagePayable.name)) &&
          xeroObj.accounts.wageExpense &&
          xeroObj.accounts.wageExpense.code &&
          xeroObj.accounts.wageExpense.name &&
          xeroObj.accounts.paygLiability &&
          xeroObj.accounts.paygLiability.code &&
          xeroObj.accounts.paygLiability.name &&
          xeroObj.accounts.defaultPayrollLiability &&
          xeroObj.accounts.defaultPayrollLiability.code &&
          xeroObj.accounts.defaultPayrollLiability.name &&
          xeroObj.accounts.superLiability &&
          xeroObj.accounts.superLiability.code &&
          xeroObj.accounts.superLiability.name &&
          xeroObj.accounts.superExpense &&
          xeroObj.accounts.superExpense.code &&
          xeroObj.accounts.superExpense.name &&
          xeroObj.accounts.employeeExpense &&
          xeroObj.accounts.employeeExpense.code &&
          xeroObj.accounts.employeeExpense.name
        ) {
          return {
            btnStyle: 'btn-success',
            btnName: 'Export Data',
            btnAction: $scope.openXeroMoreModal,
            connected: true
          }
          // return "readyToGo";
        } else {
          return {
            btnStyle: 'btn-warning',
            btnName: 'Complete Setup',
            btnAction: $scope.openXeroSettings,
            connected: true
          }
          // return "needSetup";
        }
      }

      $scope.openXeroMoreModal = () => {
        var checkXeroConnectionStatus = $scope.checkXeroConnectionStatus

        Service.modal(
          'xeroMoreModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.checkXeroConnectionStatus = checkXeroConnectionStatus

              $scope.openXeroSettings = () => {
                $scope.cancel()
                openXeroSettings()
              }

              var exportingDataToXero = (payRun, index) => {
                $scope.xeroLoading = index
                AddonService.exportXero(
                  payRun,
                  () => {
                    $scope.xeroLoading = null
                    // $scope.payRun = payRun_new;
                  },
                  () => {
                    $scope.xeroLoading = null
                  },
                  () => {
                    $scope.cancel()
                  }
                )
              }

              $scope.syncXero = function (payRun, index) {
                if (payRun.xero && payRun.xero.at) {
                  Service.confirm({
                    title: 'Re-exporting',
                    contents:
                      'If you re-export your pay run, another bill will be created in your Xero account. Would you like to proceed?',
                    confirmText: 'Yes, proceed',
                    confirmCallback: function () {
                      exportingDataToXero(payRun, index)
                    },
                    cancelCallback: () => {}
                  })
                } else {
                  exportingDataToXero(payRun, index)
                }
              }
            }
          ],
          { size: 'lg' }
        )
      }

      $scope.openXeroSettings = () => {
        AddonService.openXeroSettings()
      }
    }
  )
