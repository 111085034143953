import isPrerenderForV2 from '../scheduling/constant/isPrerenderForV2'

/* eslint-disable */
let libStr = null
let libs = null

const requestEndpoint = `${process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE}/js/externalLibs.js`

const fetchExternalLib = async () => {
  try {
    const response = await fetch(requestEndpoint)
    if (response.status !== 200) return
    const responseText = await response.text()
    libStr = responseText
    libs = eval(responseText)
  } catch (e) {
    console.error('external lib:', e.message)
  }
}

export async function initExternalLibs() {
  try {
    if (caches) {
      try {
        const storageExternalLib = await caches.open('external-library')
        const cachedLibData = await storageExternalLib.match(requestEndpoint)
        if (cachedLibData && cachedLibData.ok) {
          const textResponse = await cachedLibData.text()
          libStr = textResponse
          libs = eval(textResponse)
        }

        const response = await fetch(requestEndpoint)
        if (response.status !== 200) return
        storageExternalLib.put(requestEndpoint, response.clone())
        const textResponse = await response.text()
        libStr = textResponse
        libs = eval(textResponse)
      } catch {
        // Cache API is not supported or not available in private browsing mode.
        fetchExternalLib()
      }
    } else {
      fetchExternalLib()
    }
  } catch (err) {
    console.error(err)
    if (!isPrerenderForV2) {
      window.Sentry?.captureException(err)
    }
  }
}

export const externalLibs = {
  getSuperGuarantee: ({
    payslip = null,
    payRun = null,
    getInitialValue = null,
    employee = null,
    removedSGThreshold = null,
    allPayslips = null,
    overrideSuper = null
  }) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.getSuperGuarantee({
      payslip,
      payRun,
      getInitialValue,
      employee,
      removedSGThreshold,
      allPayslips,
      overrideSuper
    })
  },
  amountSubjectToSuper: (payslip) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.amountSubjectToSuper(payslip)
  },
  getEmployeeLeaveBalance: ({ employee, endDate = null, allPayslips = null, excludeRunId = null }) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.getEmployeeLeaveBalance({ employee, endDate, allPayslips, excludeRunId })
  },
  getGrossEarning: (payslip, forTaxCalculation) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.getGrossEarning(payslip, forTaxCalculation)
  },
  getEtpTotal: (payslip) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.getEtpTotal(payslip)
  },
  generateVoucherMap: (payments) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.generateVoucherMap(payments)
  },
  calcAccruedLeave: (employeeType, companyLongLeave, workHours) => {
    if (!libs) {
      initExternalLibs()
      return null
    }
    return libs.calcAccruedLeave(employeeType, companyLongLeave, workHours)
  }
}
