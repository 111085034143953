import { useDispatch, useSelector } from 'react-redux'
import { useGetPricingPlanQuery } from '../../../../../api/paywall'
import { stateApp } from '../../../../../reducers/appSlice'
import * as slice from '../../../../../reducers/paywallSlice'
import * as subscriptionSlice from '../../../../../reducers/subscriptionSlice'
import { CATEGORY } from '../../../../constant'

export function usePaywall() {
  const dispatch = useDispatch()
  const step = useSelector((state) => state.paywall.step)
  const period = useSelector((state) => state.paywall.period)
  const seats = useSelector((state) => state.paywall.seats)
  const tokenizeStatus = useSelector((state) => state.paywall.tokenizeStatus)
  const tokenReference = useSelector((state) => state.paywall.tokenReference)
  const paymentMethod = useSelector((state) => state.paywall.paymentMethod)

  const allowTrial = useSelector(stateApp).company?.allowTrial
  const payWallSeen = useSelector(stateApp).company?.payWallSeen // first time user sees paywall
  const createdAt = useSelector(stateApp).company?.createdAt

  const isTrialOnly = useSelector(subscriptionSlice.stateSubscription).isTrialOnly

  const { data, isLoading } = useGetPricingPlanQuery({ seats, category: CATEGORY.WEBAPP }, { skip: !seats })
  const plan = data?.[period]

  const isTrialPlan = plan?.trial?.end && allowTrial && !isTrialOnly

  const prices =
    plan &&
    // don't know why the plan?.paymentSchedules for monthly is only one value.
    // so I just hard coded types of the paymentSchedules (['discount', 'normal'])
    ['discount', 'normal'].reduce((result, type) => {
      const currentPlan = plan[type]
      if (!currentPlan) return result

      const { unitPrice, price, minCost } = currentPlan
      const useMinCost = unitPrice * Number(plan.seats) !== price
      const cost = useMinCost ? minCost : unitPrice

      return { ...result, [type]: { ...currentPlan, useMinCost, cost } }
    }, {})
  const currentPrice = prices?.discount?.unitPrice || prices?.normal?.unitPrice // discount price for initial 3 months, normal price for the rest (discount price is not fetched from the server for the rest)
  const taxExclusiveInfo = plan?.taxExclusive ? 'exclusive' : 'inclusive'

  const setStep = (val) => {
    dispatch(slice.setStep(val))
  }
  const setPeriod = (val) => {
    dispatch(slice.setPeriod(val))
  }
  const setSeats = (val) => {
    dispatch(slice.setSeats(val))
  }
  const setTokenizeStatus = (val) => {
    dispatch(slice.setTokenizeStatus(val))
  }
  const removeStoredPaymentmethod = (val) => {
    dispatch(slice.removeStoredPaymentmethod(val))
  }
  // change subscriptionSlice's state after successful payment. This state normally get true or false when the app initializes.
  const setIsSubscribed = (val) => {
    dispatch(subscriptionSlice.setIsSubscribed(val))
  }
  return {
    step,
    setStep,
    period,
    setPeriod,
    seats,
    setSeats,
    tokenizeStatus,
    setTokenizeStatus,
    tokenReference,
    paymentMethod,
    removeStoredPaymentmethod,
    setIsSubscribed,
    plan,
    prices,
    currentPrice,
    taxExclusiveInfo,
    payWallSeen,
    createdAt,
    isTrialPlan,
    isLoading,
    discount: data?.year.discount,
    discountCode: data?.discountCode
  }
}
