import { externalLibs } from '../../../services/externalLibsService'

export const validateNumberOnKeydown = (e) => {
  if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
    e.preventDefault()
  }
}

export const formatMoney = (price, isDollar = false) => {
  // Price: cents => return to dollar
  if (!price) price = 0
  try {
    const Formatted = new Intl.NumberFormat('en-DE', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: 2
    }).format(isDollar ? price : price / 100)
    return `${Formatted}`
  } catch (e) {
    const Formatted = new Intl.NumberFormat('en-DE', {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'symbol',
      maximumFractionDigits: 2
    }).format(isDollar ? price : price / 100)
    return `${Formatted}`
  }
}

export const DeepCopy = (object) => {
  return JSON.parse(JSON.stringify(object))
}

// Use for show detail calculation
export const formatPeriod = (period) => {
  return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(period)
}
const formatLength = (length) => {
  // const formatObject = Object.entries(length)
  const getKey = Object.keys(length)
  // case largest unit is year

  if (getKey.includes('years')) {
    const months = getKey.includes('months') ? length.months / 12 : 0
    const days = getKey.includes('days') ? length.days / 365 : 0
    const period = length.years + months + days
    return formatPeriod(period) > 1 ? `${formatPeriod(period)} years` : '12 months'
  } else if (getKey.includes('months')) {
    const days = getKey.includes('days') ? length.days / 30 : 0
    const period = length.months + days
    return `${formatPeriod(period)} month${period > 1 ? 's' : ''}`
  } else {
    return `${length.days} day${length.days > 1 ? 's' : ''}`
  }
  // formatObject.map(entry => `${entry[1]} ${entry[1] > 1 ? entry[0] : entry[0].substr(0, entry[0].length - 1)}`)
}

export const formattedSchedule = (schedules, subscription) => {
  if (!schedules || !subscription || !subscription.normal) return
  const formated = schedules.map((schedule, idx) => {
    const { unitPrice, price, minCost } = subscription[schedule.type]
    const useMinCost = unitPrice * Number(subscription.seats) !== price
    // const hasDays = schedule?.length?.days && !schedule?.length?.months && !schedule?.length?.years

    const length = formatLength(schedule.length)
    const lengthOfPeriod = subscription.period === 'month' ? '' : ` x ${length}`

    return {
      content:
        schedule.type === 'discount' ? `Initial ${schedule.length.months}-month discount` : `Remaining ${length}`,
      price: schedule.amount,
      details: `${useMinCost ? 'Min.' : ''} $${((useMinCost ? minCost : unitPrice) / 100).toFixed(
        2
      )}${lengthOfPeriod}  ${
        useMinCost ? '' : `x ${subscription.seats} employee${Number(subscription.seats) > 1 ? 's' : ''}`
      }`
      // useMinCost ?
      //   `$${(schedule.price / (100 * Number(subscription.seats))).toFixed(2)} x ${formatLength(schedule.length)} x ${subscription.seats} employee${Number(subscription.seats) > 1 ? 's' : ''}` :
      //   `$${(unitPrice / 100).toFixed(2)} x ${formatLength(schedule.length)} x ${subscription.seats} employee${Number(subscription.seats) > 1 ? 's' : ''}`,
    }
  })
  const voucherMap = externalLibs.generateVoucherMap(schedules) || []
  let voucherTotal = totalVoucherAmt(schedules)

  const credits = schedules.map((schedule) => schedule.amount - schedule.left).reduce((a, b) => a + b, 0)
  if (credits - voucherTotal > 0) {
    formated.push({
      content: 'Discount applied',
      price: -credits
    })
  }

  for (const [key, val] of voucherMap) {
    formated.push({
      content: `${key} × ${val.cnt}`,
      price: -val.amount,
      details: `${key} × ${val.cnt}`
    })
    if (val.remaining != 0) {
      formated.push({
        content: `Discarded ${key}`,
        price: val.remaining,
        details: `Discarded ${key}`
      })
    }
  }

  return formated
}
// end function for show detail calculation

function totalVoucherAmt(payments) {
  let voucherTotal = 0

  payments.forEach(function (payment) {
    payment.appliedVoucher?.forEach(function (vou) {
      voucherTotal += vou?.amountUsed || 0
    })
  })

  return voucherTotal
}

export const sanitizeObj = (object) => {
  // replace all entity character
  if (typeof object === 'string') {
    object = object.replaceAll('&amp;', '&')
  } else {
    if (Array.isArray(object)) {
      object = object.map((obj) => sanitizeObj(obj))
    } else {
      Object.keys(object).forEach((k) => {
        if (object[k] && typeof object[k] === 'object') {
          object[k] = sanitizeObj(object[k])
        }
        if (typeof object[k] === 'string') {
          object[k] = object[k].replaceAll('&amp;', '&')
        }
      })
    }
  }
  return object
}

// GA items for begin_checkout event
export const PERIOD_NAMES = {
  month: 'Monthly',
  year: 'Annual'
}

export const makeEventItems = ({ pricingPlan, dataSaved, currentCl }) => {
  if (!pricingPlan || !dataSaved) return []

  try {
    const period = currentCl?.period || currentCl?.subscription?.period
    let plan = pricingPlan[period]
    if (!plan) return []

    const seats = plan.seats > dataSaved.seats ? plan.seats - dataSaved.seats : plan.seats

    return [
      {
        item_id: 'subscription',
        item_name: 'Subscription',
        item_category: PERIOD_NAMES[period],
        coupon: 'VARIANTC',
        price: (plan.normal.unitPrice || plan.discount.unitPrice) / 100,
        currency: 'AUD',
        quantity: seats
      }
    ]
  } catch (error) {
    console.log('error', error)
  }
}
const numberText = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen'
]
// for 0-19 only
export const getNumberText = (number) => numberText[number]

export const getEarningSum = (payslipValues) => {
  return (
    payslipValues.ordinary +
    payslipValues.directorsFee +
    payslipValues.overtime +
    payslipValues.leaveLoading +
    payslipValues.bonus +
    payslipValues.allowanceTaxSuper +
    payslipValues.allowanceTax +
    payslipValues.allowanceExemption +
    payslipValues.etp_r +
    payslipValues.etp_o +
    payslipValues.unused_leave +
    payslipValues.lump_sum_a +
    payslipValues.lump_sum_d +
    payslipValues.paidLeaveObj.P +
    payslipValues.paidLeaveObj.W +
    payslipValues.paidLeaveObj.A
  )
}

export const addEPS = (x) => {
  return Math.round(((x || 0) + (x < 0 ? -1e-10 : +1e-10)) * 1e9) / 1e9
}

export const rounding = (value, digits = 2) => {
  if (typeof value === 'string' && !isNaN(value)) {
    value = parseFloat(value)
  }

  const multiplier = 10 ** digits
  return Math.round(addEPS(addEPS(value) * multiplier)) / multiplier
}

export const r = (val, digits = 2) => {
  if (!val) return 0
  return rounding(val, digits)
}

export const r2 = (val) => {
  return r(val, 2)
}
