import { hideAgentBanner, setModals, setInitialState } from '../../reducers/appSlice'
const { setCurrentClient, setModalAgentSubscription } = require('/src/reducers/subscriptionSlice')

angular
  .module('starter.agent.controllers')
  .controller(
    'agentProgramCtrl',
    function (
      $rootScope,
      $scope,
      $state,
      Http,
      Analytics,
      $ngRedux,
      AgentClientInvitationModal,
      HttpPromiseAndLocalStorage,
      Service,
      API_ENDPOINT,
      $window
    ) {
      $scope.shouldRefetchClients = false
      $scope.isJoinLoading = false
      $scope.openModalAgentSubscription = false
      $ngRedux.connect(
        (state) => ({ openModalAgentSubscription: state.subscription.openModalAgentSubscription } || {})
      )($scope)
      $scope.openInvitationModal = AgentClientInvitationModal.openInvitationModal
      $scope.onDoneRefetch = () => {
        $scope.shouldRefetchClients = false
      }
      $scope.onDonePayment = () => {
        delete $window.localStorage.pr_client_id
        $scope.shouldRefetchClients = true
        $ngRedux.dispatch(setModalAgentSubscription(false))
      }
      $scope.onClosePayment = () => {
        delete $window.localStorage.pr_client_id
        $ngRedux.dispatch(setModalAgentSubscription(false))
      }
      $scope.onGoToClientImport = () => $state.go('agent.import.business')
      $scope.paymentSummaryData = {
        HttpPromise: HttpPromiseAndLocalStorage,
        helpers: Service,
        API_ENDPOINT: API_ENDPOINT,
        isAgent: $rootScope.isAgent,
        onDonePayment: $scope.onDonePayment,
        handleClose: $scope.onClosePayment
      }
      $scope.onNavigateToBulkImport = () => {
        $state.go('agent.import.business')
      }

      $scope.onCloseAgentProgramBanner = () => {
        $ngRedux.dispatch(hideAgentBanner())
        localStorage.setItem('agent_program_banner', 'false')
      }

      $scope.onClickSubscribe = (client) => {
        $ngRedux.dispatch(setCurrentClient(client))
        $ngRedux.dispatch(setModalAgentSubscription(true))
      }

      $scope.onUpdateBankDetails = () => {
        $ngRedux.dispatch(
          setModals([
            {
              kind: 'BankDetailsForm',
              options: {
                overlay: true
              }
            }
          ])
        )
      }

      $scope.onJoinAgentProgram = () => {
        $scope.isJoinLoading = true
        Http(
          {
            method: 'post',
            api: '/v1/agent/agent-program/join'
          },
          () => {
            Http(
              {
                method: 'get',
                api: '/v1/initial/'
              },
              (res) => {
                $ngRedux.dispatch(setInitialState(res))
                $scope.isJoinLoading = false
                Analytics.logEvent('Agent program: User joins agent program via agent program dashboard')
                $scope.onCloseAgentProgramBanner()
                $ngRedux.dispatch(
                  setModals([
                    {
                      kind: 'AgentProgramWelcomeModal',
                      channel: 'local',
                      options: {
                        overlay: true
                      }
                    }
                  ])
                )
              }
            )
          }
        )
      }
    }
  )
