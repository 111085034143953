angular.module('starter.directives').directive('zxPasswordMeter', function () {
  return {
    scope: {
      value: '@',
      max: '@?'
    },
    template:
      '<uib-progressbar ng-style="{\'opacity\':0.5+value/8}" class=\'zxcvbn-progress\' value="value" max="max" type="{{ type }}"><span ng-class="{\'black-text\': value == 0}">{{ text }}</span></uib-progressbar>',
    link: function (scope) {
      scope.type = 'danger'
      scope.max = !scope.max ? 100 : scope.max

      scope.$watch('value', function (newValue) {
        var strenghPercent = newValue / scope.max

        if (strenghPercent === 0) {
          scope.text = 'Too weak'
        } else if (strenghPercent <= 0.25) {
          scope.type = 'danger'
          scope.text = 'Weak'
        } else if (strenghPercent <= 0.5) {
          scope.type = 'warning'
          scope.text = 'Moderate'
        } else if (strenghPercent <= 0.75) {
          scope.type = 'warning'
          scope.text = 'Strong'
        } else {
          scope.type = 'success'
          scope.text = 'Perfect'
        }
      })
    }
  }
})
