import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { GB_FEATURES } from '../../../controllers/consts'
import { growthbook } from './growthbook'
import { setGbFeatures as setAppGbFeatures } from '/reducers/appSlice'
import { useAuth } from '/scheduling/hooks/useAuth'
import { getData } from '@payroller/pds'

// TODO: move this initialize code to when redux inits.
export function useGrowthBookFeatures() {
  const dispatch = useDispatch()
  const setGbFeatures = (val) => {
    dispatch(setAppGbFeatures(val))
  }

  const companyId = useSelector((state) => state.app.company?._id)

  const { ready, user, agent } = useAuth()

  useEffect(() => {
    if (!ready || isEmpty(user) || !companyId) return

    // Set user attributes for targeting (from cookie, auth system, etc.)
    const id = user?._id || agent?._id
    const email = user?.e || agent?.e
    const signupDate = user?.createdAt || user?.at || agent.createdAt
    growthbook.setAttributes({
      id,
      companyId,
      email,
      ...(signupDate && { signupDate: new Date(signupDate).toISOString() })
    })

    // Load feature definitions from GrowthBook API
    // fetch(`https://cdn.growthbook.io/api/features/key_john_d893619f175c9c5b?project=prj_19g61nl5k8trmb`) // to test locally ('john-test' env)
    getData({ url: `${process.env.API_ENDPOINT}/growthbook/features` }).then((data) => {
      const features = data.features
      growthbook.setFeatures(features)

      // feature values with "Rollout Rules"
      const featureValues = {}
      for (let i = 0; i < GB_FEATURES.length; i++) {
        const name = GB_FEATURES[i]
        const value = growthbook.getFeatureValue(name)
        featureValues[name] = value
      }

      // share features to redux
      setGbFeatures({ loaded: true, ...featureValues })
    })
  }, [ready, user, companyId])
}
