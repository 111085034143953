angular
  .module('starter.auth.controllers')
  .controller(
    'memberSignupCtrl',
    function ($scope, $rootScope, $stateParams, Service, UserAuthFactory, $state, $window) {
      $scope.auth = {
        e: $stateParams.email
      }

      $scope.doSignUp = function () {
        UserAuthFactory.memberRegister($scope.auth, $stateParams.companyId).then(
          function (responseData) {
            // user 정보를 가져온다.
            $scope.auth = {}
            $state.go('admin.profile')
            $rootScope.user = responseData.user

            if (!responseData.company.ag) {
              $state.go('business.run.overview')
            } else {
              $window.localStorage.pr_agent_id = responseData.company._id
              $rootScope.isAgent = true
              $state.go('agent.clients')
            }
          },
          function (errMsg) {
            Service.alert('Register failed!', errMsg, 'error')
          }
        )
      }
    }
  )
