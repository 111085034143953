import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from '../api/prepareHeaders'

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.API_ENDPOINT, prepareHeaders }),
  endpoints: (build) => ({
    superannuationSummary: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/v1/reports/super-summary',
        method: 'POST',
        body: patch
      })
    })
  })
})

// export const { useMutation } = reportApi.endpoints.superannuationSummary
