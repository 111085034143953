import { r2, getFinancialYear, calculateOrdinaryWithoutLeave } from '/src/services'
import { setModals } from '/src/reducers/appSlice'

angular
  .module('starter.business.controllers')
  .controller(
    'finaliseStpCtrl',
    function ($scope, $rootScope, $ngRedux, Service, $filter, Http, STPService, Analytics) {
      var totalObj = {}
      // var taxYear = 2020;
      // var taxYearPrint = "2019-2020";

      $scope.dateNow = $rootScope.company?.features?.test_dateNow || new Date()
      $scope.taxYear = getFinancialYear($scope.dateNow)
      $scope.taxYearPrint = `${$scope.taxYear - 1}-${$scope.taxYear}`

      // $scope.fyOptions = [
      //   { n: '2019-2020', v: 2020 },
      //   { n: '2020-2021', v: 2021 },
      //   { n: '2021-2022', v: 2022 }
      // ]
      let totalYear = $scope.taxYear - 2018
      $scope.fyOptions = [...Array(totalYear)].map((_, i) => {
        return { name: `${$scope.taxYear - i - 1} - ${$scope.taxYear - i}`, val: $scope.taxYear - i }
      })

      // to set selectTaxYear's default value to previous year when it is July.
      if ($scope.dateNow.getMonth() === 6) {
        $scope.taxYear -= 1
      }

      $scope.finalAdjustment = {
        status: 'draft',
        employees: [],
        taxYear: $scope.taxYear // 2020 (2019-2020)
      }

      var adjustmentTemplate = {
        grossPayment: 0,
        payg: 0,
        lump_sum_a_tax: 0,
        unused_leave_tax: 0,
        gross2020: 0,
        ordinary: 0,
        overtime: 0,
        bonus: 0,
        superGuarantee: 0,
        superRESC: 0,
        etpTypeR: 0,
        etpTypeRTax: 0,
        etpTypeO: 0,
        etpTypeOTax: 0,
        lumSumA: 0,
        lumSumB: 0,
        lumSumD: 0,
        lumSumE: 0,
        reimbursement: 0,
        terminationDate: Date,
        allowances: {
          car: 0,
          transport: 0,
          laundry: 0,
          meals: 0,
          travel: 0,
          other: 0,
          jobKeeperTopUp: 0,

          AD: 0,
          CD: 0,
          KN: 0,
          LD: 0,
          MD: 0,
          OD: 0, // is total of G1, H1, ND, T1, U1 and V1
          QN: 0,
          RD: 0,
          TD: 0,

          odGeneral: 0,
          odHomeOffice: 0,
          odNonDeductible: 0,
          odTransportFares: 0,
          odUniform: 0,
          odPrivateVehicle: 0
        },
        deductions: {
          fees: 0,
          workplaceGiving: 0,

          F: 0,
          W: 0,
          G: 0,
          D: 0
        },
        paidLeaveObj: {
          C: 0,
          U: 0,
          P: 0,
          W: 0,
          A: 0,
          O: 0
        },
        RFBTaxable: 0,
        RFBExempt: 0
      }

      var init = () => {
        $scope.isLoading = true
        $scope.finalAdjustment = {
          status: 'draft',
          employees: [],
          taxYear: $scope.taxYear // 2020 (2019-2020)
        }
        Http(
          {
            method: 'get',
            api: '/v1/get-finalisation-ytd?taxYear=' + $scope.taxYear
          },
          (res) => {
            $scope.isLoading = false

            if (res.employeeList.length > 0) {
              $scope.employeeList = res.employeeList
              totalObj = res.totalYtd
            } else {
              $scope.employeeList = []
            }
            let submitWith2020 = $rootScope.company.stp2020 && $scope.employeeList.every((x) => x.tx?.stp2020)

            if (res.finalAdjustment) {
              // update $rootScope.finalAdjustments
              if ($rootScope.finalAdjustments) {
                let adjustmentExists = false
                $rootScope.finalAdjustments.map((finalEach, index) => {
                  if (res.finalAdjustment._id === finalEach._id) {
                    $rootScope.finalAdjustments[index] = res.finalAdjustment
                    adjustmentExists = true
                  }
                })

                if (!adjustmentExists) {
                  $rootScope.finalAdjustments.push(res.finalAdjustment)
                }
              } else {
                $rootScope.finalAdjustments = [res.finalAdjustment]
              }
              var finalAdjustmentsByEmployee = {}
              for (const x of res.finalAdjustment.employees) {
                finalAdjustmentsByEmployee[x._id] = x
              }

              let employees = []
              $scope.employeeList.map((eachEmployee) => {
                var adjustmentObj
                if (finalAdjustmentsByEmployee[eachEmployee._id]) {
                  adjustmentObj = finalAdjustmentsByEmployee[eachEmployee._id]
                } else {
                  adjustmentObj = adjustmentTemplate
                }

                employees.push(
                  angular.copy({
                    ...adjustmentObj,
                    _id: eachEmployee._id,
                    submitWith2020,
                    employeeData: angular.copy(eachEmployee)
                  })
                )
              })

              res.finalAdjustment.employees = angular.copy(employees)
              $scope.finalAdjustment = res.finalAdjustment
            } else {
              let employees = []

              for (var eachEmployee of $scope.employeeList) {
                employees.push(
                  angular.copy({
                    ...adjustmentTemplate,
                    _id: eachEmployee._id,
                    submitWith2020,
                    employeeData: eachEmployee
                  })
                )
                // employees.push(angular.copy(Object.assign(adjustmentTemplate, {
                //   _id: eachEmployee._id,
                //   employeeData: eachEmployee
                // })))
              }

              $scope.finalAdjustment.employees = employees
            }
          },
          (err) => {
            $scope.isLoading = false
            // $state.go("err404");
            // redirect 404 page
          }
        )
      }
      init()
      $scope.loadFinalisationData = () => {
        init()
      }

      $scope.viewEachEmployeeDetails = (employeeAdjustment) => {
        var saveAdjustment = (employeeAdjustment) => {
          $scope.finalAdjustment.employees.map((eachEmployee, index) => {
            if (eachEmployee._id === employeeAdjustment._id) {
              $scope.finalAdjustment.employees[index] = employeeAdjustment
            }
          })
        }

        Service.modal(
          'eachEmployeeInFinaliseStp.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.calculateOrdinaryWithoutLeave = calculateOrdinaryWithoutLeave
              $scope.r2 = r2

              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }
              $scope.r = Service.r
              // $scope.superGuarantee = Math.round((parseFloat(payslip.s_g) || 0)*100)/100;
              var employeeAdjustmentTemp = angular.copy(employeeAdjustment)
              // $scope.taxYearPrint = taxYearPrint;
              $scope.employeeAdjustment = employeeAdjustmentTemp
              $scope.employeeData = employeeAdjustmentTemp.employeeData
              $scope.YTD = employeeAdjustmentTemp.employeeData.ytd

              $scope.saveAdjustment = () => {
                saveAdjustment($scope.employeeAdjustment)
                $scope.cancel()
              }
            }
          ],
          { size: 'lg' }
        )
      }

      $scope.saveAsDraft = () => {
        Http(
          {
            method: 'post',
            api: '/v1/save-final-adjustment/',
            data: {
              finalAdjustment: $scope.finalAdjustment,
              taxYear: $scope.taxYear
            },
            disableErrorMsg: true
          },
          (res) => {
            init()
            Service.alert('Saved', 'Adjustment has been saved successfully.', 'success')
          },
          (err) => {
            Service.alert('Error', err && err.message ? err.message : null, 'error')
          }
        )
      }
      $scope.finalSTP = () => {
        // 1. merge report values for validation
        // 2. verify employee details
        // 3. open declaration modal
        // 4. send data to server

        // 1
        const finalEmployees = $scope.finalAdjustment.employees.map((emp) => {
          return emp.employeeData
        })
        // $rootScope.stpTransitionState.callback = () => {
        let finalStpEmployeeArray = []
        let adjustByEmployee = {}
        for (const x of $scope.finalAdjustment.employees) {
          adjustByEmployee[x._id] = x
        }
        $scope.employeeList.map((eachEmployee) => {
          const employeeId = eachEmployee._id
          const ytdEach = eachEmployee.ytd
          const finalAdjustment = adjustByEmployee[eachEmployee._id]

          if (finalAdjustment) {
            finalStpEmployeeArray.push({
              employeeData: eachEmployee,
              stpFigures: {
                grossPayment: r2(ytdEach.grossPayment + r2(finalAdjustment.grossPayment)),
                ordinary: r2(ytdEach.ordinary + r2(finalAdjustment.ordinary)),
                payg: r2(ytdEach.payg + r2(finalAdjustment.payg)),
                lump_sum_a_tax: r2(ytdEach.lump_sum_a_tax + r2(finalAdjustment.lump_sum_a_tax)),
                unused_leave_tax: r2(ytdEach.unused_leave_tax + r2(finalAdjustment.unused_leave_tax)),
                super_g: r2(ytdEach.super_g + r2(finalAdjustment.superGuarantee)),
                super_ec: r2(ytdEach.super_ss + ytdEach.super_ec + r2(finalAdjustment.superRESC)),
                etp_r: r2(ytdEach.etp_r + r2(finalAdjustment.etpTypeR)),
                etp_r_tax: r2(ytdEach.etp_r_tax + r2(finalAdjustment.etpTypeRTax)),
                etp_o: r2(ytdEach.etp_o + r2(finalAdjustment.etpTypeO)),
                etp_o_tax: r2(ytdEach.etp_o_tax + r2(finalAdjustment.etpTypeOTax)),
                lump_sum_a: r2(ytdEach.lump_sum_a + r2(finalAdjustment.lumSumA)),
                lump_sum_b: r2(finalAdjustment.lumSumB),
                lump_sum_d: r2(ytdEach.lump_sum_d + r2(finalAdjustment.lumSumD)),
                lump_sum_e: r2(finalAdjustment.lumSumE),

                allowanceObj: {
                  car: r2(ytdEach.allowanceObj.car + r2(finalAdjustment.allowances.car)),
                  transport: r2(ytdEach.allowanceObj.transport + r2(finalAdjustment.allowances.transport)),
                  laundry: r2(ytdEach.allowanceObj.laundry + r2(finalAdjustment.allowances.laundry)),
                  meals: r2(ytdEach.allowanceObj.meals + r2(finalAdjustment.allowances.meals)),
                  travel: r2(ytdEach.allowanceObj.travel + r2(finalAdjustment.allowances.travel)),

                  // ytdEach.allowanceObj.other not used in STPV2/2020
                  // all legacy STPV1 "other" allowances fall into ytdEach.allowanceObj.o
                  other: r2(
                    r2(ytdEach.allowanceObj.other) + r2(ytdEach.allowanceObj.o) + r2(finalAdjustment.allowances.other)
                  ),
                  jobKeeperTopUp: r2(
                    ytdEach.allowanceObj.jobKeeperTopUp + r2(finalAdjustment.allowances.jobKeeperTopUp)
                  ),

                  AD: r2(ytdEach.allowanceObj.AD + r2(finalAdjustment.allowances.AD)),
                  CD: r2(ytdEach.allowanceObj.CD + r2(finalAdjustment.allowances.CD)),
                  KN: r2(ytdEach.allowanceObj.KN + r2(finalAdjustment.allowances.KN)),
                  LD: r2(ytdEach.allowanceObj.LD + r2(finalAdjustment.allowances.LD)),
                  MD: r2(ytdEach.allowanceObj.MD + r2(finalAdjustment.allowances.MD)),
                  QN: r2(ytdEach.allowanceObj.QN + r2(finalAdjustment.allowances.QN)),
                  RD: r2(ytdEach.allowanceObj.RD + r2(finalAdjustment.allowances.RD)),
                  TD: r2(ytdEach.allowanceObj.TD + r2(finalAdjustment.allowances.TD)),
                  // ytdEach.OD never used
                  OD: r2(ytdEach.allowanceObj.OD + r2(finalAdjustment.allowances.OD)),

                  odGeneral: r2(ytdEach.allowanceObj.odGeneral + r2(finalAdjustment.allowances.odGeneral)),
                  odHomeOffice: r2(ytdEach.allowanceObj.odHomeOffice + r2(finalAdjustment.allowances.odHomeOffice)),
                  odNonDeductible: r2(
                    ytdEach.allowanceObj.odNonDeductible + r2(finalAdjustment.allowances.odNonDeductible)
                  ),
                  odTransportFares: r2(
                    ytdEach.allowanceObj.odTransportFares + r2(finalAdjustment.allowances.odTransportFares)
                  ),
                  odUniform: r2(ytdEach.allowanceObj.odUniform + r2(finalAdjustment.allowances.odUniform)),
                  odPrivateVehicle: r2(
                    ytdEach.allowanceObj.odPrivateVehicle + r2(finalAdjustment.allowances.odPrivateVehicle)
                  )
                },
                deductionObj: {
                  fees: r2(ytdEach.deductionObj.fees + r2(finalAdjustment.deductions.fees)),
                  workplaceGiving: r2(
                    ytdEach.deductionObj.workplaceGiving + r2(finalAdjustment.deductions.workplaceGiving)
                  ),

                  F: r2(ytdEach.deductionObj.F + r2(finalAdjustment.deductions.F)),
                  W: r2(ytdEach.deductionObj.W + r2(finalAdjustment.deductions.W)),
                  G: r2(ytdEach.deductionObj.G + r2(finalAdjustment.deductions.G)),
                  D: r2(ytdEach.deductionObj.D + r2(finalAdjustment.deductions.D))
                },
                RFBTaxable: r2(finalAdjustment.RFBTaxable),
                RFBExempt: r2(finalAdjustment.RFBExempt)
              }
            })
          }
        })

        STPService.verify(
          {
            company: $rootScope.company,
            employees: $rootScope.employees,
            finalStpEmployeeArray: finalStpEmployeeArray
          },
          () => {
            STPService.declarationModal(
              !$rootScope.isAgent && $rootScope.company.stp && $rootScope.company.stp.cn
                ? $rootScope.company.stp.cn
                : '',
              $rootScope.isAgent ? 'agent' : 'employer',
              (signatoryName) => {
                Http(
                  {
                    method: 'post',
                    api: '/v1/finalise-stp/',
                    data: {
                      signatoryName: signatoryName,
                      signatoryDate: $filter('date')(new Date(), 'yyyy-MM-dd'), // server date 와 browser data가 달라서
                      finalAdjustment: $scope.finalAdjustment
                    },
                    disableErrorMsg: true
                  },
                  (res) => {
                    Analytics.logEvent('Finalise STP Submitted')
                    Service.alert(
                      'Sent to ATO',
                      'Please allow the ATO 72 hours for your pay runs to be processed.',
                      'success'
                    )
                    init()
                    // $scope.employeeList.map((employeeEach,index)=>{
                    // 	if(employeeEach.checked){
                    // 		$scope.employeeList[index].fn={y18:{s:"p"}};
                    // 	}
                    // });
                  },
                  (err) => {
                    Service.alert(
                      'Error',
                      err && err.message ? err.message : 'STP submission is not successful',
                      'error'
                    )
                  }
                )
              }
            )
          }
        )
        // }
        // $ngRedux.dispatch(setMyEmployees(finalEmployees))
      }

      $scope.openMigrateSTPReportModal = () => {
        $ngRedux.dispatch(
          setModals([
            {
              kind: 'STPReportMigrationModal',
              channel: 'local',
              options: {
                data: {
                  taxYear: $scope.taxYear,
                  company: $rootScope.company,
                  employees: $scope.employeeList
                }
              }
            }
          ])
        )
      }
    }
  )
