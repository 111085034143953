import React from 'react'
import { usePaywall } from '../usePaywall'
import { PERIOD_NAMES } from '../../../../../constant'
import * as s from './styles'
import { cx } from '../../../../../utils'

export function PeriodButton({ name }) {
  const { period, setPeriod } = usePaywall()

  const active = period === name

  return (
    <button className={cx(s.periodButton, active ? s.periodActive : s.periodNonActive)} onClick={() => setPeriod(name)}>
      {PERIOD_NAMES[name]}
    </button>
  )
}
