import { useRef, useCallback } from 'react'

export function useFocusNext() {
  const inputs = useRef([])

  const handler = (e) => {
    const { maxLength, value } = e.target

    if (isFinite(e.key) && value.length >= maxLength) {
      // Required if the inputs can be reordered
      inputs.current = inputs.current
        .filter((control) => document.body.contains(control))
        .sort((a, b) => (a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1))

      const index = inputs.current.indexOf(e.target)
      const next = inputs.current[index + 1]
      next && next.focus()

      // IE 9, 10
      e.preventDefault()
    }
  }

  return useCallback((el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el) // push all registered inputs
      el.addEventListener('keyup', handler) // better to use with onChange events. it can remove isFinite(numeric) checks.
    }
  }, []) // same as "return ref" but customizable + memoized
}
