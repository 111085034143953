import { getQuarter } from './getQuarter'
import { openSuperSettingModal, showGoogleReview, updateParticipant } from '../controllers/business/businessCtrl'
import moment from 'moment-timezone'
import { r2 } from '../services'

export function getSubmitList({
  frequency,
  ignoreBeforeDate,
  startDate,
  endDate,
  superStreamBatches,
  payRuns,
  employees,
  directDebitMethods,
  defaultDirectDebitAuthorityId
}) {
  // 쉽게 ignore 하기 위해 이렇게 만들었다.
  let ignoredList = []
  if (superStreamBatches && superStreamBatches.length > 0) {
    superStreamBatches.map((eachSuperStreamBatch) => {
      if (eachSuperStreamBatch && eachSuperStreamBatch.status2 !== 'CANCELLED') {
        const dateCreated = new Date(eachSuperStreamBatch.createdAt)
        // The segment codes were incorrect before May
        if (dateCreated <= new Date(2021, 4, 27, 9, 37) && eachSuperStreamBatch.frequency === 'month') {
          ignoredList.push(
            `${eachSuperStreamBatch.year}-${eachSuperStreamBatch.segment - 1}-${eachSuperStreamBatch.frequency}`
          )
        } else if (eachSuperStreamBatch.frequency === 'everyPayRun') {
          ignoredList.push(
            `${eachSuperStreamBatch.year}-${moment(eachSuperStreamBatch.startDate).format('YYYY-MM-DD')}-${
              eachSuperStreamBatch.segment
            }-${eachSuperStreamBatch.frequency}`
          )
        } else {
          ignoredList.push(
            `${eachSuperStreamBatch.year}-${eachSuperStreamBatch.segment}-${eachSuperStreamBatch.frequency}`
          )
        }
      }
    })
  }
  // 기간에 따라서 pay run합치기
  const readyToSubmitList = []
  let payment = defaultDirectDebitAuthorityId || 'bpay'
  if (payment !== 'bpay') {
    payment = (directDebitMethods || []).find((x) => x.id === payment)
  } else {
    payment = { id: 'bpay' }
  }
  if (!payment?.id) {
    payment = { id: 'bpay' }
  }
  for (const payRun of payRuns) {
    if (payRun.s === 'd') {
      continue
    }

    // get year / quarter
    var year = new Date(payRun.pd).getFullYear()
    var segment, payrunQuarter, textDisplay, payStartDate, payEndDate

    if (frequency === 'quarter') {
      const pdDate = moment.utc(new Date(payRun.pd))
      payrunQuarter = getQuarter(pdDate)
      segment = moment(payRun.pd).quarter()

      textDisplay = payrunQuarter.textDisplay
      payStartDate = moment().utc().year(year).quarter(segment).startOf('quarter').format()
      payEndDate = moment().utc().year(year).quarter(segment).endOf('quarter').format()
    } else if (frequency === 'month') {
      segment = moment(payRun.pd).month()
      textDisplay = year + ' | ' + moment(payRun.pd).format('MMMM')
      payStartDate = moment().utc().year(year).month(segment).startOf('month').format()
      payEndDate = moment().utc().year(year).month(segment).endOf('month').format()
    } else if (frequency === 'custom') {
      textDisplay = moment(startDate).format('DD MMM YYYY') + ' ~ ' + moment(endDate).format('DD MMM YYYY')
      payStartDate = moment(startDate).format()
      payEndDate = moment(endDate).format()
    } else {
      // everyPayRun
      segment = moment(payRun.pd).format('YYYY-MM-DD')
      textDisplay = moment(payRun.pd).format('DD MMM YYYY')
      payStartDate = moment(payRun.sd).format()
      payEndDate = moment(payRun.ed).format()
    }
    let segmentCode
    if (frequency !== 'custom') {
      if (frequency === 'everyPayRun') {
        segmentCode = `${year}-${moment(payRun.sd).format('YYYY-MM-DD')}-${segment}-${frequency}`
      } else {
        segmentCode = `${year}-${segment}-${frequency}`
      }
    }

    // ignore 리스트가 segmentCode 를 가지고 있으면 무시한다.
    if (
      (frequency !== 'custom' &&
        !ignoredList.includes(segmentCode) &&
        (!ignoreBeforeDate || new Date(payRun.pd) >= new Date(ignoreBeforeDate))) ||
      (frequency === 'custom' &&
        new Date(payStartDate) <= new Date(payRun.pd) &&
        new Date(payRun.pd) <= new Date(payEndDate))
    ) {
      // readyToSubmitList 안에 해당 year 와 quarter가 존재하는지 체크 있으면 있는데에 push 없으면 새로 만든다.
      let exists_readyToSubmitList = false

      if (frequency === 'custom') {
        // custom일때 계속 push 한다.
        if (readyToSubmitList.length > 0) {
          exists_readyToSubmitList = true
          readyToSubmitList[0].payRuns.push(payRun)
        }
      } else if (frequency === 'everyPayRun') {
        readyToSubmitList.map((eachList, index2) => {
          if (eachList.year == year && eachList.segmentCode == segmentCode) {
            readyToSubmitList[index2].payRuns.push(payRun)
            exists_readyToSubmitList = true
          }
        })
      } else {
        // custom 아닐때는 year 와 segment 에 맞춰서 push 한다.
        readyToSubmitList.map((eachList, index2) => {
          if (eachList.year == year && eachList.segment == segment) {
            readyToSubmitList[index2].payRuns.push(payRun)
            exists_readyToSubmitList = true
          }
        })
      }
      if (!exists_readyToSubmitList) {
        readyToSubmitList.push({
          frequency: frequency,
          year: year,
          segment: segment,
          payStartDate: payStartDate,
          payEndDate: payEndDate,
          paymentDate: frequency === 'everyPayRun' ? payRun.pd : null,
          segmentCode: segmentCode,
          textDisplay: textDisplay,
          payRuns: [payRun],
          paymentMethod: payment // directDebitMethods.length > 0 ? directDebitMethods[0] : { id: 'bpay' }
        })
      }
    }
  }

  readyToSubmitList.map((eachItem, index) => {
    var dummyEmployeeData = []
    var superGuaranteeKingTotal = 0
    var superSacrificeKingTotal = 0
    var employerContributionKingTotal = 0
    // employee 에 따라서 따로 데이터 만들어주기
    eachItem.payRuns.map((eachPayRun) => {
      eachPayRun.psl.map((eachPayslip) => {
        let exists_employee = false
        var superGuarantee = r2(eachPayslip.s_g || 0)
        var superSacrifice = r2(eachPayslip.s_ss || 0)
        var employerContribution = r2(eachPayslip.s_ec || 0)
        superGuaranteeKingTotal += superGuarantee
        superSacrificeKingTotal += superSacrifice
        employerContributionKingTotal += employerContribution

        var dummyPayslip = {
          _id: eachPayslip._id,
          // employeeId: eachPayslip.ei,
          include: true,
          startDate: eachPayRun.sd,
          endDate: eachPayRun.ed,
          paymentDate: eachPayRun.pd,
          superGuarantee: eachPayslip.s_g,
          superSacrifice: eachPayslip.s_ss,
          employerContribution: eachPayslip.s_ec
        }

        dummyEmployeeData.map((eachEmployee, index4) => {
          if (eachEmployee.employeeDetails && eachEmployee.employeeDetails._id == eachPayslip.ei) {
            exists_employee = true
            dummyEmployeeData[index4].superGuaranteeTotal += superGuarantee
            dummyEmployeeData[index4].superSacrificeTotal += superSacrifice
            dummyEmployeeData[index4].employerContributionTotal += employerContribution
            dummyEmployeeData[index4].payslips.push(dummyPayslip)
          }
        })

        if (!exists_employee) {
          let empIdx = employees.map((x) => x._id).indexOf(eachPayslip.ei)

          if (empIdx !== -1) {
            let emp = employees[empIdx]
            dummyEmployeeData.push({
              _id: emp._id,
              include: true,
              employeeDetails: emp,
              superGuaranteeTotal: r2(eachPayslip.s_g || 0),
              superSacrificeTotal: r2(eachPayslip.s_ss || 0),
              employerContributionTotal: r2(eachPayslip.s_ec || 0),
              payslips: [dummyPayslip]
            })
          }
        }
      })
    })

    readyToSubmitList[index].employeeData = dummyEmployeeData
    readyToSubmitList[index].superGuaranteeKingTotal = superGuaranteeKingTotal
    readyToSubmitList[index].superSacrificeKingTotal = superSacrificeKingTotal
    readyToSubmitList[index].employerContributionKingTotal = employerContributionKingTotal
  })

  readyToSubmitList.sort((obj1, obj2) => (new Date(obj1.segmentCode) < new Date(obj2.segmentCode) ? -1 : 1))

  return readyToSubmitList
}

export function sharedSuperCtrl({
  $scope,
  $rootScope,
  Service,
  $state,
  $ngRedux,
  Http,
  Http2,
  $timeout,
  CompanyAction,
  employeeModalService,
  DatepickerService,
  Analytics,
  $uibModalInstance,
  isModal = false
}) {
  $ngRedux.connect((state) => {
    return { subscription: state.subscription }
  })($scope)

  let $controllerScope = $scope

  const { isSubscribed, dateTrial } = $scope.subscription
  const isSubscriptionTrial = isSubscribed && dateTrial >= 1
  const hasSmsf = $rootScope.employees?.some((employee) => employee.su?.ty === 's')
  $scope.nThToggle = null
  $scope.nThEmployeeToggle = null
  $scope.nThToggle_submitted = null
  $scope.frequency =
    !$rootScope.company || !$rootScope.company?.superStream || !$rootScope.company?.superStream.frequency
      ? 'quarter'
      : $rootScope.company?.superStream.frequency
  $scope.ignoreBeforeDate =
    $rootScope.company && $rootScope.company?.superStream && $rootScope.company?.superStream.ignoreBeforeDate
      ? moment(angular.copy($rootScope.company?.superStream.ignoreBeforeDate)).utcOffset(0, false).format()
      : null
  // var participantDetails;
  var directDebitMethods = []

  // get payment method
  var init = async () => {
    $scope.nThToggle = null
    $scope.nThEmployeeToggle = null
    $scope.nThToggle_submitted = null
    $scope.completeLoading = false
    $scope.beamConnected = false

    if (!$rootScope.company) return

    if ($rootScope.company?.superStream && !$rootScope.company.superStream.beamAcceptDate) {
      var ret = await Http2({
        method: 'get',
        api: '/v1/super/check-beamconnect',
        data: {
          companyId: $rootScope.company._id
        }
      })
      $rootScope.company.superStream.connectedDate = ret.acceptDate
    }

    if ($rootScope.company?.superStream && $rootScope.company?.superStream.connectedDate) {
      Http(
        {
          method: 'get',
          api: '/v1/super/participant-details',
          disableErrorMsg: true
        },
        (res) => {
          Analytics.logEvent('SuperStream Connection Success', {
            provider: 'Beam'
          })
          $scope.hasAlreadyTransactedBpay =
            !!res.superStreamBatches?.length &&
            res.superStreamBatches.some((fund) => {
              return fund.status2 === 'SENT_TO_FUND' && fund.paymentDetail.paymentType === 'BPAY'
            })
          $scope.completeLoading = true
          $scope.beamConnected = true

          res.superStreamBatches.map((eachSuperBatch, index14) => {
            if (eachSuperBatch.employees) {
              eachSuperBatch.employees.map((eachEmployeeSuper, index15) => {
                var employeeDetails = Service.findObjByKeyValue($rootScope.employees, '_id', eachEmployeeSuper._id)
                if (employeeDetails) {
                  res.superStreamBatches[index14].employees[index15].name =
                    employeeDetails.f + ' ' + (employeeDetails.l ? employeeDetails.l.charAt(0).toUpperCase() + '.' : '')
                }
              })
            }
          })

          // participantDetails = res.participantDetails;
          res.beamNotifications.map((notification) => {
            res.superStreamBatches.map((eachSuperBatch, index14) => {
              if (notification.processId === eachSuperBatch._id) {
                if (res.superStreamBatches[index14].notifications) {
                  res.superStreamBatches[index14].notifications.push(notification)
                } else {
                  res.superStreamBatches[index14].notifications = [notification]
                }
              }
            })
          })
          $scope.superStreamBatches = [...res.superStreamBatches].sort((obj1, obj2) =>
            new Date(obj1.endDate ?? obj1.createdAt) < new Date(obj2.endDate ?? obj2.createdAt) ? 1 : -1
          )
          if (res.participantDetails && res.participantDetails.directDebitAuthorities) {
            for (let debitAuthority of res.participantDetails.directDebitAuthorities) {
              if (!debitAuthority.endDate || debitAuthority.endDate > new Date().toISOString()) {
                directDebitMethods.push({
                  id: debitAuthority.directDebitAuthorityId,
                  bsb: debitAuthority.account.bsb,
                  accountNumber: debitAuthority.account.accountNumber,
                  accountName: debitAuthority.account.accountName,
                  businessIdentifier: debitAuthority.businessIdentifier,
                  organisationName: debitAuthority.organisationName
                })
              }
            }
          }
          $scope.readyToSubmitList = getSubmitList({
            frequency: $scope.frequency,
            superStreamBatches: $scope.superStreamBatches,
            payRuns: $rootScope.payRuns,
            employees: $rootScope.employees,
            directDebitMethods: directDebitMethods,
            defaultDirectDebitAuthorityId: $rootScope.company?.superStream.defaultDirectDebitAuthorityId,
            ignoreBeforeDate: $scope.ignoreBeforeDate
          })
          checkIfIgnoreListIsEmpty()
        },
        (err) => {
          $scope.completeLoading = true
          if (
            err.code == 'noParticipant' &&
            $rootScope.company?.superStream &&
            $rootScope.company?.superStream.connectedDate
          ) {
            $rootScope.company.superStream.connectedDate = false
          }
        }
      )
    } else {
      $scope.completeLoading = true
    }
  }

  $scope.batchSubmittedText = (eachBatch) => {
    const startDate = moment.utc(new Date(eachBatch.startDate))
    const endDate = moment.utc(new Date(eachBatch.endDate))

    if (eachBatch.frequency === 'quarter') {
      if (eachBatch.segment <= 2) {
        return `Q${parseInt(eachBatch.segment) + 2} ${eachBatch.year - 1} - ${eachBatch.year}`
      } else {
        return `Q${parseInt(eachBatch.segment) - 2} ${eachBatch.year} - ${eachBatch.year + 1}`
      }
    }

    return eachBatch.frequency === 'month'
      ? $scope.displayMonth(eachBatch)
      : startDate.format('DD MMM YYYY') + ' ~ ' + endDate.format('DD MMM YYYY')
  }

  $scope.batchIgnoredText = (eachBatch) => {
    if (eachBatch.frequency === 'quarter') {
      if (eachBatch.segment <= 2) {
        return `Q${parseInt(eachBatch.segment) + 2} ${eachBatch.year - 1} - ${eachBatch.year}`
      } else {
        return `Q${parseInt(eachBatch.segment) - 2} ${eachBatch.year} - ${eachBatch.year + 1}`
      }
    }

    const startDate = moment.utc(new Date(eachBatch.startDate))
    const endDate = moment.utc(new Date(eachBatch.endDate))

    return eachBatch.frequency === 'month'
      ? eachBatch.year + ' | ' + eachBatch.segment
      : startDate.format('DD MMM YYYY') + ' ~ ' + endDate.format('DD MMM YYYY')
  }

  $scope.displayMonth = (batch) => {
    const dateCreated = new Date(batch.createdAt)
    // The segment codes were incorrect before May
    if (dateCreated <= new Date(2021, 4, 27, 9, 37)) {
      const theMonth = $scope.monthArray[batch.segment - 1]
      const theYear = batch.year
      return `${theYear} | ${theMonth}`
    }
    const theMonth = $scope.monthArray[batch.segment]
    const theYear = batch.year
    return `${theYear} | ${theMonth}`
  }

  // run init
  init()

  $scope.getNotificationMessage = (notification) => {
    if (notification.notificationType === 'STATUS') {
      var statusMessage
      switch (notification.payload.statusMessage) {
        case 'AWAITING_PAYMENT':
          statusMessage = 'Awaiting Payment'
          break
        case 'AWAITING_CLEARANCE':
          statusMessage = 'Awaiting Clearance'
          break
        case 'RECONCILED':
          statusMessage = 'Reconciled'
          break
        case 'SENT_TO_FUND':
          statusMessage = 'Sent to Fund'
          break
        case 'SENT_TO_FUND_WITH_REFUND':
          statusMessage = 'Sent to Fund with Refund'
          break
        case 'CANCELLED':
          statusMessage = 'Cancelled'
          break
        case 'REFUNDED':
          statusMessage = 'Refund'
          break
      }
      return 'The status was changed to <b>' + statusMessage + '</b>'
    } else if (
      notification.notificationType === 'CONTRIBUTION_RESPONSE' ||
      notification.notificationType === 'MEMBER_RESPONSE'
    ) {
      var html = notification.payload.summary
      var tableHtml = ''
      var employeeId
      if (notification.payload.eventItem && notification.payload.eventItem.parameters) {
        tableHtml += '<table class=\'table mt-3\'><thead><tr><th colspan="2">Details</th></tr></thead><tbody>'
        notification.payload.eventItem.parameters.map((param) => {
          tableHtml += '<tr><td>' + Service.camelToSentence(param.name) + '</td><td>' + param.value + '</td></tr>'
          // if(param.name === "ContextID"){
          if (param.name === 'Identifier') {
            employeeId = param.value
          }
        })
        tableHtml += '</tbody></table>'
        if (employeeId) {
          const index5 = Service.findIndexByKeyValue($rootScope.employees, '_id', employeeId)
          const employeeData = $rootScope.employees[index5]

          if (index5 != null && employeeData) {
            html += "<div class='mt-3'>Employee name : <b>" + employeeData.f + ' ' + employeeData.l + '</b></div>'
          }
        }
      }
      return html + tableHtml
    } else if (notification.notificationType === 'EXCEPTION') {
      var html1 = '<b>[' + notification.payload.exceptionType + ']</b> ' + notification.payload.message
      if (
        notification.payload.properties &&
        notification.payload.properties['Amount'] &&
        notification.payload.properties['BPAY® Biller Code'] &&
        notification.payload.properties['Reference']
      ) {
        html1 += '<table class=\'table mt-3\'><thead><tr><th colspan="2">Details</th></tr></thead><tbody>'
        html1 +=
          '<tr><td>BPAY® Biller Code</td><td>' + notification.payload.properties['BPAY® Biller Code'] + '</td></tr>'
        html1 += '<tr><td>Reference</td><td>' + notification.payload.properties['Reference'] + '</td></tr>'
        html1 += '<tr><td>Amount</td><td>$' + notification.payload.properties['Amount'] + '</td></tr>'
      }
      return html1
    }
  }

  var sumSuperData = (readyToSubmitListIndex) => {
    var superGuaranteeKingTotal = 0
    var superSacrificeKingTotal = 0
    var employerContributionKingTotal = 0
    $scope.readyToSubmitList[readyToSubmitListIndex].employeeData.map((eachEmployeeData, index10) => {
      var superGuaranteeTotal = 0
      var superSacrificeTotal = 0
      var employerContributionTotal = 0

      eachEmployeeData.payslips.map((eachPayslip, index11) => {
        if (eachPayslip.include) {
          superGuaranteeTotal += Service.rounding(eachPayslip.superGuarantee || 0)
          superSacrificeTotal += Service.rounding(eachPayslip.superSacrifice || 0)
          employerContributionTotal += Service.rounding(eachPayslip.employerContribution || 0)
        }
      })

      superGuaranteeKingTotal += superGuaranteeTotal
      superSacrificeKingTotal += superSacrificeTotal
      employerContributionKingTotal += employerContributionTotal

      $scope.readyToSubmitList[readyToSubmitListIndex].employeeData[index10].superGuaranteeTotal = superGuaranteeTotal
      $scope.readyToSubmitList[readyToSubmitListIndex].employeeData[index10].superSacrificeTotal = superSacrificeTotal
      $scope.readyToSubmitList[readyToSubmitListIndex].employeeData[index10].employerContributionTotal =
        employerContributionTotal
    })
    $scope.readyToSubmitList[readyToSubmitListIndex].superGuaranteeKingTotal = superGuaranteeKingTotal
    $scope.readyToSubmitList[readyToSubmitListIndex].superSacrificeKingTotal = superSacrificeKingTotal
    $scope.readyToSubmitList[readyToSubmitListIndex].employerContributionKingTotal = employerContributionKingTotal
  }

  // control ready to submit list
  $scope.onChangeEmployeeCheckbox = (parentIndex, index, checkboxStatus) => {
    $scope.readyToSubmitList[parentIndex].employeeData[index].include = !checkboxStatus

    // 1. 아래 모든 payslip에 false 를 만들어준다.
    // 2. total price 를 다시 계산 해준다.

    // 1
    $scope.readyToSubmitList[parentIndex].employeeData[index].payslips.map((eachPayslip, index9) => {
      $scope.readyToSubmitList[parentIndex].employeeData[index].payslips[index9].include = !checkboxStatus
    })

    // 2
    sumSuperData(parentIndex)
  }

  $scope.onChangePayslipCheckbox = (parentParentIndex, parentIndex, index, checkboxStatus) => {
    $scope.readyToSubmitList[parentParentIndex].employeeData[parentIndex].payslips[index].include = !checkboxStatus

    // payslip이 하나라고 include 되어 있으면 부모데이터에도 include 해준다.
    if (!checkboxStatus) {
      $scope.readyToSubmitList[parentParentIndex].employeeData[parentIndex].include = true
    } else {
      // 히니도 include가 없으면 부모에도 false 로 해준다.
      var atLeastOneInclude = false
      $scope.readyToSubmitList[parentParentIndex].employeeData[parentIndex].payslips.map((eachPayslip) => {
        if (eachPayslip.include) {
          atLeastOneInclude = true
        }
      })
      if (!atLeastOneInclude) {
        $scope.readyToSubmitList[parentParentIndex].employeeData[parentIndex].include = false
      }
    }

    // 1. 아래 모든 payslip에 false 를 만들어준다.
    // 2. total price 를 다시 계산 해준다.
    sumSuperData(parentParentIndex)
  }

  $scope.connectSuperStream = updateParticipant

  $scope.toggle = (index) => {
    $scope.nThToggle = $scope.nThToggle == index ? null : index
    $scope.nThEmployeeToggle = null
  }

  $scope.employeeToggle = (index) => {
    $scope.nThEmployeeToggle = $scope.nThEmployeeToggle == index ? null : index
  }

  $scope.toggle_submitted = (index) => {
    $scope.nThToggle_submitted = $scope.nThToggle_submitted == index ? null : index
  }

  $scope.getName = function (firstName, lastName) {
    return firstName + ' ' + (lastName ? lastName.charAt(0).toUpperCase() + '.' : '')
  }

  $scope.periodChanged = (frequency) => {
    if (frequency != 'custom') {
      $scope.showLoading = true
      const submitList = getSubmitList({
        frequency: frequency,
        superStreamBatches: $scope.superStreamBatches,
        payRuns: $rootScope.payRuns,
        employees: $rootScope.employees,
        directDebitMethods: directDebitMethods,
        defaultDirectDebitAuthorityId: $rootScope.company?.superStream.defaultDirectDebitAuthorityId
      })
      $scope.$evalAsync(() => {
        $scope.readyToSubmitList = angular.copy(submitList)
        $timeout(() => {
          $scope.showLoading = false
        }, 2000)
      })
      CompanyAction.update({ 'superStream.frequency': frequency })
    } else {
      $scope.showLoading = true
      $scope.createCustomBatch1()
    }
    $scope.frequency = frequency
    $scope.nThToggle = null
    $scope.nThEmployeeToggle = null
  }

  $scope.ignoreDateOnChange = function (ev, picker) {
    // $timeout(()=>{
    // test1 = $scope.ignoreBeforeDate;
    // var ignoreBeforeDate = angular.copy(new Date($scope.ignoreBeforeDate));
    // resetReadyToSubmitList($scope.frequency, ignoreBeforeDate);
    // CompanyAction.update({"superStream.ignoreBeforeDate": ignoreBeforeDate});
    // },500);
  }

  $scope.ignoreBeforeHelp = () => {
    Service.infoPopup({
      title: 'Ignore Before',
      contents: 'Pay runs with a payment date prior to this date will be ignored.'
    })
  }

  $scope.paymentPeriodHelp = () => {
    Service.infoPopup({
      title: 'Super Payment Period',
      contents: 'Any submissions made in one super payment period will not translate to other super payment periods.'
    })
  }

  $scope.openEditEmployeeModal = function (employee, currentTab) {
    employeeModalService.edit(employee, currentTab, () => {
      // employee
      const submitList = getSubmitList({
        frequency: $scope.frequency,
        superStreamBatches: $scope.superStreamBatches,
        payRuns: $rootScope.payRuns,
        employees: $rootScope.employees,
        directDebitMethods: directDebitMethods,
        defaultDirectDebitAuthorityId: $rootScope.company?.superStream.defaultDirectDebitAuthorityId
      })
      $scope.$evalAsync(() => {
        $scope.readyToSubmitList = angular.copy(submitList)
        $timeout(() => {
          $scope.showLoading = false
        }, 2000)
      })
    })
  }

  var getBankDetails = (account) => {
    if (account.bsb && account.accountNumber) {
      return (
        account.bsb.substring(0, 3) +
        '-' +
        account.bsb.substring(3, 6) +
        ' ' +
        account.accountNumber +
        ' | ' +
        account.accountName
      )
    } else {
      return 'BPAY'
    }
  }

  $scope.getBankDetails = getBankDetails

  $scope.openPaymentMethodModal = function (paymentMethodId, index) {
    var savePaymentMethod = (selectedMethod) => {
      // change payment in readyToSubmitList
      if (selectedMethod === 'bpay') {
        $scope.readyToSubmitList[index].paymentMethod = { id: 'bpay' }
      } else {
        const index2 = Service.findIndexByKeyValue(directDebitMethods, 'id', selectedMethod)
        $scope.readyToSubmitList[index].paymentMethod = directDebitMethods[index2]
      }
    }
    var hasAlreadyTransactedBpay = $scope.hasAlreadyTransactedBpay

    Service.modal('superPaymentMethodModal.html', [
      '$scope',
      '$uibModalInstance',
      function ($scope, $uibModalInstance) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss()
        }
        const shouldShowDirectDebit =
          ($rootScope.company && $rootScope.company.superStream.bypassDDRestrictions) ||
          (!isSubscriptionTrial && (hasAlreadyTransactedBpay || !hasSmsf))

        $scope.shouldShowDirectDebit = shouldShowDirectDebit
        $scope.selectedMethod = shouldShowDirectDebit ? paymentMethodId : 'bpay'
        $scope.directDebitMethods = directDebitMethods
        $scope.getBankDetails = getBankDetails
        $scope.radioChange = (value) => {
          $scope.selectedMethod = value
        }
        // to fix radio button issue.
        $scope.updateDefault = async (val) => {
          await Http2({
            method: 'post',
            api: '/v1/super/set-default',
            data: {
              paymentId: val
            }
          })

          location.reload()
        }

        $scope.savePaymentMethod = (selectedMethod) => {
          savePaymentMethod(selectedMethod)
          $scope.cancel()
        }
        $scope.updateParticipant = updateParticipant
      }
    ])
  }

  $scope.ignoreListExists = {}
  var checkIfIgnoreListIsEmpty = () => {
    var ignoreListExists_q = false
    var ignoreListExists_m = false
    var ignoreListExists_e = false
    $scope.superStreamBatches.map((eachBatch) => {
      if (eachBatch.status1 === 'ignore') {
        if (eachBatch.frequency === 'quarter') {
          ignoreListExists_q = true
        } else if (eachBatch.frequency === 'month') {
          ignoreListExists_m = true
        } else {
          ignoreListExists_e = true
        }
      }
    })
    $scope.ignoreListExists = {
      quarter: ignoreListExists_q,
      month: ignoreListExists_m,
      everyPayRun: ignoreListExists_e
    }
  }

  $scope.ignore = (superBatch, index) => {
    $scope.loading = true
    Http(
      {
        method: 'post',
        api: '/v1/super/ignore-batch',
        data: {
          year: superBatch.year,
          frequency: superBatch.frequency,
          paymentDate: superBatch.paymentDate,
          segment: superBatch.segment,
          payStartDate: superBatch.payStartDate,
          payRunId: superBatch.payRuns?.[0]?._id
        }
        // disableErrorMsg: true
      },
      (res) => {
        $scope.superStreamBatches.push(res.superStreamBatch)
        checkIfIgnoreListIsEmpty()
        $scope.loading = false
        // 해당 item을 삭제한다.
        $scope.readyToSubmitList.splice(index, 1)
        // resetReadyToSubmitList($scope.frequency);
      },
      (err) => {
        $scope.loading = false
        // if (err.code == "companyExists"){
        // $rootScope.company = err.newCompany;
        // Service.alert(null, "You've already connected to Beam.", "error");
        // } else {
        // Service.alert();
        // }
      }
    )
  }

  $scope.unignore = (superBatchId) => {
    $scope.loading = true
    Http(
      {
        method: 'post',
        api: '/v1/super/unignore-batch',
        data: {
          id: superBatchId
        }
        // disableErrorMsg: true
      },
      (res) => {
        const index = Service.findIndexByKeyValue($scope.superStreamBatches, '_id', superBatchId)
        $scope.superStreamBatches.splice(index, 1)
        checkIfIgnoreListIsEmpty()
        $scope.loading = false
        $scope.readyToSubmitList = getSubmitList({
          frequency: $scope.frequency,
          superStreamBatches: $scope.superStreamBatches,
          payRuns: $rootScope.payRuns,
          employees: $rootScope.employees,
          directDebitMethods: directDebitMethods,
          defaultDirectDebitAuthorityId: $rootScope.company?.superStream.defaultDirectDebitAuthorityId
        })
      },
      (err) => {
        $scope.loading = false
      }
    )
  }

  $scope.createCustomBatch1 = () => {
    $scope.showLoading = true

    function hideLoading() {
      $scope.readyToSubmitList = []
      $timeout(() => {
        $scope.showLoading = false
      }, 1000)
    }

    Service.modal(
      'createCustomContributionBatchModal.html',
      [
        '$scope',
        '$uibModalInstance',
        function ($scope, $uibModalInstance) {
          $scope.cancel = function () {
            hideLoading()
            $uibModalInstance.dismiss()
          }
          $scope.customBatchDate = { startDate: null, endDate: null }

          $scope.save = () => {
            if ($scope.customBatchDate.startDate) {
              // let start = $scope.customBatchDate.startDate
              // let end = $scope.customBatchDate.endDate
              let startStr = $scope.customBatchDate.startDate.format('YYYY-MM-DD')
              let endStr = $scope.customBatchDate.endDate.format('YYYY-MM-DD')
              $scope.customBatchDate = {
                startDate: moment.utc(startStr),
                endDate: moment.utc(endStr)
              }
              $uibModalInstance.dismiss()
              const submitList = getSubmitList({
                frequency: 'custom',
                startDate: $scope.customBatchDate.startDate,
                endDate: $scope.customBatchDate.endDate,
                superStreamBatches: $controllerScope.superStreamBatches,
                payRuns: $rootScope.payRuns,
                employees: $rootScope.employees,
                directDebitMethods: directDebitMethods,
                defaultDirectDebitAuthorityId: $rootScope.company?.superStream.defaultDirectDebitAuthorityId
              })
              $controllerScope.$evalAsync(() => {
                $controllerScope.readyToSubmitList = angular.copy(submitList)
                $timeout(() => {
                  $controllerScope.showLoading = false
                }, 2000)
              })
            }
          }
        }
      ],
      { size: 'sm' }
    )
  }

  function openNoticeModal(title, contents) {
    Service.modal('noticeModal.html', [
      '$scope',
      '$uibModalInstance',
      function ($scope, $uibModalInstance) {
        $scope.cancel = function () {
          $uibModalInstance.dismiss()
        }
        $scope.title = title
        $scope.contents = contents
      }
    ])
  }

  var reformContributionData = (contributionData) => {
    var newContributionData = angular.copy(contributionData)
    var dummyEmployeeData = []
    newContributionData.employeeData = newContributionData.employeeData.map((eachEmployeeData, index) => {
      if (eachEmployeeData.include) {
        var dummyPayslips = []
        var superGuaranteeTotal = 0
        var superSacrificeTotal = 0
        var employerContributionTotal = 0
        eachEmployeeData.payslips.map((eachPayslip) => {
          if (eachPayslip.include) {
            dummyPayslips.push(eachPayslip)
            superGuaranteeTotal += eachPayslip.superGuarantee
            superSacrificeTotal += eachPayslip.superSacrifice
            employerContributionTotal += eachPayslip.employerContribution
          }
        })
        eachEmployeeData.payslips = dummyPayslips
        eachEmployeeData.superGuaranteeTotal = superGuaranteeTotal
        eachEmployeeData.superSacrificeTotal = superSacrificeTotal
        eachEmployeeData.employerContributionTotal = employerContributionTotal

        dummyEmployeeData.push(eachEmployeeData)
      }
    })
    newContributionData.employeeData = dummyEmployeeData
    return newContributionData
  }

  $scope.validateContribution = (contributionData) => {
    Analytics.logEvent('SuperStream Submission Started', {
      provider: 'Beam'
    })

    validateContribution(
      contributionData,
      () => {
        // reform contributionData
        var reformedContributionData = reformContributionData(contributionData)

        // valid
        $scope.loading = true
        Http(
          {
            method: 'post',
            api: '/v1/super/validate-contribution',
            data: { contributionData: reformedContributionData }
          },
          (res) => {
            $scope.loading = false
            // validation fail
            var companyValidationError = []
            var employeeValidationError = []
            // 1. rearrange errors

            var insertEmployeeError = (errorObj) => {
              var idExists = false
              employeeValidationError.map((employeeData, index7) => {
                // 1. 해당 employee array가 존재한다.
                // 2. 존재 하지 않으면 새롭게 만들어 준다.
                // 3. 존재하면 있는 array 에 추가
                if (errorObj.employeeId == employeeData._id) {
                  employeeValidationError[index7].errors.push({
                    message: errorObj.message,
                    severity: errorObj.severity
                  })
                  idExists = true
                }
              })

              if (!idExists) {
                var index8 = Service.findIndexByKeyValue($rootScope.employees, '_id', errorObj.employeeId)
                var employee = $rootScope.employees[index8]
                if (index8 != null) {
                  employeeValidationError.push({
                    _id: errorObj.employeeId,
                    name: employee.f + ' ' + employee.l,
                    errors: [
                      {
                        message: errorObj.message,
                        severity: errorObj.severity
                      }
                    ]
                  })
                }
              }
            }

            res.validations.map((eachError, index6) => {
              // if(eachError.severity === "Error"){
              // <<cases>>
              // 1. less than 2 context array
              // 2. equal 2 arrays : 두번째 array에 포함된 employeeID 마다 에러 메세지를 넣어준다.
              // 3. 3 arrays : 3 array 에 있는 employeeID에 에러메세지를 넣어준다.

              var errorIdArray = eachError.context.split('|')
              if (errorIdArray.length < 2) {
                // 1.
                companyValidationError.push(eachError.message)
              } else if (errorIdArray.length == 2) {
                // 2.
                // 어느 employee 인지 찾는다.
                var errorEmployeeIdArray = errorIdArray[1].split(':')
                errorEmployeeIdArray.map((eachEmployeeId, index7) => {
                  if (eachEmployeeId) {
                    insertEmployeeError({
                      employeeId: eachEmployeeId,
                      message: eachError.message,
                      severity: eachError.severity
                    })
                  }
                })
              } else if (errorIdArray.length == 3) {
                insertEmployeeError({
                  employeeId: errorIdArray[2],
                  message: eachError.message,
                  severity: eachError.severity
                })
              }
            })

            var openEditEmployeeModal = $scope.openEditEmployeeModal
            var connectSuperStream = $scope.connectSuperStream
            // var pushSuperStreamBatch = (superStreamBatch) => {
            // $scope.superStreamBatches.push(superStreamBatch);
            // resetReadyToSubmitList($scope.frequency);
            // };
            Service.modal(
              'superValidationModal.html',
              [
                '$scope',
                '$uibModalInstance',
                function ($scope, $uibModalInstance) {
                  $scope.cancel = function () {
                    $uibModalInstance.dismiss()
                  }
                  $scope.companyValidationError = companyValidationError
                  $scope.employeeValidationError = employeeValidationError
                  $scope.hasErrors = res.hasErrors
                  $scope.numOfErrors = res.validations && res.validations.length > 0 ? res.validations.length : 0
                  $scope.editEmployee = (employeeId) => {
                    $scope.cancel()
                    const index3 = Service.findIndexByKeyValue($rootScope.employees, '_id', employeeId)
                    openEditEmployeeModal($rootScope.employees[index3])
                  }
                  $scope.openSuperSettingModal = () => {
                    $scope.cancel()
                    openSuperSettingModal()
                  }
                  $scope.connectSuperStream = connectSuperStream

                  $scope.submitContribution = () => {
                    $scope.loading = true
                    Http(
                      {
                        method: 'post',
                        api: '/v1/super/submit-contribution',
                        data: { contributionData: reformedContributionData }
                        // disableErrorMsg: true
                      },
                      (res) => {
                        $scope.loading = false
                        // submission 성공
                        // pushSuperStreamBatch(res.superStreamBatch);
                        init()
                        $scope.cancel()

                        var contents
                        if (contributionData.paymentMethod.id === 'bpay') {
                          contents =
                            'Your super contribution has been submitted successfully. Please make your payment via BPAY within 10 business days. If payment has not been received, this lodgement will be cancelled. Any payments lodged past this date will be refunded to the refund bank account in the Superannuation tab of the Settings section.<br><br>'
                          contents += 'Biller code : ' + res.payments[0].billerCode + '<br>'
                          contents += 'Reference : ' + res.payments[0].customerReferenceNumber
                        } else {
                          contents =
                            "Your super contribution has been submitted successfully. The money will be withdrawn and transferred to your employee's nominated super fund within one business day."
                        }

                        openNoticeModal('Successfully Submitted', contents)
                        Analytics.logEvent('SuperStream Submission Success', {
                          provider: 'Beam'
                        })
                      },
                      (err) => {
                        if (err.code === 'MISSING_BPAY_SMSF') {
                          init()
                          $scope.cancel()
                        }
                        $scope.loading = false
                      }
                    )
                  }
                }
              ],
              { size: 'lg' }
            )
          },
          (err) => {
            $scope.loading = false
          }
        )
      },
      (errorObj) => {
        // invalid
        // open error popup
        var openEditEmployeeModal = $scope.openEditEmployeeModal
        Service.modal(
          'superValidationModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }
              $scope.companyValidationError = errorObj.companyValidationError
              $scope.employeeValidationError = errorObj.employeeValidationError
              $scope.hasErrors = true
              $scope.numOfErrors = 0

              $scope.openSuperSettingModal = () => {
                $scope.cancel()
                openSuperSettingModal()
              }

              $scope.editEmployee = (employeeId) => {
                $scope.cancel()
                const index3 = Service.findIndexByKeyValue($rootScope.employees, '_id', employeeId)
                openEditEmployeeModal($rootScope.employees[index3])
              }
            }
          ],
          { size: 'xxl' }
        )
      },
      {}
    )
  }
  $scope.selectDate = () => {
    DatepickerService($scope.ignoreBeforeDate, (selectedDate) => {
      const parsedSelectedDate = moment(selectedDate).utcOffset(0, true).format()
      $scope.showLoading = true
      $scope.ignoreBeforeDate = parsedSelectedDate

      const submitList = getSubmitList({
        frequency: $scope.frequency,
        ignoreBeforeDate: parsedSelectedDate,
        superStreamBatches: $scope.superStreamBatches,
        payRuns: $rootScope.payRuns,
        employees: $rootScope.employees,
        directDebitMethods: directDebitMethods,
        defaultDirectDebitAuthorityId: $rootScope.company?.superStream.defaultDirectDebitAuthorityId
      })
      $scope.$evalAsync(() => {
        $scope.readyToSubmitList = angular.copy(submitList)
        $timeout(() => {
          $scope.showLoading = false
        }, 2000)
      })

      CompanyAction.update({ 'superStream.ignoreBeforeDate': parsedSelectedDate })
    })
  }

  var validateContribution = (contributionData, successCallback, errorCallback) => {
    var companyValidationError = []
    var employeeValidationError = []
    // eslint-disable-next-line
    var textRegex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(\[\]\?\/\;\:\'\"\,+=._-\s]+$/i

    if (
      !$rootScope.company?.superStream ||
      !$rootScope.company?.superStream.refundDetails ||
      !$rootScope.company?.superStream.refundDetails.bsb ||
      !$rootScope.company?.superStream.refundDetails.accountNumber ||
      !$rootScope.company?.superStream.refundDetails.accountName
    ) {
      companyValidationError.push('Refund bank details are required.')
    }

    contributionData.employeeData.map((eachEmployee) => {
      // employee valid check : employmentStartDate, familyName, givenName, dob, usi
      if (eachEmployee.include) {
        var errorArray = []
        var employee = eachEmployee.employeeDetails
        if (!employee.f) {
          errorArray.push({ message: 'First name is required.', severity: 'Error' })
        }
        if (employee.f && !textRegex.test(employee.f)) {
          errorArray.push({
            message: 'First name is invalid. Some special characters are not allowed.',
            severity: 'Error'
          })
        }
        if (!employee.l) {
          errorArray.push({ message: 'Last name is required.', severity: 'Error' })
        }
        if (employee.l && !textRegex.test(employee.l)) {
          errorArray.push({
            message: 'Last name is invalid. Some special characters are not allowed.',
            severity: 'Error'
          })
        }
        if (!employee.sd) {
          errorArray.push({ message: 'Start date is required.', severity: 'Error' })
        }
        if (!employee.dob) {
          errorArray.push({ message: 'Date of Birth is required.', severity: 'Error' })
        }
        if (
          !employee.su ||
          (employee.su.spaceshipType !== 'SPX' &&
            employee.su.spaceshipType !== 'SPI' &&
            employee.su.ty !== 'r' &&
            employee.su.ty !== 's')
        ) {
          errorArray.push({ message: 'Superannuation fund is required.', severity: 'Error' })
        } else if (
          ((employee.su.ty === 'r' || employee.su.spaceshipType === 'SPX' || employee.su.spaceshipType === 'SPI') &&
            ((!employee.su.usi && !employee.su.abn) || !employee.su.no)) ||
          (employee.su.ty === 's' && (!employee.su.sm || !employee.su.sm.abn || !employee.su.sm.n))
        ) {
          errorArray.push({ message: 'Superannuation fund is required.', severity: 'Error' })
        }

        if (errorArray.length > 0) {
          employeeValidationError.push({
            _id: employee._id,
            name: employee.f + ' ' + employee.l,
            errors: errorArray
          })
        }
      }
    })

    if (employeeValidationError.length > 0) {
      errorCallback({
        companyValidationError: companyValidationError,
        employeeValidationError: employeeValidationError
      })
    } else {
      successCallback()
    }
  }

  $scope.copyIdToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        Service.alert('Copied', 'Copying to clipboard was successful!', 'success')
      },
      function (err) {}
    )
  }

  $scope.cancelSubmission = (processId) => {
    $scope.loading = true
    Http(
      {
        method: 'post',
        api: '/v1/super/cancel-submission',
        data: { processId: processId }
      },
      (res) => {
        $scope.loading = false
        if (res.success) {
          const index = Service.findIndexByKeyValue($scope.superStreamBatches, '_id', processId)
          $scope.superStreamBatches[index].s2 = res.superStreamBatch
          Service.alert('Success', 'Your submission has been cancelled.', 'error')
          init()
        } else {
          Service.alert(
            null,
            'You cannot cancel your submission as the direct debit request has already been sent to the bank. It can only be cancelled before it has been sent.',
            'error'
          )
        }
      },
      (err) => {
        $scope.loading = false
      }
    )
  }

  $scope.monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  // for the "views/business/superStream/superReadyToSubmit.html" modal
  if (isModal) {
    $scope.cancel = function () {
      $uibModalInstance?.dismiss()
      showGoogleReview()
    }
    $scope.isModal = true
  }
}
