import React from 'react'
import moment from 'moment'
import { Space } from '../../../../Space'
import { Badge } from '../comps/Badge'
import { CheckItem } from '../comps/CheckItem'
import { usePaywall } from '../usePaywall'
import * as s from './styles'
import { logEvent } from '../../../../../../modules/analytics'
import { Button } from '../../../../../components/Button'
import { availableFeatures, LINKS, PERIOD_NAMES } from '../../../../../constant'
import { to$ } from '../../../../../utils'
import { useModal } from '../../../../../hooks'
import { useSelector } from 'react-redux'
import { useFeature } from '@growthbook/growthbook-react'

export function AvailablePlans() {
  const { setStep, isTrialPlan, period } = usePaywall()
  const { modals, setModals } = useModal()
  const user = useSelector((state) => state.app.user)

  // AB tests from Growthbook (paywall-texts)
  const feature = useFeature('paywall-texts')
  const { gbFeatures } = useSelector((state) => ({
    gbFeatures: state.app.gbFeatures
  }))
  const { delayed_tokenisation: delayedTokenisation, paywall_no_lite } = gbFeatures
  const { lite } = paywall_no_lite || {}

  const testPrice = feature.value?.[period]?.price
  const testPriceInfo = feature.value?.[period]?.priceInfo
  const testDescription = feature.value?.[period]?.description

  return (
    <div className={s.wrap}>
      <div className={s.cards}>
        {!!lite && (
          <Card
            cardName="Lite"
            priceText="Free"
            priceInfo=""
            description={
              <>
                Your current plan.{' '}
                <span
                  className={s.link}
                  onClick={() => {
                    setModals([
                      ...modals,
                      { kind: 'QRCode', options: { data: { link: LINKS.APP_DOWNLOAD.url + user._id } } }
                    ])
                    logEvent(LINKS.APP_DOWNLOAD.eventName)
                  }}
                >
                  Click here for QR code
                </span>{' '}
                to download the app to your mobile device.
                <Space className="h-[8px]" />
                To experience the full power of Payroller on web and on mobile, select “Subscribe to Standard”.
              </>
            }
            buttonText="Subscribe to standard"
            buttonOnClick={() => {
              setStep(1)
              logEvent(
                window.localStorage.pr_agent_id
                  ? `Agent Upgrades Account - Select Lite & Continue with Standard`
                  : 'Company Upgrades Account - Select Lite & Continue with Standard'
              )
            }}
          />
        )}
        <Card
          active
          cardName="Standard"
          priceText={testPrice}
          priceInfo={testPriceInfo}
          description={testDescription && <span dangerouslySetInnerHTML={{ __html: testDescription }} />}
          currency="$"
          asterisk
          badge={isTrialPlan}
          buttonOnClick={() => {
            if (isTrialPlan) logEvent('Clicked Start free Trial')
            setStep(1)
            if (delayedTokenisation) {
              logEvent('Click Subscribe Now on paywall (test group)')
            } else {
              logEvent(
                window.localStorage.pr_agent_id
                  ? `Agent Upgrades Account - Select Standard & Continue`
                  : 'Company Upgrades Account - Select Standard & Continue'
              )
            }
          }}
          buttonText={isTrialPlan ? 'START FREE TRIAL' : 'SUBSCRIBE NOW'}
        />
        <Card
          cardName="Enterprise"
          priceText="Custom price"
          priceInfo=""
          description={<>For tax agents with 10 or more clients or businesses with 20 or more employees.</>}
          buttonText="Contact us"
          buttonOnClick={() => {
            window.Intercom('show')

            logEvent(
              window.localStorage.pr_agent_id
                ? `Agent Upgrades Account - Select Enterprise & Contact us`
                : 'Company Upgrades Account - Select Enterprise & Contact us'
            )
          }}
        />
      </div>
    </div>
  )
}

function Card({
  active = false,
  cardName = 'Standard',
  currency = '',
  priceText,
  asterisk = false,
  priceInfo = '/month per employee',
  description,
  buttonText = 'START FREE Trial',
  buttonOnClick,
  badge = false
}) {
  const { prices, currentPrice, period, discount, plan } = usePaywall()
  const trialLimit = plan?.pricingPlan?.trialLimit?.[0] || 14
  const price = priceText || (currentPrice / 100).toFixed(2) || 0
  const momentDiscountEnd = discount ? moment(discount.end) : undefined
  const monthDiff = discount ? momentDiscountEnd.diff(moment(), 'month') : undefined
  const isNoDiscount = !discount

  return (
    <div className={s.card(active)}>
      <div>
        <label className={s.title}>{cardName}</label>
        <Space className="h-2 justify-between" />

        <div className={s.priceWrap}>
          <div className="flex">
            <span>{currency}</span>
            <div className={s.price}>{price}</div>
            {asterisk && <span>*</span>}
          </div>
          <span className={s.priceInfo}>{priceInfo}</span>
        </div>

        <Space className="h-[22px]" />

        <p className={s.description}>
          {description || (
            <>
              {isNoDiscount ? (
                <>
                  *Minimum spend {to$(prices?.normal.minCost)}/month billed {PERIOD_NAMES[period].toLowerCase()}.{' '}
                  <Space className="h-[8px]" />
                  <a
                    className={s.link}
                    href={LINKS.TERMS_CONDITIONS.url}
                    target="_blank"
                    onClick={() => logEvent(LINKS.TERMS_CONDITIONS.eventName)}
                  >
                    Terms & Conditions
                  </a>{' '}
                  apply.
                </>
              ) : (
                <>
                  *Initial {monthDiff > 1 ? '3' : '1'}-month discount, then {to$(prices?.normal.unitPrice)}/month per
                  employee thereafter.
                  {/* Paragraph Spacing 8px */}
                  <Space className="h-[8px]" />
                  Minimum spend {to$(prices?.normal.minCost)}/month after the discount period, billed{' '}
                  {PERIOD_NAMES[period].toLowerCase()}.
                  <Space className="h-[8px]" />
                  <a
                    className={s.link}
                    href={LINKS.TERMS_CONDITIONS.url}
                    target="_blank"
                    onClick={() => logEvent(LINKS.TERMS_CONDITIONS.eventName)}
                  >
                    Terms & Conditions
                  </a>{' '}
                  apply.
                </>
              )}
            </>
          )}
        </p>
      </div>

      <Space className="h-6" />

      <div>
        <Button kind={active ? 'active' : 'inactive'} onClick={buttonOnClick}>
          {buttonText}
        </Button>

        <Space className="h-7" />

        <div className={s.itemContainer}>
          {availableFeatures[cardName].map((feature, id) => (
            <CheckItem key={id} feature={feature} />
          ))}
        </div>
      </div>

      {badge && <Badge content={trialLimit + '-day free trial'} />}
    </div>
  )
}
