angular
  .module('starter.business.controllers')
  .factory('creditCardService', function ($q, Service, $http, API_ENDPOINT) {
    function setDefaultCard(cardId) {
      var deferred = $q.defer()
      // expiry check
      $http
        .post(API_ENDPOINT.url + '/v1/set-defaultcard', { cardId: cardId })
        .success(function (stripeCustomer) {
          deferred.resolve(stripeCustomer)
        })
        .error(function (data, status) {
          if (status == 409) {
            Service.alert('Alert', data.message)
            UserAuthFactory.logout()
          } else if (status == 500) {
            Service.alert('Alert', data.message)
          } else {
            Service.alert()
          }
        })
      return deferred.promise
    }

    function deleteCard(cardId) {
      var deferred = $q.defer()

      // expiry check
      $http
        .delete(API_ENDPOINT.url + '/v1/creditCard/' + cardId)
        .success(function (stripeCustomer) {
          deferred.resolve(stripeCustomer)
        })
        .error(function (data, status) {
          if (status == 409) {
            Service.alert('Alert', data.message)
            UserAuthFactory.logout()
          } else if (status == 500) {
            Service.alert('Alert', data.message)
          } else {
            Service.alert()
          }
        })
      return deferred.promise
    }

    return {
      setDefaultCard: setDefaultCard,
      deleteCard: deleteCard
    }
  })
