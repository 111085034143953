angular.module('starter.services').factory('LeaveBalancePdfService', function ($filter, $q, Service, $rootScope) {
  return function (data, opt) {
    var deferred = $q.defer()

    const csvItems = []
    var items = []
    var stripeColor = '#deedf7'

    csvItems.push([$rootScope.company.c + ' As at ' + $filter('date')(new Date(data.asAtDate), 'd MMM yyyy')])
    csvItems.push(['ABN: ' + $rootScope.company.abn])
    csvItems.push([])

    csvItems.push(['Employee', 'Leave Type', 'Hours', 'Value'])
    items.push([
      {
        text: 'Employee',
        style: 'tableHeader',
        bold: true,
        border: [false, false, false, true],
        fillColor: stripeColor
      },
      {
        text: 'Leave Type',
        style: 'tableHeader',
        bold: true,
        border: [false, false, false, true],
        fillColor: stripeColor
      },
      {
        text: 'Hours',
        style: 'tableHeader',
        bold: true,
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: stripeColor
      },
      {
        text: 'Value',
        style: 'tableHeader',
        bold: true,
        alignment: 'right',
        border: [false, false, false, true],
        fillColor: stripeColor
      }
    ])

    var annualLeaveSubtotal = 0
    var longServiceLeaveSubtotal = 0
    var sickLeaveSubtotal = 0

    for (let index = 0; index < data.leaveArray.length; index++) {
      const leave = data.leaveArray[index]

      if (leave.employeeStatus !== 'd') {
        if (data.leaveType === 'all' || data.leaveType === 'annual') {
          csvItems.push([
            Service.sanitizeObj(leave.f + ' ' + leave.l),
            'Annual Leave',
            $filter('number')(leave.annualLeaveRemaining, 4),
            $filter('currency')(leave.annualLeaveValue)
          ])
          items.push([
            {
              text: Service.sanitizeObj(leave.f + ' ' + leave.l),
              style: ['table'],
              border: [false, false, false, false]
            },
            { text: 'Annual Leave', style: ['table'], border: [false, false, false, false] },
            {
              text: $filter('number')(leave.annualLeaveRemaining, 4),
              alignment: 'right',
              style: ['table'],
              border: [false, false, false, false]
            },
            {
              text: $filter('currency')(leave.annualLeaveValue),
              alignment: 'right',
              style: ['table'],
              border: [false, false, false, false]
            }
          ])
          annualLeaveSubtotal += leave.annualLeaveValue
        }

        if (
          $rootScope.company.le &&
          $rootScope.company.le.lsl &&
          (data.leaveType === 'all' || data.leaveType === 'longService')
        ) {
          csvItems.push([
            data.leaveType === 'longService' ? Service.sanitizeObj(leave.f + ' ' + leave.l) : '',
            'Long Service Leave',
            $filter('number')(leave.longServiceLeaveRemaining, 4),
            $filter('currency')(leave.longServiceLeaveValue)
          ])
          items.push([
            {
              text: data.leaveType === 'longService' ? Service.sanitizeObj(leave.f + ' ' + leave.l) : '',
              style: ['table'],
              border: [false, false, false, false]
            },
            { text: 'Long Service Leave', style: ['table'], border: [false, false, false, false] },
            {
              text: $filter('number')(leave.longServiceLeaveRemaining, 4),
              alignment: 'right',
              style: ['table'],
              border: [false, false, false, false]
            },
            {
              text: $filter('currency')(leave.longServiceLeaveValue),
              alignment: 'right',
              style: ['table'],
              border: [false, false, false, false]
            }
          ])

          longServiceLeaveSubtotal += leave.longServiceLeaveValue
        }

        if (data.leaveType === 'all' || data.leaveType === 'sick') {
          csvItems.push([
            data.leaveType === 'sick' ? Service.sanitizeObj(leave.f + ' ' + leave.l) : '',
            "Sick/Carer's Leave",
            $filter('number')(leave.sickLeaveRemaining, 4),
            $filter('currency')(leave.sickLeaveValue)
          ])
          items.push([
            {
              text: data.leaveType === 'sick' ? Service.sanitizeObj(leave.f + ' ' + leave.l) : '',
              style: ['table'],
              border: [false, false, false, data.leaveType !== 'sick']
            },
            { text: "Sick/Carer's Leave", style: ['table'], border: [false, false, false, data.leaveType !== 'sick'] },
            {
              text: $filter('number')(leave.sickLeaveRemaining, 4),
              alignment: 'right',
              style: ['table'],
              border: [false, false, false, data.leaveType !== 'sick']
            },
            {
              text: $filter('currency')(leave.sickLeaveValue),
              alignment: 'right',
              style: ['table'],
              border: [false, false, false, data.leaveType !== 'sick']
            }
          ])

          sickLeaveSubtotal += leave.sickLeaveValue
        }
      }
    }

    if (data.leaveType === 'all') {
      csvItems.push(['Subtotal', 'Annual Leave', '', $filter('currency')(annualLeaveSubtotal)])
      items.push([
        { text: 'Subtotal', style: ['table'], border: [false, true, false, false] },
        { text: 'Annual Leave', style: ['table'], border: [false, true, false, false] },
        { text: '', style: ['table'], border: [false, true, false, false] },
        {
          text: $filter('currency')(annualLeaveSubtotal),
          alignment: 'right',
          style: ['table'],
          border: [false, true, false, false]
        }
      ])

      if ($rootScope.company.le && $rootScope.company.le.lsl) {
        csvItems.push(['', 'Long Service Leave', '', $filter('currency')(longServiceLeaveSubtotal)])
        items.push([
          { text: '', style: ['table'], border: [false, false, false, false] },
          { text: 'Long Service Leave', style: ['table'], border: [false, false, false, false] },
          { text: '', style: ['table'], border: [false, false, false, false] },
          {
            text: $filter('currency')(longServiceLeaveSubtotal),
            alignment: 'right',
            style: ['table'],
            border: [false, false, false, false]
          }
        ])
      }

      csvItems.push(['', "Sick/Carer's Leave", '', $filter('currency')(sickLeaveSubtotal)])
      items.push([
        { text: '', style: ['table'], border: [false, false, false, false] },
        { text: "Sick/Carer's Leave", style: ['table'], border: [false, false, false, false] },
        { text: '', style: ['table'], border: [false, false, false, false] },
        {
          text: $filter('currency')(sickLeaveSubtotal),
          alignment: 'right',
          style: ['table'],
          border: [false, false, false, false]
        }
      ])
    }

    csvItems.push([
      'Total',
      '',
      '',
      $filter('currency')(annualLeaveSubtotal + longServiceLeaveSubtotal + sickLeaveSubtotal)
    ])
    items.push([
      { text: 'Total', style: ['table'], border: [false, true, false, false] },
      { text: '', style: ['table'], border: [false, true, false, false] },
      { text: '', style: ['table'], border: [false, true, false, false] },
      {
        text: $filter('currency')(annualLeaveSubtotal + longServiceLeaveSubtotal + sickLeaveSubtotal),
        alignment: 'right',
        style: ['table'],
        border: [false, true, false, false]
      }
    ])

    var pdf = {
      content: [
        {
          columns: [
            {
              text: [
                // {text: "Report Group : " + data.reportName + "\n", bold: true},
                { text: $rootScope.company.c + '\n\n', bold: true },
                { text: 'ABN: ' + $rootScope.company.abn + '\n\n' },
                { text: 'As at ' + $filter('date')(new Date(data.asAtDate), 'd MMM yyyy'), bold: true }
              ],
              margin: [10, 5, 0, 10]
            },
            {
              alignment: 'right',
              text: [{ text: 'Leave Balances\n', fontSize: 16, bold: true, marginBottom: 10 }],
              margin: [0, 0, 10, 10]
            }
          ]
        },
        '\n',
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', 150, 90, 90],
            body: items
          },
          layout: {
            hLineColor: function (i, node) {
              return '#badbef'
            }
          }
        }
      ],

      styles: {
        tableHeader: {
          bold: true,
          marginTop: 4,
          marginBottom: 2,
          marginLeft: 5,
          marginRight: 5
        },
        table: {
          marginTop: 4,
          marginBottom: 2,
          marginLeft: 5,
          marginRight: 5
        },
        default: {
          marginTop: 3,
          marginBottom: 1,
          marginLeft: 5,
          marginRight: 5
        }
      },
      defaultStyle: {
        columnGap: 30,
        fontSize: 8.5,
        lineHeight: 1.2,
        font: 'dejavu'
      }
    }

    var fontDescriptors = {
      dejavu: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf'
      }
    }
    pdfMake.fonts = fontDescriptors
    pdf = pdfMake.createPdf(pdf)

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvItems.forEach(function (rowArray) {
      let row = rowArray.map((x) => `"${x.toString().replace(/"/g, '""')}"`).join(',')
      csvContent += row + '\r\n'
    })
    const csvData = encodeURI(csvContent)

    pdf.getBase64(function (output) {
      deferred.resolve({ csvData, pdfData: opt === 'base64' ? output : base64ToUint8Array(output) })
    })

    return deferred.promise
  }

  function base64ToUint8Array(base64) {
    var raw = atob(base64)
    var uint8Array = new Uint8Array(raw.length)
    for (var i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i)
    }
    return uint8Array
  }
})
