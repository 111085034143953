angular.module('starter.services').factory('LeaveAction', function ($rootScope, Service) {
  return {
    add: function (newLeave) {
      var leaves_temp = angular.copy($rootScope.leaves || [])
      leaves_temp.push(newLeave)
      // resort
      leaves_temp.sort((obj1, obj2) => (new Date(obj1.sd) < new Date(obj2.sd) ? -1 : 1))
      $rootScope.leaves = leaves_temp
    },
    update: function (newLeave) {
      var leaves_temp = angular.copy($rootScope.leaves)

      for (var index = 0; index < leaves_temp.length; index++) {
        const element = leaves_temp[index]
        if (element._id === newLeave._id) {
          leaves_temp[index] = newLeave
        }
      }

      // resort
      leaves_temp.sort((obj1, obj2) => (new Date(obj1.sd) < new Date(obj2.sd) ? -1 : 1))
      $rootScope.leaves = leaves_temp
    },
    delete: function (leaveId) {
      var index = Service.findIndexByKeyValue($rootScope.leaves, '_id', leaveId)
      $rootScope.leaves.splice(index, 1)
    },
    deleteEmployee: function (employeeId) {
      var leaves_temp = []

      for (var index = 0; index < $rootScope.leaves.length; index++) {
        if ($rootScope.leaves[index].ei !== employeeId) {
          leaves_temp.push($rootScope.leaves[index])
        }
      }

      $rootScope.leaves = leaves_temp
    }
  }
})
