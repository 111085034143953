angular
  .module('starter.agent.controllers')
  .controller(
    'agentBulkRunSelectPayrollCtrl',
    function ($scope, $rootScope, $state, Service, PayrollService, Analytics) {
      // ready to run
      if (!$rootScope.clients || $rootScope.clients[0] === 'no data') {
        $state.go('agent.clients')
        return false
      }

      let bulkPayRunList = []
      $scope.hasErrorInCompany = false

      $rootScope.clients.map((client) => {
        if (
          client._id !== client.ai ||
          !$rootScope.agent.agd ||
          !$rootScope.agent.agd.ma_h ||
          ($rootScope.agent.agd.ma_h === 2 && $rootScope.agentUser._id === $rootScope.agent._id)
        ) {
          let clientCopy = angular.copy(client)

          // prevent from selecting clients if they don't have
          // 1. abn 2. working hours 3. valid subscription 4. at least one active employee (active or pending)
          if (
            !client.abn ||
            client.wh == null ||
            !client.subscriptionStatus.webapp.isValid ||
            client.activeEmployeeNumber < 1
          ) {
            clientCopy.disabled = true
            $scope.hasErrorInCompany = true
          }

          // get next payroll date
          let getDateObj = PayrollService.getNextPayrollDate(client.payRun, client)
          clientCopy.dateObj = getDateObj

          // to run next payrun
          clientCopy.dateObj.payRunId = null

          bulkPayRunList.push(clientCopy)
        }
      })

      $scope.bulkPayRunList = bulkPayRunList
      // make run list
      $scope.selectAll_payRun = (checkedAll) => {
        $scope.bulkPayRunList.map((eachClient, index) => {
          if (!eachClient.disabled) {
            $scope.bulkPayRunList[index].checked = checkedAll
          }
        })
      }

      $scope.runBulkPayroll = () => {
        Analytics.logEvent('Agent Bulk Payroll Started')
        var bulkRunList = []

        var noClients = true
        $scope.bulkPayRunList.map((eachClient, index) => {
          if (eachClient.checked) {
            bulkRunList.push(eachClient)
            noClients = false

            // reset checked status for next bulk run
            $scope.bulkPayRunList[index].checked = false
          }
        })

        if (noClients) {
          Service.alert('Alert', 'You need to add at least one client!', 'error')
        } else {
          $scope.action.runBulkPayroll(bulkRunList)
          $state.go('agent.bulkRun.loading')
        }
      }
      $scope.getTooltip = (payRunClient) => {
        let message = ''
        if (!payRunClient.abn) {
          message = 'Require company setup'
        } else {
          if (!payRunClient.subscriptionStatus.webapp.isValid) {
            message = 'Require a subscription to proceed'
          }
        }
        return message
      }
    }
  )
