import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Button } from '@bookipi/bds'
import { setModals, stateApp } from '../../../../reducers/appSlice'
import { logEvent } from '../../../../modules/analytics'
import { PriceLockInSummaryModal } from '.'

const composeSubscriptionMessage = (period, date, isAgent) => {
  const PRICE_LOCKIN_DETAILS = {
    year: {
      statement1: (
        <span>
          If you choose to opt in today, your subscription end date will be <span className="!font-bold">{date}</span>.
        </span>
      ),
      statement2: (
        <span>We will discount any subscription period that you have already paid for in the coming year.</span>
      )
    },
    month: {
      statement1: (
        <span>
          If you choose to opt in today, your subscription end date will be <span className="!font-bold">{date}</span>.
        </span>
      ),
      statement2: (
        <span className="font-bold leading-5">
          Please note that by opting in you will be converted to an annual subscription. Your subscription will renew as
          an annual subscription at the end of the lock-in period.
        </span>
      ),
      statement3: 'We will discount any subscription period that you have already paid for in the coming year.'
    }
  }

  const AGENT_PRICE_LOCKIN_DETAILS = {
    year: {
      statement1: (
        <span>
          If you choose to opt in today, your subscription end date will be <span className="!font-bold">{date}</span>.
        </span>
      ),
      statement2: (
        <span>We will discount any subscription period that you have already paid for in the coming year.</span>
      )
    },
    month: {
      statement1: (
        <span>
          If you choose to opt in today, your subscription end date will be <span className="!font-bold">{date}</span>.
        </span>
      ),
      statement2: (
        <span className="font-bold leading-5">
          Please note that by opting in, your client will be converted to an annual subscription. Your subscription will
          renew as an annual subscription at the end of the lock-in period.
        </span>
      ),
      statement3: 'We will discount any subscription period that you have already paid for in the coming year.'
    }
  }
  return isAgent ? AGENT_PRICE_LOCKIN_DETAILS[period] : PRICE_LOCKIN_DETAILS[period]
}

const PriceLockInModal = () => {
  const dispatch = useDispatch()
  const [isCheckout, setCheckout] = useState(false)
  const today = dayjs()
  const subscriptionStatus = useSelector((state) => state.app.subscriptionStatus)
  useEffect(() => {
    logEvent('Modal viewed')
  }, [])
  const onClose = () => {
    dispatch(setModals([]))
  }
  const onGetStarted = () => {
    logEvent('Price Lockin Value Prop Confirmed')
    setCheckout(true)
  }
  const subscriptionMessage = composeSubscriptionMessage(
    subscriptionStatus.webapp.period,
    today.add(1, 'year').subtract(1, 'day').format('DD MMM YYYY')
  )
  if (isCheckout) return <PriceLockInSummaryModal />
  return (
    <>
      <div className="w-[545px] h-fit bg-white flex flex-col items-end border rounded-md mt-[40px]">
        <button className="bg-transparent cursor-pointer ml-auto mr-3 mt-3" onClick={onClose}>
          <div className="icon-feather-x fs-24" />
        </button>
        <div className="flex flex-col items-center gap-6 w-full mt-4">
          <div className="font-bold text-2xl font-inter text-black">Price lock-in</div>
          <div className="font-semibold text-xl font-inter">We are pleased to offer you a price lock-in.</div>
        </div>
        <div className="flex flex-col gap-6 my-4 px-10 font-inter text-black">
          <div>{subscriptionMessage.statement1}</div>
          <div>{subscriptionMessage.statement2}</div>
          {subscriptionMessage.statement3 && <div>{subscriptionMessage.statement3}</div>}
        </div>
        <div className="flex gap-4 m-3">
          <Button color="light" onClick={onClose}>
            Close
          </Button>
          <Button onClick={onGetStarted}>Let's start!</Button>
        </div>
      </div>
    </>
  )
}

export { PriceLockInModal }
