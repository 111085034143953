import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'stpTransition',
  initialState: {
    myEmployees: [],
    // debug2018: false,
    // debug2020: false,
    show2018modal: false,
    show2020modal: false,
    showMyCrap: false,
    company: null
  },
  reducers: {
    setMyEmployees: (state, action) => {
      state.myEmployees = _.cloneDeep(action.payload)
    },
    showMyCrap: (state, action) => {
      state.showMyCrap = action.payload
    },
    show2018modal: (state, action) => {
      state.show2018modal = action.payload
    },
    show2020modal: (state, action) => {
      state.show2020modal = action.payload
    },
    setStpTransitionCompany: (state, action) => {
      state.company = _.cloneDeep(action.payload)
    }
    // debug2018: (state, action) => {
    //   state.debug2018 = action.payload
    // },
    // debug2020: (state, action) => {
    //   state.debug2020 = action.payload
    // }
  }
})

export const { setMyEmployees, setStpTransitionCompany } = appSlice.actions
export const stpTransitionSlice = appSlice
export default appSlice.reducer
