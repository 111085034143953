import React from 'react'
import _ from 'lodash'
import { other, reasons } from './consts'
import { useSelector } from 'react-redux'
import { useModal } from '../../../../hooks'
import { useMutation } from '../../../../../api/survey'
import { CircularProgress } from '@material-ui/core'
import { logEvent } from '../../../../../modules/analytics'
import { RemoveSubscriptionView } from '@payroller/pds/client'

export function RemoveSubscription({ data, deleteSubscription }) {
  const { hideModal, setModals } = useModal()
  const hide = () => hideModal('RemoveSubscription')
  const company = useSelector((state) => state.app.company)
  const user = useSelector((state) => state.app.user)
  const agent = useSelector((state) => state.app.agent)
  const { gbFeatures } = useSelector((state) => ({
    gbFeatures: state.app.gbFeatures
  }))
  const { discount_cancellation: discountCancellation } = gbFeatures
  const userEmail = user?.e || agent?.e || company?.e
  const companyId = data.id
  const { isPayingUser } = data
  const [newSurvey, { isLoading }] = useMutation()
  const onSubmit = async (answers) => {
    const [, , , tooExpensive] = reasons
    const { anythingToShare, cancelReason } = answers
    logEvent('Click cancel button', {
      'Cancellation reason': cancelReason,
      ...(anythingToShare && { 'Other reason': anythingToShare })
    })
    if (
      discountCancellation &&
      isPayingUser &&
      (company.priceGroup === 'MAY2024DISCOUNT' || company.priceGroup === 'MAY2024')
    ) {
      setModals([
        {
          kind: 'DiscountOfferModal',
          channel: 'local',
          options: {
            data: {
              data,
              answers,
              deleteSubscription
            },
            overlay: true
          }
        }
      ])
      return
    }

    // survey
    await newSurvey({ answers: { companyId, isPayingUser, userEmail, ...answers } })
    // delete the subscription
    deleteSubscription(data.id)
    hide()
  }

  const viewProps = {
    reasons,
    other,
    isLoading,
    isPayingUser,
    Loader: CircularProgress,
    isV2: data.isV2
  }
  const eventProps = { onSubmit, onClickClose: hide }

  return <RemoveSubscriptionView {...viewProps} {...eventProps} />
}
