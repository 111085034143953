const filterActiveEmployees = (employees) => {
  const activeEmployees =
    employees?.filter(
      (e) =>
        (e.s === 'a' || e.s === 'p') &&
        !e?.rosterOnly &&
        (!window.localStorage.pr_client_id || e.ci === window.localStorage.pr_client_id)
    ).length || 0
  return activeEmployees
}

export { filterActiveEmployees }
