import { useEffect, useRef, useState } from 'react'
import { addInterectionObserver } from './addInterectionObserver'

export function useIntersection(
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
    remainAfterPassed: false
  }
) {
  const ref = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const target = ref.current
    if (target) {
      addInterectionObserver({
        target,
        options,
        onVisible: () => setVisible(true),
        onHide: () => setVisible(false)
      })
    }
  }, [ref])

  return [ref, visible]
}
