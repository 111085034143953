import _ from 'lodash'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Capacitor } from '@capacitor/core'
import { useAlerts, useModal } from '../../hooks'
import { Confetti } from './comps'
import * as I from './Inners'
import { confetti, overlay, positioning, scrollView, fullscreen } from './styles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useEscClose } from './useEscClose'
import { twConfig } from '../../constant'
import useSafeAreaView from '../../hooks/useSafeAreaView'
import { useDimensions } from '@payroller/pds'

const baseZ = Number(twConfig.theme.zIndex.modal)

const Modal = ({ state, channel, ...rest }) => {
  const { modals, hideModal } = useModal()
  usePreventBodyScroll({ modals })

  const inChannel = modals.filter(
    (m) =>
      (channel === 'local' && m.channel === 'local') || // CASE 1. use modal as locally in angular or as imported react component. (e.g. CheckList modals, RemoveSubscription)
      (channel === 'global' && !m.channel) // CASE 2. use modal as globally. (e.g. Paywall, SuperSummaryModalSelect)
  )

  return (
    <>
      {/* overlay doesn't have transition (enter & exit) animations */}
      {inChannel.map(
        (modal, id) => modal.options?.overlay && <div key={id} className={overlay} style={{ zIndex: baseZ + id }} />
      )}
      <TransitionGroup component={null}>
        {inChannel.map((modal, id) => {
          const animateProps = modal.options?.noAnimate ? { timeout: 0 } : { timeout: 360, classNames: 'modal' }

          return (
            <CSSTransition key={modal.kind} {...animateProps}>
              <M modal={modal} state={state} rest={rest} hideModal={hideModal} style={{ zIndex: baseZ + id }} />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </>
  )
}

export const GlobalModal = (props) => <Modal {...props} channel="global" />
export const LocalModal = (props) =>
  ReactDOM.createPortal(<Modal {...props} channel="local" />, document.getElementById('modalLocalChannelPortal'))

function M({ modal, state, rest, hideModal, style }) {
  const options = modal.options || {}
  const data = options.data || {}
  const className = options.className || ''
  const innerClassname = options.innerClassname || ''
  const addSafeArea = _.isNil(options.addSafeArea) ? options.addSafeArea : true
  const kind = modal.kind
  const { setAlert } = useAlerts()
  const safeAreaMargin = useSafeAreaView()
  const isTsrOnly = process.env.APP === 'people'
  const { isMobile, isTablet } = useDimensions()
  const isMobileOrTablet = isMobile || isTablet

  const Inner = I[modal.kind]
  const innerProps = { data, ...state, ...rest } // "rest" are passed props from React parent components.

  const ref = useEscClose({ kind })

  const isNative = Capacitor.isNativePlatform()

  return (
    <div
      id="react-modal"
      className={`${scrollView} ${className}`}
      style={{ ...style }}
      onClick={() => hideModal(kind)}
      ref={(el) => {
        if (addSafeArea) {
          const paddingTop = isNative ? safeAreaMargin.top : 0
          el && el.style.setProperty('padding-top', `${paddingTop}px`, 'important')
        }
      }}
    >
      {options.confetti && <Confetti className={confetti} />}
      {/* tabIndex is needed to enable key listener on div elements of React */}
      <div
        className={`${innerClassname || (isTsrOnly && isNative && isMobileOrTablet ? fullscreen : positioning)}`}
        onClick={(e) => e.stopPropagation()}
        ref={ref}
        tabIndex="0"
      >
        <Inner {...innerProps} hideModal={hideModal} setAlert={setAlert} />
      </div>
    </div>
  )
}

// prevent body scroll when modal appears
function usePreventBodyScroll({ modals }) {
  useEffect(() => {
    if (modals.length > 0) {
      document.body.style.height = '100vh'
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.height = ''
      document.body.style.overflowY = ''
    }
  }, [modals])
}
