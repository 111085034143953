import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './prepareHeaders'

export const stpApi = createApi({
  reducerPath: 'stpApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.API_ENDPOINT, prepareHeaders }),
  endpoints: (build) => ({
    update: build.mutation({
      query: () => ({ url: '/v1/send-stp-info/', method: 'POST' })
    })
  })
})

export const { useMutation } = stpApi.endpoints.update
