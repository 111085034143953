import { useEffect, useRef } from 'react'
import { useModal } from '../../hooks'

export function useEscClose({ kind }) {
  const ref = useRef(null)
  const node = ref.current
  const { modals, hideModal } = useModal()

  function downHandler({ key }) {
    if (key === 'Escape') hideModal(kind)
  }

  // Add event listeners
  useEffect(() => {
    if (node) {
      node.addEventListener('keydown', downHandler)
      // Remove event listeners on cleanup
      return () => {
        node.removeEventListener('keydown', downHandler)
      }
    }
  }, [node, modals]) // update listenser when modals state changes

  return ref
}
