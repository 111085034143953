import React from 'react'
import { container } from '@payroller/pds/client'

import QR from 'react-qr-code'

export function QRCode({ data }) {
  return (
    <div className={container}>
      <QR value={data?.link} />
    </div>
  )
}
