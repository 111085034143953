import { GrowthBook } from '@growthbook/growthbook-react'
import { postData } from '@payroller/pds'

export const growthbook = new GrowthBook({
  enabled: process.env.DISABLE_GROWTHBOOK !== 'yes',
  trackingCallback: (experiment, result) => {
    log.debug({ experiment, result })
    postData({
      url: `${process.env.API_ENDPOINT}/v1/exp`,
      data: {
        experimentId: experiment.key,
        variationId: result.variationId
      }
    }).catch((error) => {
      console.error('growthbook error', error)
    })
  }
})
