import React, { Fragment } from 'react'
import { makeStyles, Dialog } from '@material-ui/core'

import { DivContainerModal } from '../utils'
import { useDimensions } from '@payroller/pds'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    bottom: 32
  },
  root: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      margin: '0px',
      'box-shadow': 'unset'
    }
  },
  content: {
    padding: 0
  }
}))

const AlertConfirm = ({
  open,
  title,
  titleStyle,
  messageStyle = '!mt-4 font-inter text-lg text-inputLabelGray',
  message,
  hasClose = true,
  onClose,
  icon,
  btnContainerStyle = 'flex !flex-row md:!flex-col justify-center !p-6 md:!p-4 border-[#E5E7EB] border-t',
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  onCancel,
  onConfirm,
  buttonArr // Use for customized buttons
}) => {
  const classes = { ...useStyles() }
  const { isMobile } = useDimensions()

  if (!message) return null

  return (
    <Dialog
      {...{
        open,
        onClose
      }}
      classes={{ ...(isMobile && { paper: classes.paper }) }}
      className={`${classes.root} element-wrapper ${classes.paper}`}
      style={{
        zIndex: 105000002
      }}
      container={DivContainerModal}
    >
      <div className="rounded-lg bg-white w-[368px] sm:w-[343px]">
        {hasClose && (
          <button className="close absolute top-4 right-4 text-inputTextGray" type="button" onClick={onClose}>
            <div className="icon-feather-x"></div>
          </button>
        )}
        <div className="flex flex-col items-center justify-center !p-6 md:!p-4 text-center">
          {icon || null}
          {title && <div className={titleStyle}>{title}</div>}
          <div className={messageStyle}>{message}</div>
        </div>

        <div className={btnContainerStyle}>
          {buttonArr ? (
            <Fragment>
              {buttonArr.map((btnData, index) => {
                return (
                  <button type="button" key={index} className={btnData?.className} onClick={btnData?.onClick}>
                    {btnData?.label}
                  </button>
                )
              })}
            </Fragment>
          ) : (
            <Fragment>
              <button
                type="button"
                className="px-4 py-2.5 text-sm font-font-normal text-center text-inputLabelGray rounded-lg cursor-pointer bg-white hover:bg-white hover:opacity-70 border border-inputBorderGray mr-3 md:mb-3 w-full"
                onClick={onCancel}
              >
                {cancelLabel}
              </button>
              <button
                type="button"
                className="px-4 py-2.5 text-sm font-font-normal text-center text-white rounded-lg cursor-pointer !bg-[#C81E1E] hover:opacity-70 w-full"
                onClick={onConfirm}
              >
                {confirmLabel}
              </button>
            </Fragment>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default AlertConfirm
