import { Capacitor } from '@capacitor/core'

export const prepareHeaders = (headers, { getState }) => {
  headers.set('Content-Type', 'application/json')
  headers.set('x-app', window.location.hostname)
  headers.set('x-platform', 'web')
  // Athentication data
  const Authorization = localStorage.pr_token
  const clientId = localStorage.pr_client_id || JSON.parse(localStorage.pr_company)._id
  const xPublic = getXPublicHeader()

  if (Authorization) {
    headers.set('Authorization', Authorization)
  }
  if (clientId) {
    headers.set('x-client-id', clientId)
  }
  if (xPublic) {
    headers.set('x-public', xPublic)
  }

  return headers
}

export const getXPublicHeader = () => {
  const isTsr = process.env.APP === 'people'
  const tsrHeader = Capacitor.isNativePlatform() ? 'TSRApp' : 'TSR'
  return isTsr ? tsrHeader : 'APP'
}
