import { stpTransitionSlice } from '../../reducers/stpTransitionSlice'
import { setDisplayAddSeats, setModalFromOnBoarding, setModalAgentSubscription } from '../../reducers/subscriptionSlice'
import { setModalSubscription } from '../../scheduling/react-subscription/utils/share'
import { setOpen as setSuperOpen } from '../../reducers/superReminderSlice'
import { setModals } from '../../reducers/appSlice'

// sdhare AngularJs's sendPayslips function to be used in react
export let sendPayslips = () =>
  console.warn('Empty function - sendPayslips: Angular controller is not loaded. (runCtrl.js)')

angular
  .module('starter.business.controllers')
  .controller(
    'runCtrl',
    function (
      $scope,
      $rootScope,
      Service,
      $state,
      $ngRedux,
      Http,
      Http2,
      $filter,
      PayRunAction,
      STPService,
      PayrollService,
      GLOBALS,
      Analytics,
      HttpPromiseAndLocalStorage,
      API_ENDPOINT,
      $q
    ) {
      $scope.appState = {}
      $ngRedux.connect((state) => state.app)($scope.appState)

      if (GLOBALS.GUI_DEBUG) {
        globalThis.$scope = $scope
        globalThis.$rootScope = $rootScope
      }

      sendPayslips = async ({ payRunId }) => {
        // get payRun from server
        let retData
        let tmpPayrun
        $rootScope.sendProgress = {
          sent: 0,
          total: 0
        }
        try {
          retData = await Http2({
            method: 'get',
            api: `/v2/pay-run/${payRunId}`
          })
        } catch (error) {
          return false
        }
        tmpPayrun = retData.payRun
        const payslipArray = tmpPayrun.psl

        if ($rootScope.bulkSending) return false

        $rootScope.bulkSending = true
        $rootScope.failureArray = []

        let totalEmployeeNumber = payslipArray.length
        $rootScope.sendProgress.total = payslipArray.length
        let successCount = 0

        for (let slip of payslipArray) {
          try {
            let retData = await Http2({
              method: 'post',
              api: '/v2/send-payslip',
              data: {
                payRunId,
                payslipId: slip._id
              }
            })
            if (retData?.success) slip['sent'] = new Date().toISOString()
          } catch (error) {
            if (error && error.employee) {
              $rootScope.failureArray.push({
                message:
                  'Payslip was unable to send to ' +
                  error.employee.f +
                  ' ' +
                  error.employee.f +
                  ' (' +
                  error.employee.e +
                  ')'
              })
            }
          } finally {
            successCount++
            $rootScope.sendProgress.sent++
            $rootScope.sendProgressBar = (successCount / totalEmployeeNumber) * 100
            if (successCount === totalEmployeeNumber) {
              $rootScope.bulkSending = false
              Service.alert('Success!', 'Payslips have been successfully sent!', 'success')
              // $scope.sendProgress = null
              console.log('[Step 2] Send Payslips ─ Complete')
            }
          }
        }

        tmpPayrun.psl = payslipArray
        PayRunAction.update(tmpPayrun)
        return tmpPayrun

        // if (callback) {
        //   callback()
        // }
        // }, 2, 2)

        // for (let slip of payslipArray) {
        //   cargo.push(slip, function (err) {
        //     throw err
        //     // Sentry.captureException(err)
        //   })
        // }
      }

      $rootScope.bulkSend = function (payRunId, callBack) {
        Service.confirm({
          title: 'Send payslips',
          contents: 'Are you sure you want to send all payslips?',
          confirmText: 'Yes!',
          cancelText: 'No',
          confirmCallback: async function () {
            const payRun = await sendPayslips({ payRunId })
            if (callBack) callBack(payRun)
          },
          cancelCallback: () => {}
        })
      }

      $scope.modal = {}
      $scope.runNewPayRun = function (kind) {
        // if non-payment or not enough seats, open subscription Modal
        $scope.amplitudeEvent = (triggerPoint) => {
          Analytics.logEvent('click the trigger points', {
            trigger_point: triggerPoint,
            company_id: $rootScope.company._id
          })
          Analytics.logEvent('Triggered Paywall')
        }

        if (
          !$rootScope.gbFeatures.delayed_paywall &&
          !$ngRedux.getState().subscription.isSubscribed &&
          kind !== 'onboarding'
        ) {
          const state = $ngRedux.getState()
          $scope.amplitudeEvent('payrun')
          if (
            ['AGENT_SUBSCRIPTION_NEED_MORE_SEATS', 'SUBSCRIPTION_NEED_MORE_SEATS'].includes(
              state.subscription.errorCode
            )
          ) {
            $ngRedux.dispatch(setDisplayAddSeats(true))
          } else {
            if ($rootScope.isAgent) {
              $ngRedux.dispatch(setModalAgentSubscription(true))
            } else {
              if (
                $rootScope.gbFeatures.delayed_tokenisation &&
                state.subscription.errorCode !== 'SUBSCRIPTION_TRIAL_ENDED' &&
                $rootScope.company.allowTrial
              ) {
                $ngRedux.dispatch(setModals([{ kind: 'FreeTrial' }]))
              } else {
                $ngRedux.dispatch(
                  setModalSubscription({
                    source: 'runPayrun'
                  })
                )
              }
              if (kind === 'onBoarding') $ngRedux.dispatch(setModalFromOnBoarding(true))
            }
          }
        } else {
          Analytics.logEvent('Press New Pay Run')
          PayrollService.payrollDateModal(
            {
              payRuns: $rootScope.payRuns,
              company: $rootScope.company
            },
            (res) => {
              Analytics.logEvent('Select Pay Run Date And Period')
              $state.go('business.run.payroll', res)
            }
          )
        }
      }

      $scope.showMyCrapModal = () => {
        $ngRedux.dispatch(stpTransitionSlice.actions.showMyCrap(true))
      }
      $scope.to2018 = () => {
        $ngRedux.dispatch(stpTransitionSlice.actions.show2018modal(true))
      }
      $scope.to2020 = () => {
        $ngRedux.dispatch(stpTransitionSlice.actions.show2020modal(true))
      }
      // sets current payrun to NEW_RATE and saves employee.s_r to NEW_RATE
      $scope.updateSuperButtonClick = (fyTarget) => {
        $scope.reactState = {
          open: true,
          service: Http,
          employees: $scope.employees,
          fyTarget: fyTarget
        }
        $ngRedux.dispatch(setSuperOpen(true))
      }

      $scope.joinBeta = () => {
        Http(
          {
            method: 'post',
            api: '/v1/migrate2020/join-beta',
            data: {
              companyId: $rootScope.company._id
            }
          },
          (retVal) => {
            $rootScope.company.features.beta2020 = true
          },
          (err) => {
            console.error(err)
          }
        )
      }
      $scope.leaveBeta = () => {
        Http(
          {
            method: 'post',
            api: '/v1/migrate2020/leave-beta',
            data: {
              companyId: $rootScope.company._id
            }
          },
          (retVal) => {
            $rootScope.company.features.beta2020 = false
          },
          (err) => {
            console.error(err)
          }
        )
      }

      $scope.reportSTP = function (payRun, modalInstance) {
        // do not ask for stp v2 details when submitting stp

        // const getPayrunEmployees = (payrun) => {
        //   const payslips = payrun.psl || []
        //   const idMask = new Set(
        //     payslips.map((ps) => {
        //       return ps.ei
        //     })
        //   )
        //   const payrunEmployees = $rootScope.employees.filter((emp) => {
        //     return idMask.has(emp._id)
        //   })
        //   return payrunEmployees
        // }
        //
        // $rootScope.stpTransitionState.callback = () => {
        modalInstance?.dismiss() // hide passed modal instance
        STPService.verify(
          {
            company: $rootScope.company,
            employees: $rootScope.employees,
            payRun: payRun
          },
          () => {
            STPService.declarationModal(
              !$rootScope.isAgent && $rootScope.company.stp && $rootScope.company.stp.cn
                ? $rootScope.company.stp.cn
                : '',
              $rootScope.isAgent ? 'agent' : 'employer',
              (signatoryName) => {
                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/submit-stp/' + payRun._id,
                    data: {
                      signatoryName: signatoryName,
                      signatoryDate: $filter('date')(new Date(), 'yyyy-MM-dd', 'UTC')
                    },
                    disableErrorMsg: true
                  },
                  (res) => {
                    $scope.loading = false
                    $scope.success = true
                    Service.alert(
                      'Sent to ATO',
                      'Please allow the ATO 72 hours for your pay runs to be processed.',
                      'success'
                    )
                    // 저장된 employee data 를 update 한다.
                    PayRunAction.update(res.payRun)
                  },
                  (err) => {
                    $scope.loading = false
                    $scope.success = false

                    var errorTitle = 'Error'
                    var alertType = 'error'

                    if (err && err.code) {
                      switch (err.code) {
                        case 'inProgress':
                          alertType = 'warning'
                          errorTitle = 'In progress'
                          break
                        case 'alreadyCompleted':
                          alertType = 'success'
                          errorTitle = 'Completed'
                          PayRunAction.update(err.payRun)
                          break
                      }
                    }

                    if (err.title && err.title === 'ATO server issue') {
                      STPService.openStpErrorMessageModal({})
                    } else {
                      Service.alert(
                        errorTitle,
                        err && err.message ? err.message : 'STP submission is not successful.',
                        alertType
                      )
                    }

                    $scope.errorMessage = err.message
                  }
                )
              }
            )
          }
        )
        // }
        // $ngRedux.dispatch(setMyEmployees(getPayrunEmployees(payRun)))
      }

      $rootScope.$watch('company.abn', () => {
        const company = $rootScope.company
        if (!company?.abn) return

        const abnVerifiedAt = company.abnVerifiedAt ?? new Date().setMonth(-3)
        const now = moment()
        const diff = now.diff(abnVerifiedAt, 'months', true)

        // if isActiveAbn is null | undefined or not verified for 3 months
        if (company.isActiveAbn == null || diff >= 3) {
          STPService.updateAbn(company.abn).then((res) => {
            $rootScope.company = res
          })
        }
      })
    }
  )
