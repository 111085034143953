angular
  .module('starter.agent.controllers', [])
  .controller('verifyResellerCtrl', function ($scope, $stateParams, Http, $window, Service) {
    delete $window.localStorage.demo

    /// /////////////////////////////////
    // START : load employee details from server
    /// /////////////////////////////////
    $scope.company = {}
    $scope.status = 'loading'
    $scope.currentIndex = 1

    Http(
      {
        method: 'get',
        api: '/reseller-verification/' + $stateParams.resellerId + '/' + $stateParams.code
      },
      (res) => {
        $scope.status = 'dataLoaded'
        $scope.loading = false
        $scope.resellerDetails = res.reseller
        // 저장된 employee data 를 update 한다.
      },
      (err) => {
        $scope.loading = false

        if (err.error == 'noResult') {
          $scope.error = true
          $scope.errorMessage = "We couldn't find any reseller data from our database."
        } else if (err.error == 'alreadyActive') {
          $scope.error = true
          $scope.errorMessage = 'Your account has already been activated.'
        }
      }
    )
    /// /////////////////////////////////
    // END : load employee details from server
    /// /////////////////////////////////

    /// /////////////////////////////////
    // START : submit
    /// /////////////////////////////////
    $scope.resellerMoreDetails = {}
    $scope.submit = function () {
      $scope.resellerDetailsError = {}
      var error = false

      if (!$scope.resellerMoreDetails.url) {
        $scope.resellerDetailsError.url = true
        error = true
      }

      if (!$scope.resellerMoreDetails.serviceName) {
        $scope.resellerDetailsError.serviceName = true
        error = true
      }

      if (!$scope.resellerMoreDetails.noClients) {
        $scope.resellerDetailsError.noClients = true
        error = true
      }
      if (!$scope.resellerMoreDetails.howFind) {
        $scope.resellerDetailsError.howFind = true
        error = true
      }
      if (!$scope.resellerMoreDetails.password) {
        $scope.resellerDetailsError.password = true
        error = true
      }
      if ($scope.resellerMoreDetails.password && $scope.resellerMoreDetails.password.length < 8) {
        $scope.resellerDetailsError.password = true
        Service.alert('Password error', 'Password must be at least 8 characters.', 'error')
        error = true
      }

      if (!$scope.resellerMoreDetails.confirmPassword) {
        $scope.resellerDetailsError.confirmPassword = true
        // Service.alert(null, "Start date is required!");
        error = true
      }

      if (
        $scope.resellerMoreDetails.confirmPassword &&
        $scope.resellerMoreDetails.password != $scope.resellerMoreDetails.confirmPassword
      ) {
        $scope.resellerDetailsError.confirmPassword = true
        Service.alert('Password error', 'Confirm password must be same as password.', 'error')
        error = true
      }

      if (error) {
        return false
      }

      $scope.resellerMoreDetails.url = $scope.resellerMoreDetails.url.toLowerCase()

      Http(
        {
          method: 'post',
          api: '/reseller-verification/' + $stateParams.resellerId + '/' + $stateParams.code,
          data: {
            reseller: {
              u: $scope.resellerMoreDetails.url,
              sn: $scope.resellerMoreDetails.serviceName,
              bs: $scope.resellerMoreDetails.noClients,
              hf: $scope.resellerMoreDetails.howFind
            },
            email: $scope.resellerDetails.e,
            pw: $scope.resellerMoreDetails.password
          },
          disableErrorMsg: true
        },
        (res) => {
          $scope.loading = false
          $scope.currentIndex = 4
        },
        (err) => {
          $scope.loading = false
          if (err.error == 'URLExists') {
            $scope.resellerDetailsError.url = true
            Service.alert(null, 'URL already exists', 'error')
          }
        }
      )
    }

    /// /////////////////////////////////
    // END : submit
    /// /////////////////////////////////
  })
