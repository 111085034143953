angular
  .module('starter.agent.controllers')
  .controller('agentConnectClientCtrl', function ($scope, $rootScope, $state, $stateParams, Http, $window, Service) {
    $scope.loading = true
    Http(
      {
        method: 'post',
        api: '/v1/agent/connect-client/' + $stateParams.verificationCode
      },
      (res) => {
        $scope.loading = false
        $scope.clientCompanyName = res.newCompany.c
        $scope.init()
      },
      (error) => {
        $scope.loading = false
        Service.alert(
          error && error.title ? error.title : null,
          error && error && error.message
            ? error.message
            : 'Sorry, there was a problem processing your request. Please try again.',
          'error'
        )
        $scope.errorMessage = error.message
          ? error.message
          : 'Sorry, there was a problem processing your request. Please try again.'
      }
    )
  })
