import CanvasConfetti from 'canvas-confetti'
import React, { useEffect, useRef } from 'react'

export function Confetti(props) {
  const { doIt, style, className } = props
  const canvasRef = useRef()
  const confettiRef = useRef()

  // Setup Confetti
  useEffect(() => {
    confettiRef.current = CanvasConfetti.create(canvasRef.current, {
      resize: true
    })
  }, [])

  // Trigger Confetti
  useEffect(() => {
    const timer = setTimeout(
      () =>
        void confettiRef.current({
          colors: ['#FF6142', '#2ABDBC', '#ABEDED'],
          particleCount: 300,
          spread: 400,
          ticks: 600,
          shapes: ['circle'],
          startVelocity: 60,
          // scalar: 1,
          origin: {
            y: 0.2
          }
        }),
      750
    )

    return () => clearTimeout(timer)
  }, [doIt])

  return <canvas ref={canvasRef} style={style} className={className} />
}
