"use strict";
exports.__esModule = true;
exports.TIME_FORMATS = exports.DATE_FORMATS = void 0;
var DATE_FORMATS = [
    { value: 'D MMMM YYYY', title: '8 April 2024' },
    { value: 'DD/MM/YYYY', title: '08/04/2024' },
    { value: 'MMM D, YYYY', title: 'Apr 8, 2024' },
    { value: 'MM/DD/YYYY', title: '04/08/2024' },
    { value: 'YYYY/MM/DD', title: '2024/04/08' }
];
exports.DATE_FORMATS = DATE_FORMATS;
var TIME_FORMATS = [
    { value: 'h:mmA', title: '12-hour clock (E.g. 1:00 PM)' },
    { value: 'HH:mm', title: '24-hour clock (E.g. 13:00)' }
];
exports.TIME_FORMATS = TIME_FORMATS;
