import React from 'react'
import { OrderSummary } from './comps/OrderSummary'
import { PaymentDetails } from './comps/PaymentDetails'
import * as s from './styles'

export function PaymentSummary() {
  return (
    <div className={s.flexRowCenter}>
      <div className={s.paymentSummaryContainer}>
        <OrderSummary />
        <PaymentDetails />
      </div>
    </div>
  )
}
