/**
 * This method will extract the pathname and query params from the URL scheme
 *
 * NOTE: Using new URL() doesn't accept timesheets.employee.scheduling://tsr-signup?.......
 * as a valid value so we opted to handling it manually
 */
export const getUrlParams = (url) => {
  const pathNameSplit = url.split('://').pop().split('?')
  const pathName = pathNameSplit[0]
  const params = pathNameSplit[1]
  const queryArr = params.split('&')

  const queryParams = {}
  queryArr.forEach((param) => {
    const paramSplit = param.split('=')
    const key = paramSplit[0]
    const value = paramSplit[1]
    queryParams[key] = value
  })

  return {
    pathName,
    queryParams
  }
}

/**
 * Filter out the accepted query parameters
 */
export const getAllowedParams = (queryParams = {}, allowedParams = []) => {
  if (allowedParams.length === 0) return {}
  const newQueryParams = {}
  Object.keys(queryParams).map((key) => {
    // Check if field allowed
    if (allowedParams.indexOf(key) >= 0) newQueryParams[key] = queryParams[key]
  })
  return newQueryParams
}
