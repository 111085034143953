import { setModals } from '../../reducers/appSlice'

export let authSuccessCallback = () =>
  console.warn('Empty function - authSuccessCallback: Angular controller is not loaded. (agentSettingsCtrl.js)')

angular
  .module('starter.agent.controllers')
  .controller(
    'agentSettingsCtrl',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      Http,
      $window,
      Service,
      $timeout,
      Logout,
      CompanyAction,
      Analytics,
      HttpPromiseAndLocalStorage,
      API_ENDPOINT,
      $ngRedux,
      UserAuthFactory
    ) {
      $scope.step = $stateParams.step || 1

      authSuccessCallback = async (data) => {
        await UserAuthFactory.loginSuccess(data)
        $rootScope.isAgent = false
        $state.go('business.run.overview')
      }

      $scope.reactState = {
        HttpPromise: HttpPromiseAndLocalStorage,
        helpers: Service,
        API_ENDPOINT: API_ENDPOINT
      }
      $scope.agentSubscription = $rootScope.agent.agentSubscription
      function init() {
        var agentData = angular.copy($rootScope.agent) || {}
        if (!agentData.agd) {
          agentData.agd = {}
        }
        $scope.resellerDetails1 = {
          ag_u: agentData.ag_u || '',
          sn: agentData.agd.sn
        }

        $scope.clientPageSettings = {
          d_h: !!(agentData.agd && agentData.agd.d_h),
          ma_h: agentData.agd && agentData.agd.ma_h ? agentData.agd.ma_h : 0
        }
        // $scope.resellerDetails2 = {
        // 	c: agentData.c,
        // 	abn: agentData.abn,
        // 	a_1: agentData.a_1,
        // 	a_2: agentData.a_2,
        // 	a_c: agentData.a_c,
        // 	a_p: agentData.a_p,
        // 	a_s: agentData.a_s,
        // };

        // $scope.resellerDetails3 = {
        // 	rp: agentData.agd.rp,
        // 	b_n: agentData.agd.b_n,
        // 	b_b: agentData.agd.b_b,
        // 	b_no: agentData.agd.b_no
        // };
      }

      init()

      $scope.onClickSwitchToEmployerAccount = () => {
        $ngRedux.dispatch(
          setModals([
            {
              kind: 'SwitchToEmployerAccount'
            }
          ])
        )
      }

      $scope.$watch('agent', function (newValue, oldValue, scope) {
        init()
      })

      $scope.companySetting_1 = function () {
        var valid = true
        $scope.resellerDetailsError1 = {}
        if (!$scope.resellerDetails1.ag_u) {
          $scope.resellerDetailsError1.ag_u = true
        } else if (!$scope.resellerDetails1.ag_u.match('^[a-zA-Z0-9-]*$')) {
          $scope.resellerDetailsError1.ag_u = true
          Service.alert('Error!', 'URL can contain letters and numbers only', 'error')
          valid = false
        }

        if (!$scope.resellerDetails1.sn) {
          $scope.resellerDetailsError1.sn = true
          valid = false
        }

        if (!valid) {
          return false
        }

        $scope.loading = true
        CompanyAction.agentUpdate(
          {
            ag_u: $scope.resellerDetails1.ag_u.toLowerCase(),
            agd: {
              sn: $scope.resellerDetails1.sn
            }
          },
          () => {
            $scope.loading = false
          },
          () => {
            $scope.loading = false
          }
        )
      }

      $scope.saveClientPageSettings = () => {
        $scope.loading = true
        CompanyAction.agentUpdate(
          {
            'agd.d_h': $scope.clientPageSettings.d_h,
            'agd.ma_h': $scope.clientPageSettings.ma_h
          },
          () => {
            $scope.loading = false
          },
          () => {
            $scope.loading = false
          }
        )
      }

      $scope.getTeamMembers = function () {
        $scope.loading = true
        Http(
          {
            method: 'get',
            api: '/v1/members/'
          },
          (res) => {
            $scope.loading = false
            $scope.members = res.members
          },
          (err) => {
            $scope.loading = false
          }
        )
      }

      $scope.getName = function (firstName, lastName) {
        return firstName + ' ' + (lastName ? lastName.charAt(0).toUpperCase() + '.' : '')
      }

      $scope.reinviteMember = function (memberId) {
        Service.confirm({
          title: 'Resend invitation',
          contents: 'Are you sure you want to resend an invitation?',
          confirmText: 'Yes!',
          cancelText: 'No',
          confirmCallback: function () {
            Http(
              {
                method: 'post',
                api: '/v1/reinvite-member/' + memberId
              },
              (res) => {
                Service.alert('Success', 'The invitation has been resent.', 'success')
              },
              (_) => {}
            )
          },
          cancelCallback: () => {}
        })
      }

      $scope.deleteMember = function (user) {
        const userId = user._id
        const isDeletingSelf = userId === $rootScope.agentUser._id
        Service.confirm({
          title: isDeletingSelf ? 'Delete your login' : 'Remove member',
          contents: isDeletingSelf
            ? 'Are you sure you want to delete your login? If you proceed, you will no longer have access to this Payroller account?'
            : 'Are you sure you want to delete this member?',
          confirmText: 'Yes!',
          cancelText: 'No',
          confirmCallback: function () {
            Http(
              {
                method: 'post',
                api: '/v1/member/delete/' + userId
              },
              (res) => {
                if (isDeletingSelf) {
                  $window.localStorage.removeItem('url_client_pay')
                  $timeout(function () {
                    Service.hideLoading()
                    $state.go('home.main')
                    window.Sentry?.setUser(null)
                    Logout()
                  }, 300)
                  return
                }

                Service.alert('Success', 'This member has been deleted.', 'success')
                var index = Service.findIndexByKeyValue($scope.members, '_id', userId)
                $scope.members.splice(index, 1)
              },
              (_) => {}
            )
          },
          cancelCallback: () => {}
        })
      }

      $scope.inviteMemberAgent = function () {
        // email verification
        var valid = true
        $scope.invitationEmailErrors = {}

        if (!$scope.firstNameForInvitation) {
          $scope.invitationEmailErrors.firstName = true
          valid = false
        }

        if (!$scope.emailForInvitation || !Service.validateEmail($scope.emailForInvitation)) {
          $scope.invitationEmailErrors.email = true
          valid = false
        }

        if (!valid) {
          return false
        }

        // show share popup!
        function inviteMember() {
          $scope.loadingInvitation = true
          Http(
            {
              method: 'post',
              api: '/v1/invite-member/',
              data: {
                email: $scope.emailForInvitation,
                firstName: $scope.firstNameForInvitation,
                lastName: $scope.lastNameForInvitation
              }
            },
            (res) => {
              $scope.loadingInvitation = false
              $scope.members.push(res.newMember)
              $scope.emailForInvitation = ''
              $scope.firstNameForInvitation = ''
              $scope.lastNameForInvitation = ''
              Service.alert('Success', 'Invitation was sent successfully.', 'success')
              Analytics.logEvent('Agent Invite Team Member')
            },
            (err) => {
              $scope.loadingInvitation = false
            }
          )
        }
        inviteMember()
      }

      if (!$rootScope.reseller) {
        $state.go('reseller.overview')
        return false
      }
    }
  )
