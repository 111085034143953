/* Badge */
export const basicBadge =
  "!rounded-[4px] w-fit !text-[13px] !font-['Rubik'] !font-medium !leading-[15.41px] !px-2 !py-1 absolute truncate max-w-[45%] top-0 right-0 !mt-[17px] !mr-[14px] !bg-[#FADA69]"

/* Card Extend */
export const cardExtend = 'w-full !p-8 border-solid-non-color !border-cardBorder !rounded-[5px] !font-sansRubik'
export const extendContainer = 'flex items-center justify-between gap-3 cursor-pointer'
export const cardTitle = '!font-medium !text-[20px] !leading-[24px]'
export const cardDescription = '!font-normal !pt-6 !text-[16px] !mr-5 text-justify !leading-[19px]'

/* Check Item */
export const checkItem = 'flex items-center'
export const checkTitle = "!font-normal !text-[16px] !leading-[22.4px] !font-['Poppins'] !ml-[16px]"

/* Period Button */
export const periodButton =
  'border-solid-non-color !border-[1.5px] !rounded-[4px] !px-[53px] !py-[10px] !cursor-pointer !font-bold'
export const periodActive = 'bg-[#EDF5F5]  !border-primary'
export const periodNonActive = 'bg-white !border-white'

/* Step Button */
export const stepButton =
  '!cursor-pointer flex items-center justify-center border-solid-non-color rounded-full !w-6 !h-6 !font-medium !text-[14px] !leading-[17px]'
export const stepActive = '!bg-primary !border-transparent text-white'
export const stepNonActive = '!border-grayBorder'
