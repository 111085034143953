import clsx from 'clsx'
import React from 'react'
import * as s from './styles'

export function CheckItem({ feature }) {
  return (
    <div className={s.checkItem}>
      <img
        width={'22px'}
        height={'22px'}
        src={feature.available ? '/img/paywall/check.svg' : '/img/paywall/check-inactive.png'}
      />
      <span
        className={clsx(s.checkTitle, {
          '!text-[#ADADAD]': !feature.available
        })}
      >
        {feature.featureTitle}
      </span>
    </div>
  )
}
