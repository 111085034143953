import * as RosterApp from '../scheduling'

angular
  .module('starter.directives')
  .directive('reactApp', function ($compile, Http, $state, Service, HttpPromiseAndLocalStorage) {
    return {
      template: '<div class="react-part"></div>',
      restrict: 'E',
      scope: {
        state: '=',
        component: '@'
      },
      link: function (scope, el, attrs) {
        // scope.markComplete = (todoItem) => {scope.markItemCompleted(todoItem)}
        scope.state = scope.state || {} // Make component state optional
        if (!scope.component) {
          console.error('No component name provided', {
            component: scope.component
          })
        }
        function setState(newState) {
          Object.assign(scope.state, newState)
          scope.$applyAsync()
        }
        scope.$on('$destroy', function () {
          const root = RosterApp.passedRoots.get(el[0])
          root?.unmount()
          RosterApp.passedRoots.delete(el[0])
        })

        scope.$watch(
          'state',
          function (newValue) {
            if (angular.isDefined(newValue)) {
              RosterApp.Render(
                scope.component,
                {
                  state: scope.state,
                  setState: setState,
                  service: Http,
                  router: $state,
                  helpers: Service,
                  HttpPromise: HttpPromiseAndLocalStorage
                },
                el[0]
              )
            }
          },
          true
        )
      }
    }
  })
