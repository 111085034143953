import { useDispatch, useSelector } from 'react-redux'
import { setModals as setAppModals } from '../../reducers/appSlice'

export function useModal() {
  const dispatch = useDispatch()
  const modals = useSelector((state) => state.app.modals)

  const setModals = (val) => {
    dispatch(setAppModals(val))
  }

  const hideModal = async (kind) => {
    setModals(modals.filter((modal) => modal.kind !== kind))
  }

  return { modals, setModals, hideModal }
}
