import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import isPrerenderForV2 from '../scheduling/constant/isPrerenderForV2'

export const newEmployeeDefault = {
  cl: 25,
  su: { ty: 'r', sm: {} },
  tfnd: {},
  pu: 'h',
  // Employement details
  sd: moment().format('DD/MM/YYYY'),
  ty: 'f',
  tx: { rs: 'a', incomeStreamType: 'SAW', o_ft: true },
  // Pay details
  wwh: !isPrerenderForV2 && localStorage.pr_company ? JSON.parse(localStorage.pr_company).wh * 5 : 0,
  // Payment details
  pm: 'c'
}

const newEmployeeSlice = createSlice({
  name: 'newEmployee',
  initialState: { result: newEmployeeDefault },
  reducers: {
    setNewEmployee: (state, action) => {
      state.result = action.payload
    }
  }
})

export const { setNewEmployee } = newEmployeeSlice.actions
const slice = newEmployeeSlice.reducer
export default slice

export function useNewEmployee() {
  const dispatch = useDispatch()
  const newEmployee = useSelector((state) => state.newEmployee.result)

  const setNewEmployee = (val) => {
    dispatch(newEmployeeSlice.actions.setNewEmployee(val))
  }
  return { newEmployee, setNewEmployee }
}
