import { externalLibs } from './externalLibsService'

angular.module('starter.services').factory('LeaveService', function ($rootScope, Service) {
  function getLeaveBalance(options) {
    options = options || {
      employeeId: null,
      payRollEndDate: null
    }
    let allPayslips = $rootScope.payRuns.flatMap((run) => {
      return run.psl.map((slip) => {
        slip._payRun = {
          _id: run._id,
          s: run.s,
          ed: run.ed
        }
        return slip
      })
    })

    let retVal = {}
    if (options.employeeId) {
      const emp = $rootScope.employees.find((x) => x._id?.toString() === options.employeeId.toString())
      return externalLibs.getEmployeeLeaveBalance({
        employee: emp,
        endDate: options.payRollEndDate,
        allPayslips
      })
    } else {
      let employeeList = $rootScope.employees

      //  Filter active only employees
      if (options?.activeOnly) employeeList = employeeList.filter((emp) => emp?.s === 'a')

      for (const emp of employeeList) {
        retVal[emp._id] = externalLibs.getEmployeeLeaveBalance({
          employee: emp,
          endDate: options.payRollEndDate,
          allPayslips
        })
      }
      if (Object.values(retVal).some((x) => x.lastPayRunEndDate)) {
        retVal.lastPayRunEndDate = Object.values(retVal)
          .map((x) => x.lastPayRunEndDate)
          .reduce((a, b) => {
            return a > b ? a : b
          })
      }
      return retVal
    }
  }

  // for future leave
  function getProjectionLeaveBalance(employeeId, leaveStartDate, leaveType) {
    // 1. get remaining
    var leaveRemaining_employees = getLeaveBalance({ employeeId: employeeId })

    // 2. calculate how many payruns
    var lastPayRunEndDate = leaveRemaining_employees?.lastPayRunEndDate
    var extraEstimatedAnnualLeaveHours = 0
    var extraEstimatedSickLeaveHours = 0

    if (lastPayRunEndDate < leaveStartDate) {
      var timeDiff = Math.abs(leaveStartDate.getTime() - lastPayRunEndDate?.getTime())
      var dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24))

      const index = Service.findIndexByKeyValue($rootScope.employees, '_id', employeeId)
      const employeeData = $rootScope.employees[index]

      // employee.pp 가 w,f,m,q 이면 employee.pp 가 해달 period 이고 아니면 company.pp 가 해당 데이터 이다.
      var payPeriod = !employeeData.pp || employeeData.pp == 'a' ? $rootScope.company.pp : employeeData.pp

      var payPeriod_days = payPeriod == 'w' ? 7 : payPeriod == 'f' ? 14 : payPeriod == 'q' ? 91.25 : 30.4167 // 91.25 = 365/4  ,  30.4167 = 365/12
      // 에러 수정하기 위해 (dayDifference-1) pay period end date 가 정확히 14일이 차이나면 payRun_num 을 1로 놓게 된다.
      // 14일 차이면 다음 pay run에서 쓰는 휴가임으로 에러를 방지하기 위해서 (dayDifference-1) 을 설정하였다.
      var payRun_num = Math.floor((dayDifference - 1) / payPeriod_days)
      extraEstimatedAnnualLeaveHours =
        payRun_num *
        (payPeriod == 'w' ? 5 : payPeriod == 'f' ? 10 : payPeriod == 'q' ? 91.25 * (5 / 7) : 30.4167 * (5 / 7)) *
        $rootScope.company.wh *
        0.07692307
      extraEstimatedSickLeaveHours = extraEstimatedAnnualLeaveHours / 2
    }

    if (leaveType == 'annual') {
      const annualLeaveRemaining = leaveRemaining_employees?.annualLeaveRemaining || 0
      return {
        currentRemaining: annualLeaveRemaining,
        expectedRemaining: annualLeaveRemaining + (extraEstimatedAnnualLeaveHours || 0),
        leaveStartDate: leaveStartDate,
        lastPayRunEndDate: lastPayRunEndDate
      }
    } else {
      const sickLeaveRemaining = leaveRemaining_employees?.sickLeaveRemaining || 0
      return {
        currentRemaining: sickLeaveRemaining,
        expectedRemaining: sickLeaveRemaining + (extraEstimatedSickLeaveHours || 0),
        leaveStartDate: leaveStartDate,
        lastPayRunEndDate: lastPayRunEndDate
      }
    }
  }

  return {
    getLeaveBalance: getLeaveBalance,
    getProjectionLeaveBalance: getProjectionLeaveBalance
  }
})
