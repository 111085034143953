import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './prepareHeaders'

export const referralApi = createApi({
  reducerPath: 'referral',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.API_ENDPOINT, prepareHeaders }),
  endpoints: (build) => ({
    find: build.query({ query: () => `/v1/referral` }),
    sendEmail: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/v1/referral/sendEmail',
        method: 'POST',
        body: patch
      })
    })
  })
})

export const { useQuery } = referralApi.endpoints.find
export const { useMutation } = referralApi.endpoints.sendEmail
