const API_ENDPOINT = process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE

const post = async (url, data) => {
  const authToken = localStorage.pr_token
  return fetch(`${API_ENDPOINT}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify(data)
  }).then((response) => response.json())
}

export { post }
