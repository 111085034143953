import readXlsxFile from 'read-excel-file'
import { ngPostData } from '/src/services/httpService'

let countries = require('../../scheduling/react-stp-transition/countries').default

angular
  .module('starter.agent.controllers')
  .controller(
    'agentImportEmployeeCtrl',
    function ($scope, $rootScope, $state, $stateParams, Http, $window, Service, Analytics) {
      $scope.downloadExcelTemplate = () => {
        document.getElementById('iframe_for_download').src =
          'https://s3.amazonaws.com/bookipi.com/downloadable-files/employeeImportTemplate-20230309.xlsx'
      }

      $scope.whmCountries = countries

      $scope.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
      $scope.uploadItemList = async function (file) {
        if (file) {
          const isCSV = /(\.csv)$/i.exec(file.name)
          const isExcel = /(\.xlsx)$/i.exec(file.name)

          if (isCSV) {
            var r = new FileReader()
            r.onload = function (e) {
              var contents = e.target.result
              var importedData = Service.CSVToArray(contents)
              processExcelData(importedData)
            }

            r.readAsText(file)
          } else if (isExcel) {
            const importedData = await readXlsxFile(file)
            processExcelData(importedData)
          }
        }
      }

      function processExcelData(data) {
        if (data[0][0] && data[0][0].toLowerCase() == 'company id') {
          data.splice(0, 1)
        }
        const employeeList = []

        for (let index = 0; index < data.length; index++) {
          if (data[index][1]) {
            employeeList.push(data[index])
          }
        }

        // validation
        doValidation(employeeList)
        // $scope.$apply(function () {
        // 	$scope.importedEmployees = employeeList;
        // });
      }

      var doValidation = (employeeList) => {
        var invalid = false
        var errors = []

        employeeList.map((client, index) => {
          employeeList[index][24] = employeeList[index][24] ? String(employeeList[index][24]).replace(/\s/g, '') : ''
        })

        employeeList.map((client, index) => {
          var errorLog = {
            hasError: false,
            errorArray: [
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false
            ]
          }

          var companyExists = false

          $rootScope.clients.map((clientInList) => {
            if (clientInList._id == client[0]) {
              companyExists = true
              // employeeList[index][31] = clientInList.wh
            }
          })

          if (!companyExists) {
            errorLog.errorArray[0] = true
            errorLog.hasError = true
          }

          // 1: first name
          if (!client[1] || !/^[A-Za-z\d\s]+$/.test(client[1])) {
            console.log('errorLog.errorArray[0] = true;')
            errorLog.errorArray[1] = true
            errorLog.hasError = true
            invalid = true
          }
          // 2: last name
          if (!client[2] || !/^[A-Za-z\d\s]+$/.test(client[2])) {
            console.log('errorLog.errorArray[1] = true;')
            errorLog.errorArray[2] = true
            errorLog.hasError = true
            invalid = true
          }

          // 3: job title
          if (!client[3]) {
            console.log('errorLog.errorArray[3] = true;')
            errorLog.errorArray[3] = true
            errorLog.hasError = true
            invalid = true
          }

          // 4: email validation
          if (
            !client[4] ||
            !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
              client[4]
            )
          ) {
            console.log('errorLog.errorArray[2] = true;')
            errorLog.errorArray[4] = true
            errorLog.hasError = true
            invalid = true
          }

          // 5: DOB
          if (client[5] && !/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(client[5])) {
            console.log('errorLog.errorArray[5] = true;')
            errorLog.errorArray[5] = true
            errorLog.hasError = true
            invalid = true
          }

          // 6: address 1
          if (!client[6]) {
            console.log('errorLog.errorArray[6] = true;')
            errorLog.errorArray[6] = true
            errorLog.hasError = true
            invalid = true
          }
          // 8: suburb
          if (!client[8]) {
            console.log('errorLog.errorArray[8] = true;')
            errorLog.errorArray[8] = true
            errorLog.hasError = true
            invalid = true
          }
          // 9: postcode
          if (!client[9]) {
            console.log('errorLog.errorArray[9] = true;')
            errorLog.errorArray[9] = true
            errorLog.hasError = true
            invalid = true
          }
          // 10: state
          if (
            !(
              client[10] === 'ACT' ||
              client[10] === 'NSW' ||
              client[10] === 'NT' ||
              client[10] === 'QLD' ||
              client[10] === 'SA' ||
              client[10] === 'TAS' ||
              client[10] === 'VIC' ||
              client[10] === 'WA'
            )
          ) {
            console.log('errorLog.errorArray[10] = true;')
            errorLog.errorArray[10] = true
            errorLog.hasError = true
            invalid = true
          }

          // 11: payment option
          if (!(client[11] === 'Bank' || client[11] === 'Cash')) {
            console.log('errorLog.errorArray[11] = true;')
            errorLog.errorArray[11] = true
            errorLog.hasError = true
            invalid = true
          }
          // 12: bank account name
          if (client[11] === 'Bank' && !/^[A-Za-z\d\s]+$/.test(client[12])) {
            console.log('errorLog.errorArray[12] = true;')
            errorLog.errorArray[12] = true
            errorLog.hasError = true
            invalid = true
          }
          // 13: BSB
          if (client[11] === 'Bank' && !/^[0-9]{6}$/.test(client[13])) {
            console.log('errorLog.errorArray[13] = true;')
            errorLog.errorArray[13] = true
            errorLog.hasError = true
            invalid = true
          }
          // 14: account number
          if (client[11] === 'Bank' && !/^[0-9-]{1,9}$/.test(client[14])) {
            console.log('errorLog.errorArray[14] = true;')
            errorLog.errorArray[14] = true
            errorLog.hasError = true
            invalid = true
          }
          // 17: super rate
          if (!client[17] || isNaN(client[17])) {
            // console.log('errorLog.errorArray[17] = true;')
            errorLog.errorArray[17] = true
            errorLog.hasError = true
            invalid = true
          }
          // 18: start date
          if (!/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(client[18])) {
            console.log('errorLog.errorArray[18] = true;')
            errorLog.errorArray[18] = true
            errorLog.hasError = true
            invalid = true
          }
          // 20 : weekly work hours
          if (!client[20] || isNaN(client[20])) {
            console.log('errorLog.errorArray[20] = true;')
            errorLog.errorArray[20] = true
            errorLog.hasError = true
            invalid = true
          }
          // 21 : pay rate
          if (!client[21] || isNaN(client[21])) {
            console.log('errorLog.errorArray[21] = true;')
            errorLog.errorArray[21] = true
            errorLog.hasError = true
            invalid = true
          }
          // 23: status of residency
          if (
            !(
              client[23] === 'Australian Resident' ||
              client[23] === 'Foreign Resident' ||
              client[23] === 'Working Holiday'
            )
          ) {
            console.log('errorLog.errorArray[23] = true;')
            console.log(client[23])
            errorLog.errorArray[23] = true
            errorLog.hasError = true
            invalid = true
          }
          // 24 : TFN
          if (!Service.TFNCheck(client[24])) {
            client[24] = '000000000'
            // console.log('errorLog.errorArray[24] = true;')
            // errorLog.errorArray[24] = true
            // errorLog.hasError = true
            // invalid = true
          }

          // 34: WHM COUNTRY
          if (!client[34]) {
            if (client[33] === 'Working Holiday Maker' || client[23] === 'Working Holiday Maker') {
              errorLog.errorArray[34] = true
              errorLog.hasError = true
              invalid = true
            }
          }

          // 35: incomeStreamType
          if (!client[33]) {
            errorLog.errorArray[33] = true
            errorLog.hasError = true
            invalid = true
          }

          errors.push(errorLog)
        })

        $scope.invalid = invalid
        $scope.errors = errors
        $scope.importedEmployees = employeeList
        $scope.$applyAsync()
        return !invalid
      }

      $scope.importEmployee = () => {
        if (doValidation($scope.importedEmployees)) {
          // send clients data to server
          $scope.loading = true
          Http(
            {
              method: 'post',
              api: '/v1/agent/import-employees',
              data: {
                employees: $scope.importedEmployees,
                url: $rootScope.agent.ag_u
              },
              disableErrorMsg: true
            },
            async (res) => {
              // send invitation email
              await ngPostData({
                api: '/v1/invite-employee-app',
                data: { employeeIds: res.employeeDocuments.map((d) => d._id) }
              })

              $scope.loading = false
              Service.alert('Success!', 'Successfully imported!', 'success')
              $state.go('agent.clients')
              $scope.init()
              Analytics.logEvent('Agent Import Employee')
            },
            (err) => {
              $scope.loading = false
              Service.alert(err.error || 'Failed!', err.message, 'error')
            }
          )
        }
      }

      $scope.$watch('importFile', function (nv, ov) {
        // TODO andrew
        // Service.alert("","Sorry, this feature is currently down for maintenance. Please try again within 48 hours.","danger");

        if (nv) {
          var allowedExtensions = /(\.csv|\.xlsx)$/i
          if (!allowedExtensions.exec(nv.name)) {
            Service.alert('', 'Please upload file having extensions .csv or .xlsx only.', 'danger')
            nv = ''
            return false
          } else {
            $scope.uploadItemList(nv)
          }
        }
      })
    }
  )
