angular.module('starter.services').factory('DateService', function ($rootScope) {
  var addWeekDays = function (date, number, period) {
    var workingWeekdays = [1, 2, 3, 4, 5]
    var day = moment(date)
    if (!day.isValid()) {
      return day
    }

    if (number < 0) {
      number = Math.round(-1 * number) * -1
    } else {
      number = Math.round(number)
    }

    var signal = number < 0 ? -1 : 1
    period = typeof period !== 'undefined' ? period : 'days'

    var remaining = Math.abs(number)
    while (remaining > 0) {
      day.add(signal, period)

      if (workingWeekdays.indexOf(day.day()) >= 0) {
        remaining--
      }
    }

    return new Date(day)
  }

  // function addWeekDays(date, days){
  // 	var thisDate = new Date(date);
  // 	days = parseInt(days);
  // 	var wks = Math.floor(days/5);
  // 	var dys = days.mod(5);
  // 	var dy = thisDate.getDay();

  // 	if (dy === 6 && dys > -1) {
  // 		if (dys === 0) {dys-=2; dy+=2;}
  // 		 dys++; dy -= 6;}
  // 	if (dy === 0 && dys < 1) {
  // 		 if (dys === 0) {dys+=2; dy-=2;}
  // 		 dys--; dy += 6;}
  // 	if (dy + dys > 5) dys += 2;
  // 	if (dy + dys < 1) dys -= 2;
  // 	thisDate.setDate(thisDate.getDate()+wks*7+dys);

  // 	return thisDate;
  // }

  // var isBusinessDay = function () {
  // 	// var locale = this.localeData();
  // 	var defaultWorkingWeekdays = [1, 2, 3, 4, 5];
  // 	var workingWeekdays = defaultWorkingWeekdays;

  // 	if (this.isHoliday()) return false;
  // 	if (workingWeekdays.indexOf(this.day()) >= 0) return true;

  // 	return false;
  // };

  var businessDiff = function (date1, date2, checkHoliday) {
    var workingWeekdays = [1, 2, 3, 4, 5]
    var d1 = moment(date1)
    var d2 = moment(date2)
    var start = d1 < d2 ? d1 : d2
    var end = d2 > d1 ? d2 : d1

    var daysBetween = 0

    if (start.format('DD/MM/YYYY') === end.format('DD/MM/YYYY')) {
      return daysBetween
    }

    while (start < end) {
      if (
        workingWeekdays.indexOf(start.day()) >= 0 &&
        ((checkHoliday && $rootScope.holidays.indexOf(start.format('YYYY-MM-DD')) <= -1) || !checkHoliday)
      ) {
        daysBetween++
      }
      start.add(1, 'd')
    }
    return daysBetween
  }

  return {
    addWeekDays: addWeekDays,
    businessDiff: businessDiff
  }
})
