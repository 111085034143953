export const DivContainerModal = () => document.getElementById('modalDivsContainer')
export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  INACTIVE: 'INACTIVE'
}

export const getSafe = (accessor) => {
  try {
    return accessor()
  } catch {
    return undefined
  }
}

export function getErrMessage(error) {
  return error?.data?.message || error?.data || 'Unknown error'
}

export * from './cx'
export * from './sleep'
export * from './formatTime'
export * from './paywall'

export * from './getSSID'
