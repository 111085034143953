import moment from 'moment'

const formatPeriodLength = function (startDate, endDate, getDays = false) {
  let startDateMoment = moment(startDate)
  let endDateMoment = moment(endDate)
  const month = endDateMoment.diff(startDateMoment, 'months')
  startDateMoment = startDateMoment.add(month, 'months')
  const weeks = endDateMoment.diff(startDateMoment, 'weeks')
  startDateMoment = startDateMoment.add(weeks, 'weeks')
  const days = parseInt(Math.round(endDateMoment.diff(startDateMoment, 'days', true)))
  let periodArr = [[month, month > 1 ? 'months' : 'month']]
  if (days && days % 7 !== 0) {
    periodArr.push([weeks * 7 + days, 'days'])
  } else if (getDays) {
    periodArr.push([weeks * 7])
  } else {
    periodArr.push([weeks + (days || 0) / 7, 'weeks'])
  }
  let lengthStr = periodArr
    .filter((en) => en[0] > 0)
    .map((en) => en.join(' '))
    .join(',')
  if (lengthStr === '') {
    lengthStr = 'today'
  }
  const lastIndexOfSep = lengthStr.lastIndexOf(',')
  if (lastIndexOfSep > 0) {
    let lengthArr = lengthStr.split('')
    lengthArr.splice(lastIndexOfSep, 1, ' and ')
    return lengthArr.join('')
  } else {
    return lengthStr
  }
}

export { formatPeriodLength }
