import moment from 'moment'

const isValidMobileSubscription = (subscriptionStatus) => {
  const today = moment()
  const isValidSubscription =
    subscriptionStatus?.employerApp?.latestSubscription &&
    moment(subscriptionStatus?.employerApp?.latestSubscription.expirationDate).isSameOrAfter(today)

  return isValidSubscription
}

export { isValidMobileSubscription }
