angular
  .module('starter.business.controllers')
  .controller(
    'jobmakerCtrl',
    function (
      $scope,
      $rootScope,
      Service,
      EmployeeAction,
      $timeout,
      $filter,
      Http,
      STPService,
      Http2,
      employeeModalService
    ) {
      let jobMakerPeriods = [
        // { id: -1, start: '2020-04-07', end: '2020-7-06', claimStart: '2020-08-01', claimEnd: '2020-10-31', employees: [] }, // test only
        // { id: 0, start: '2020-07-07', end: '2020-10-06', claimStart: '2020-11-01', claimEnd: '2021-01-31', employees: [] }, // test only
        {
          id: 1,
          start: '2020-10-07',
          end: '2021-01-06',
          claimStart: '2021-02-01',
          claimEnd: '2021-04-30',
          employees: []
        }, // 1
        {
          id: 2,
          start: '2021-01-07',
          end: '2021-04-06',
          claimStart: '2021-05-01',
          claimEnd: '2021-07-31',
          employees: []
        }, // 2
        {
          id: 3,
          start: '2021-04-07',
          end: '2021-07-06',
          claimStart: '2021-08-01',
          claimEnd: '2021-10-31',
          employees: []
        }, // 3
        {
          id: 4,
          start: '2021-07-07',
          end: '2021-10-06',
          claimStart: '2021-11-01',
          claimEnd: '2022-01-31',
          employees: []
        }, // 4
        {
          id: 5,
          start: '2021-10-07',
          end: '2022-01-06',
          claimStart: '2022-02-01',
          claimEnd: '2022-04-30',
          employees: []
        }, // 5
        {
          id: 6,
          start: '2022-01-07',
          end: '2022-04-06',
          claimStart: '2022-05-01',
          claimEnd: '2022-07-31',
          employees: []
        }, // 6
        {
          id: 7,
          start: '2022-04-07',
          end: '2022-07-06',
          claimStart: '2022-08-01',
          claimEnd: '2022-10-31',
          employees: []
        }, // 7
        {
          id: 8,
          start: '2022-07-07',
          end: '2022-10-06',
          claimStart: '2022-11-01',
          claimEnd: '2023-01-31',
          employees: []
        } // 8
      ]

      let getJobMakerPeriods = (startDate) => {
        let jobMakerPeriods = [
          // { id: -1, start: '2020-04-07', end: '2020-7-06', claimStart: '2020-08-01', claimEnd: '2020-10-31' }, // test only
          // { id: 0, start: '2020-07-07', end: '2020-10-06', claimStart: '2020-11-01', claimEnd: '2021-01-31' }, // test only
          { id: 1, start: '2020-10-07', end: '2021-01-06', claimStart: '2021-02-01', claimEnd: '2021-04-30' }, // 1
          { id: 2, start: '2021-01-07', end: '2021-04-06', claimStart: '2021-05-01', claimEnd: '2021-07-31' }, // 2
          { id: 3, start: '2021-04-07', end: '2021-07-06', claimStart: '2021-08-01', claimEnd: '2021-10-31' }, // 3
          { id: 4, start: '2021-07-07', end: '2021-10-06', claimStart: '2021-11-01', claimEnd: '2022-01-31' }, // 4
          { id: 5, start: '2021-10-07', end: '2022-01-06', claimStart: '2022-02-01', claimEnd: '2022-04-30' }, // 5
          { id: 6, start: '2022-01-07', end: '2022-04-06', claimStart: '2022-05-01', claimEnd: '2022-07-31' }, // 6
          { id: 7, start: '2022-04-07', end: '2022-07-06', claimStart: '2022-08-01', claimEnd: '2022-10-31' }, // 7
          { id: 8, start: '2022-07-07', end: '2022-10-06', claimStart: '2022-11-01', claimEnd: '2023-01-31' } // 8
        ]

        let endDate = luxon.DateTime.fromJSDate(startDate).plus({ years: 1 })
        let periods = []
        for (let period of jobMakerPeriods) {
          if (endDate < new Date(period.start)) {
            break
          }
          if (new Date(period.start) < endDate && startDate <= new Date(period.end)) {
            periods.push(period)
          }
        }
        return periods
      }

      $scope.initialLoading = true
      $scope.jobMakerEmployees = []
      $scope.jobMakerSubmissions = []
      $scope.jobMakerSubmissionPeriods = []

      const eligibilityTestModule = (emp) => {
        let eligible = true
        let ageValid = true
        let tfnValid = true
        let startValid = true
        let hoursValid = true
        let employValid = true
        let nominated = !!(emp.jobMakerData && emp.jobMakerData.nominated)
        let tfnDeclared = true

        // let nominationCancelled = false
        let nominationCode = 'JMHC-NOM'
        if (emp.sd && new Date(emp.sd) < new Date('2020-10-07')) {
          startValid = false
          eligible = false
        }

        // Jobmaker should go based on the age the employee was first employed.
        const baseDateForAge = emp.sd && new Date(emp.sd) >= new Date('2020-10-07') ? new Date(emp.sd) : new Date()
        const empAge = moment(baseDateForAge).diff(emp.dob, 'years')

        if (!empAge || empAge < 16 || empAge > 35) {
          ageValid = false
          eligible = false
        }
        if (!emp.wwh || emp.wwh < 20) {
          hoursValid = false
          eligible = false
        }
        if (emp.s !== 'a') {
          employValid = false
        }

        if (!emp.tx || !emp.tx.no || !Service.TFNCheck(emp.tx.no)) {
          tfnValid = false
          eligible = false
        }

        if (!emp.tfnd || (!emp.tfnd.p && !emp.tfnd.at)) {
          tfnDeclared = false
        }

        // statusColor = 'red'
        // statusColor = 'grey'

        return {
          _id: emp._id,
          employee: emp,
          // status: status,
          // statusColor,
          nominationCode: nominationCode,
          firstName: emp.f,
          lastName: emp.l,
          startDate: emp.sd,
          checked: false,
          hoursPerWeek: emp.wwh,
          age: empAge,
          eligible,
          ageValid,
          tfnValid,
          startValid,
          hoursValid,
          employValid,
          nominated,
          tfnDeclared
        }
      }

      // $state.current.name = 'business.jobmaker.employees'
      Http2({
        method: 'get',
        api: '/v1/employee?s=a'
      }).then(async (employees) => {
        $scope.initialLoading = false
        for (let emp of employees || []) {
          const empTestReturn = eligibilityTestModule(emp)
          if (empTestReturn.startValid && empTestReturn.ageValid) {
            $scope.jobMakerEmployees.push(empTestReturn)
          }
        }
        $scope.getJobMakerSubmissions()
      })

      $scope.$watch('employees', function (newEmployeeArr, oldEmployeeArr) {
        if (newEmployeeArr && newEmployeeArr.length > 0 && oldEmployeeArr && oldEmployeeArr.length > 0) {
          for (let index = 0; index < newEmployeeArr.length; index++) {
            const newEmp = newEmployeeArr[index]
            const oldEmp = oldEmployeeArr[index]
            if (
              newEmp.sd != oldEmp.sd ||
              newEmp.dob != oldEmp.dob ||
              newEmp.wwh != oldEmp.wwh ||
              newEmp.dob != oldEmp.dob ||
              newEmp.s != oldEmp.s ||
              newEmp.tx.no != oldEmp.tx.no ||
              newEmp.dob != oldEmp.dob ||
              JSON.stringify(newEmp.jobMakerData) != JSON.stringify(oldEmp.jobMakerData)
            ) {
              // update jobMakerEmployees

              $scope.initialLoading = true
              for (let j = 0; j < $scope.jobMakerEmployees.length; j++) {
                const emp = $scope.jobMakerEmployees[j]
                if (newEmp._id === emp._id) {
                  $scope.jobMakerEmployees[j] = eligibilityTestModule(newEmp)
                }
              }

              $timeout(() => {
                $scope.initialLoading = false
              }, 500)
            }
          }
        }
      })

      $scope.getShortName = (firstName, lastName) => {
        return `${firstName} ${lastName ? lastName.charAt(0).toUpperCase() + '.' : ''}`
      }

      // TODO : I did copy/paste
      $scope.addEmployee = function () {
        let jobKeeperEmployees = $scope.jobMakerEmployees
        let payRunData = $scope.payRunData

        function pushNewJobKeeperEmployee(empData) {
          // existence test
          // avoid duplication
          let empIdExists = {}
          for (const x of $scope.jobMakerEmployees) {
            empIdExists[x._id] = true
          }

          if (!empIdExists[empData._id]) {
            $scope.jobMakerEmployees.push(empData)
          }
        }

        Service.modal('addEmployeeModal.html', [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            $scope.hideCreateNewButton = true
            $scope.cancel = function () {
              $uibModalInstance.dismiss()
            }

            $scope.getName = function (firstName, lastName) {
              return firstName + ' ' + (lastName ? lastName.charAt(0).toUpperCase() + '.' : '')
            }

            // get employees that the current job maker list doesn't include
            let employeeList = []
            let jobMakerEmployeeObj = {}
            for (const employee of jobKeeperEmployees) {
              jobMakerEmployeeObj[employee._id] = true
            }
            $rootScope.employees.map((eachEmployee) => {
              if (eachEmployee.s !== 'd' && !jobMakerEmployeeObj[eachEmployee._id]) {
                employeeList.push(eachEmployee)
              }
            })

            $scope.employeeList = employeeList

            $scope.addEmployeeToPayslip = async (employee) => {
              const empTestReturn = eligibilityTestModule(employee)
              pushNewJobKeeperEmployee(empTestReturn)
              $scope.cancel()
            }
          }
        ])
      }

      $scope.openJobMakerEmployeeModal = function (empDetails) {
        Service.modal(
          'jobMakerEmployeeModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.empDetails = empDetails
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.editEmployee = (redirectTab) => {
                $scope.cancel()
                employeeModalService.edit(empDetails.employee, redirectTab)
              }

              $scope.nominate = async (empDetails) => {
                $scope.loading = true

                let taxYear
                if (new Date().toISOString() < `${new Date().getFullYear()}-07-01`) {
                  taxYear = new Date().getFullYear()
                } else {
                  taxYear = new Date().getFullYear() + 1
                }

                // 1. validation
                // 2. record Eligibility information in employee db

                // 1.
                $scope.errors = []
                if (!empDetails.eligible) {
                  $scope.errors.push("This employee doesn't meet eligibility requirements")
                }

                if (!empDetails.tfnDeclared && !$scope.tfndSubmitted) {
                  $scope.errors.push('Tax File number must be declared. Tick the box above to proceed.')
                }

                if ($scope.errors.length > 0) {
                  $scope.loading = false
                  return false
                }

                // 2.
                Http(
                  {
                    method: 'post',
                    api: '/v1/employee/update/' + empDetails._id,
                    data: {
                      companyId: $rootScope.company._id,
                      employee: {
                        'jobMakerData.nominated': !empDetails.nominated,
                        ...(!empDetails.tfnDeclared &&
                          $scope.tfndSubmitted && {
                            'tfnd.p': true,
                            'tfnd.s_at': Service.getUTCDate()
                          })
                      }
                    }
                  },
                  (res) => {
                    $scope.loading = false
                    $scope.cancel()
                    EmployeeAction.update(res.employee)
                  },
                  (_) => {}
                )
                $scope.loading = false
              }
            }
          ],
          { size: 'lg' }
        )
      }

      // $scope.cancelNomination = async (empDetails) => {
      //   $scope.loading = true

      //   let taxYear
      //   if (new Date().toISOString() < `${new Date().getFullYear()}-07-01`) {
      //     taxYear = new Date().getFullYear()
      //   } else {
      //     taxYear = new Date().getFullYear() + 1
      //   }
      //   await Http2({
      //     method: 'post',
      //     api: '/v1/sbr/jobmaker/push',
      //     data: {
      //       employeeId: empDetails.employee._id,
      //       payload: 'JMHC-NOMX',
      //       signatoryName: $scope.user.f,
      //       taxYear: taxYear
      //     }
      //   })
      //   $scope.loading = false
      // }
      $scope.reportPeriod = async (empDetails, periodId, enoughHours) => {
        $scope.loading = true

        let taxYear
        if (new Date().toISOString() < `${new Date().getFullYear()}-07-01`) {
          taxYear = new Date().getFullYear()
        } else {
          taxYear = new Date().getFullYear() + 1
        }
        await Http2({
          method: 'post',
          api: '/v1/sbr/jobmaker/push',
          data: {
            employeeId: empDetails._id,
            payload: `JMHC-P0${periodId}${enoughHours ? '' : 'X'}`,
            signatoryName: $scope.user.f,
            taxYear: taxYear
          }
        })
        $scope.loading = false
      }

      $scope.getJobMakerSubmissions = async () => {
        $scope.initialLoading = true
        // get applicable jobmaker periods
        let jobMakerSubmissionPeriods = []
        for (const JMPeriod of jobMakerPeriods) {
          if (Service.getUTCDate(JMPeriod.claimStart) <= Service.getUTCDate()) {
            jobMakerSubmissionPeriods.push({
              ...angular.copy(JMPeriod),
              status: null
            })
          }
        }

        // check if jobmaker submitted
        const submittedJobMakers = await Http2({
          method: 'get',
          api: '/v1/jobmakers'
        })

        // add nominated & eligible employees to jobmaker period array
        let eligibleEmployees = []
        for (const jobMakerEmployee of $scope.jobMakerEmployees) {
          if (jobMakerEmployee.eligible && jobMakerEmployee.nominated) {
            const eligiblePeriod = getJobMakerPeriods(new Date(jobMakerEmployee.startDate))

            eligibleEmployees.push({
              ...jobMakerEmployee,
              eligiblePeriod
            })

            let JMPeriodObj = {}
            for (const x of eligiblePeriod) {
              JMPeriodObj[x.id] = x
            }

            for (let index = 0; index < jobMakerSubmissionPeriods.length; index++) {
              const eachJMSubmission = jobMakerSubmissionPeriods[index]
              if (JMPeriodObj[eachJMSubmission.id]) {
                jobMakerSubmissionPeriods[index].employees.push(jobMakerEmployee)
                jobMakerSubmissionPeriods[index].submittedJobMakers = []

                // inject submitted jobkeepers to jobMakerSubmissionPeriods
                for (let j = 0; j < submittedJobMakers.length; j++) {
                  const submittedJobMaker = submittedJobMakers[j]
                  if (jobMakerSubmissionPeriods[index].id == submittedJobMaker.period) {
                    jobMakerSubmissionPeriods[index].submittedJobMakers.unshift(submittedJobMaker)
                    jobMakerSubmissionPeriods[index].status = submittedJobMaker.status
                  }
                }
              }
            }
          }
        }

        $scope.jobMakerSubmissionPeriods = jobMakerSubmissionPeriods
        $timeout(() => {
          $scope.initialLoading = false
        }, 500)
      }

      $scope.openJobMakerReportModal = function (JMPeriodData) {
        Service.modal(
          'jobMakerReportModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }
              $scope.JMPeriodData = angular.copy(JMPeriodData)

              $scope.selectAll = (checkedAll) => {
                $scope.JMPeriodData.employees.map((eachClient, index) => {
                  $scope.JMPeriodData.employees[index].checked = checkedAll
                })
              }

              $scope.isLocked = () => {
                return (
                  !$scope.resubmit &&
                  ($scope.JMPeriodData.status === 'pending' || $scope.JMPeriodData.status === 'accepted')
                )
              }

              $scope.submitJobMaker = () => {
                // workflow
                // 1. validation
                // 2. STP declaration
                // 3. submit

                // 1.
                let numChecked = 0
                for (const employee of $scope.JMPeriodData.employees) {
                  if (employee.checked) {
                    numChecked++
                  }
                }

                if (!numChecked) {
                  Service.alert('Alert!', 'Select at least 1 employee', 'error')
                  return false
                }

                // 2
                $scope.cancel()
                STPService.declarationModal(
                  !$rootScope.isAgent && $rootScope.company.stp && $rootScope.company.stp.cn
                    ? $rootScope.company.stp.cn
                    : '',
                  $rootScope.isAgent ? 'agent' : 'employer',
                  (signatoryName) => {
                    // 3
                    Http2({
                      method: 'post',
                      api: '/v1/sbr/jobmaker/submit',
                      data: {
                        jobMakerData: $scope.JMPeriodData,
                        signatoryName: signatoryName,
                        signatoryDate: $filter('date')(new Date(), 'yyyy-MM-dd', 'UTC')
                      }
                    })
                      .then((_) => {})
                      .catch((_) => {})
                  }
                )
              }
            }
          ],
          { size: 'xxl' }
        )
      }
    }
  )
