export const LINKS = {
  TERMS_CONDITIONS: {
    url: 'https://payroller.com.au/terms-of-service',
    eventName: 'Terms & Conditions click (Standard screen)'
  },
  APP_DOWNLOAD: {
    url: `${process.env.API_ENDPOINT}/redirection/payroller-app?userId=`,
    eventName: 'Click here for QR Code (Lite screen)'
  }
}

// for SendPayslipsNow
export const SEND_PAYSLIPS_NOW_MODAL_READ_MORE = 'https://www.fairwork.gov.au/pay-and-wages/paying-wages/pay-slips'
// for PaySuperNow
export const PAY_SUPER_NOW_MODAL_FIND_OUT_MORE = 'https://beamconnect.com.au/employers'
