import { useState, useEffect } from 'react'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { Capacitor } from '@capacitor/core'

const useSafeAreaView = () => {
  const [safeAreaMargin, setSafeAreaMargin] = useState({})
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      setSafeAreaMargin(insets)
    })
  }, [])
  return safeAreaMargin
}

export default useSafeAreaView
