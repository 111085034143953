export const wrap = '!font-sansPop !flex !justify-center'

export const cards = 'flex gap-3 md:flex-col'

export const card = (active) =>
  `flex-1 ${active ? '!border-[2px]' : 'border-solid-non-color'}  !border-solid ${
    active ? '!border-primary' : '!border-cardBorder'
  } 
  }] rounded-[5px] !p-[16px]  flex flex-col justify-between relative`

export const title = '!font-semibold !text-[18px]'

export const priceWrap = 'flex items-end'
export const price = '!font-semibold !text-[32px] !leading-[32px] tracking-[-0.02em]'
export const priceInfo = '!font-sansWork whitespace-nowrap !leading-[20px] truncate'

export const description = 'text-p-xs !leading-[18px] !text-[14px]'
export const link = '!text-[#006CCF] cursor-pointer'

export const itemContainer = 'flex gap-[17.5px] flex-col'
