import { createAsyncThunk } from '@reduxjs/toolkit'
import { gtag } from '../../components/GaInit'

export const isDev = process.env.P_NODE_ENV === 'development'
export const isDebug = process.env.ENABLE_DEBUG === 'true'
export const isGuiDebug = isDebug && process.env.GUI_DEBUG === 'DEBUG'

export const GoTermAndConditions = () => {
  window.open('https://payroller.com.au/terms-of-service', '_blank')
}

export const openHelpUs = () => {
  document.getElementsByClassName('intercom-launcher')[0].click()
}

export const APIgetCalculatePrice = (seats, category) => {
  return {
    method: 'get',
    api: `/v1/subscription/calculatePrice?seats=${seats}&category=${category}`
  }
}

export const APIgetCalculatePriceWOlogin = (seats, category) => {
  return {
    method: 'get',
    api: `/subscription/calculatePrice?seats=${seats}&category=${category}`
  }
}

export const APIaddSubscriptionWebapp = (params) => {
  return {
    method: 'post',
    api: `/v1/subscription/webapp`,
    data: params
  }
}
export const APIInviteClientPay = (params) => {
  return {
    method: 'post',
    api: '/v1/agent/send-payment-bind-invitation',
    data: params
  }
}

export const APIgetInitial = () => {
  return {
    method: 'get',
    api: '/v1/initial/'
  }
}

export const APIgetEmployeeInitial = (companyId) => {
  return {
    method: 'get',
    api: `/v1/employee-portal/init?companyId=${companyId}`
  }
}

export const APIgetEmployeeNotification = (employeeId) => {
  return {
    method: 'get',
    api: `/v1/rosters/employee-notifications?employeeId=${employeeId}`
  }
}

export const getInitial = createAsyncThunk(
  'app/getInitialData',
  async function ({ HttpPromise, userType }, { dispatch }) {
    let employeeRes = {}
    let employeeData

    const employerRes = await HttpPromise(APIgetInitial())
    const user = employerRes?.user
    const isEmployee = (userType && userType === 'employee') || user?.userType === 'employee'

    if (isEmployee) {
      if (employerRes?.company) employeeRes = await HttpPromise(APIgetEmployeeInitial(employerRes?.company?._id))
      employeeData = employeeRes[0]

      // Get actionable notification
      if (employeeData) {
        const { notifications } = await HttpPromise(APIgetEmployeeNotification(employeeData?.employee?._id))
        employeeData['notifications'] = notifications
      }
    }

    if (!isEmployee && !employeeData) {
      dispatch(getBillingInvoices({ HttpPromise }))
    }

    return {
      ...employerRes,
      ...(isEmployee && employeeData && { employeeData })
    }
  }
)

export const APIgetPaymentMethod = () => {
  return {
    method: 'get',
    api: `/v1/paymentMethod/`
  }
}

export const APIdeleteWebapp = () => {
  return {
    method: 'delete',
    api: `/v1/subscription/webapp`
  }
}

export const APIgetBillingInvoices = () => {
  return {
    method: 'get',
    api: '/v1/bills'
  }
}

export const getBillingInvoices = createAsyncThunk('subscription/getBillingInvoices', async function ({ HttpPromise }) {
  return HttpPromise(APIgetBillingInvoices())
})

export const agentInit = createAsyncThunk('app/agentInit', async function ({ Http2 }) {
  return Http2({
    method: 'get',
    api: '/v1/agent/get'
  })
})

export const updateCompany = createAsyncThunk('app/updateCompany', async function ({ Http2 }) {
  return Http2({
    method: 'get',
    api: '/v1/agent/get'
  })
})

// give it 3000ms to get the gcid (for worst cases)
// TODO: cache?
const getGAField = (fieldName) =>
  Promise.race([
    new Promise(async (resolve) => {
      try {
        if (process.env.GA_DATA_STREAM_MEASUREMENT_ID) {
          gtag('get', process.env.GA_DATA_STREAM_MEASUREMENT_ID, fieldName, resolve)
        } else {
          resolve(undefined)
        }
      } catch (err) {
        window.Sentry?.captureException(err)
        resolve(undefined)
      }
    }),
    new Promise((resolve) => {
      setTimeout(resolve, 3000, undefined)
    })
  ])
export const getGcid = () => getGAField('client_id')

export const getGcSessionId = () => getGAField('session_id')

export const updateSubscription = createAsyncThunk(
  'subscription/updateSubscription',
  async function ({ HttpPromise, plan, seats, reference, discount, payer, trial, method }) {
    const [gcid, gsid] = await Promise.all([await getGcid(), await getGcSessionId()])
    const ret = await HttpPromise(
      APIaddSubscriptionWebapp({
        period: plan,
        seats: seats,
        discount: discount,
        payer: payer,
        trial: trial,
        gcid,
        gsid,
        ...(method ? { method } : {}),
        ...(reference ? { reference } : {})
      })
    )
    return ret
  }
)

export const APIgetSubscriptionStatus = () => {
  return {
    method: 'get',
    api: '/v1/subscription'
  }
}

export const getSubscriptionStatus = createAsyncThunk('subscription/getStatus', async function ({ HttpPromise }) {
  const ret = await HttpPromise(APIgetSubscriptionStatus())
  return ret
})

// When this function called it notify to both slices (appSlice & subscriptionSlice).
// It enables new_paywall and the existing paywall without the circular dependency issue. (https://redux-toolkit.js.org/usage/usage-guide/#exporting-and-using-slices)
export const setModalSubscription = createAsyncThunk('subscription/setModalSubscription', (payload) => payload) // just pass the original payload

export const APIupdateUser = (params) => {
  return {
    method: 'post',
    api: `/v1/user/update`,
    data: params
  }
}
export const APIdownloadInvoice = (id) => {
  return {
    method: 'get',
    api: `/v1/bills/${id}`,
    responseType: 'blob'
  }
}

/**
 *
 * @description to identify the type of events on dev (could be removed in future)
 */
export function gaEvent({ name, items, description }) {
  if (isDev) {
    console.log(
      '%c gaEvent start',
      'background: hsl(218, 100%, 70%); color: #fff; border-radius: 4px; padding: 2px 8px 2px 0px;',
      description,
      { name, items }
    )
  }

  try {
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: name,
      ecommerce: {
        items,
        currency: 'AUD',
        description
      }
    })
  } catch (err) {
    window.Sentry?.captureException(err)
  }
}

export const APIupdatePaymentMethodsForAll = () => {
  return {
    method: 'post',
    api: '/v1/updatePaymentMethodsForAllPayingSubscriptions'
  }
}
