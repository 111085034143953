import React from 'react'
import { usePaywall } from '../usePaywall'
import { cx } from '../../../../../utils'
import * as s from './styles'

export function StepButton({ stepOrder }) {
  const { step, setStep } = usePaywall()
  const active = step === stepOrder

  return (
    <div className={cx(s.stepButton, active ? s.stepActive : s.stepNonActive)} onClick={() => setStep(stepOrder)}>
      {stepOrder + 1}
    </div>
  )
}
