import React, { useEffect, useState } from 'react'
import { Button } from '@bookipi/bds'
import { useMutation } from '../../../../../api/survey'
import { useSelector } from 'react-redux'
import { logEvent } from '../../../../../modules/analytics'
import { post } from '../../../../../api/fetch'
import { formatMoney } from '../../../../react-subscription/utils'

const DiscountOfferModal = (props) => {
  const { hideModal, setAlert, data, alert } = props
  const { data: payloadData, answers, deleteSubscription } = data
  const { id, isPayingUser, isV2 } = payloadData
  const [isCreatingVoucher, setCreatingVoucher] = useState(false)
  const company = useSelector((state) => state.app.company)
  const user = useSelector((state) => state.app.user)
  const agent = useSelector((state) => state.app.agent)
  const subscription = useSelector((state) => state.app.subscriptionStatus)
  const userEmail = user?.e || agent?.e || company?.e
  const onClose = () => {
    logEvent('Discount Voucher Modal Closed')
    hideModal('DiscountOfferModal')
  }
  const [newSurvey, { isLoading }] = useMutation()
  const amount = subscription?.webapp?.period === 'year' ? 2500 : 1600
  useEffect(() => {
    logEvent('Discount Voucher Modal Viewed')
  }, [])
  const onRedeemVoucher = async () => {
    setCreatingVoucher(true)
    const data = await post('/vouchers/create', {
      companyId: id,
      amount,
      desc: 'Loyal Customer Voucher'
    })
    if (data.message === 'success') {
      if (!isV2) {
        setAlert({
          title: 'Voucher successfully redeemed',
          message: 'The voucher has been applied to your account! Thank you for choosing to stay with us!',
          kind: 'success'
        })
      } else {
        alert(
          'Voucher successfully redeemed',
          'The voucher has been applied to your account! Thank you for choosing to stay with us!',
          'success'
        )
      }
      logEvent('Discount Voucher Redeem Button Clicked')
      setCreatingVoucher(false)
      onClose()
    } else {
      if (!isV2) {
        setAlert({
          title: 'Something went wrong',
          message: data.message,
          kind: 'error'
        })
      } else {
        alert('Something went wrong', data.message, 'error')
      }
      onClose()
    }
  }
  const onCancelSubscription = async () => {
    await newSurvey({ answers: { companyId: id, isPayingUser, userEmail, ...answers } })
    logEvent('Discount Voucher Continue Cancel Button Clicked')
    deleteSubscription(id)
    onClose()
  }
  const value = formatMoney(amount)
  return (
    <div
      className={
        'p-4 bg-white shadow-modal rounded-lg h-fit w-[545px] flex flex-col items-center text-black font-inter'
      }
    >
      <button className="bg-transparent cursor-pointer ml-auto" onClick={onClose}>
        <div className="icon-feather-x fs-24" />
      </button>
      <div className="flex flex-col items-center gap-6 w-full">
        <div className="font-bold text-xl">We're sorry to see you go!</div>
        <div className="text-md">
          As a thank you for using Payroller, we'd like you to have a special {value} credit*. The credit will
          automatically be applied to your account.
        </div>
        <div className="text-center text-xs mt-3 mx-8 text-[#77838f]">
          *Offer must be redeemed at time of offer and can only be redeemed once. Credit only applies to this account
          and is not redeemable for cash out or refund.
        </div>
        <div className="flex gap-x-4">
          <button
            className="bg-transparent text-[#77838f]"
            color="red"
            disabled={isLoading || isCreatingVoucher}
            onClick={onCancelSubscription}
          >
            No, cancel subscription
          </button>
          <Button onClick={onRedeemVoucher} disabled={isLoading || isCreatingVoucher}>
            <div className="font-bold">Claim your {value} credit</div>
          </Button>
        </div>
      </div>
    </div>
  )
}

export { DiscountOfferModal }
