angular
  .module('starter.services')
  .factory('EmployeeContactsPdfService', function ($filter, $q, Service, $rootScope, $window) {
    return function (opt) {
      var deferred = $q.defer()

      let csvData = null
      const csvItems = []

      csvItems.push([$rootScope.company.c], ['ABN: ' + $rootScope.company.abn], [])
      csvItems.push(['Name', 'Email', 'Phone', 'Address'])

      for (let index = 0; index < $rootScope.employees.length; index++) {
        const employee = Service.sanitizeObj(angular.copy($rootScope.employees[index]))
        if (employee.s !== 'd') {
          csvItems.push([
            employee.f + ' ' + employee.l,
            employee.e || '',
            employee.ph || '',
            (employee.a_1 || '') +
              ' ' +
              (employee.a_2 || '') +
              ' ' +
              (employee.a_c || '') +
              ' ' +
              (employee.a_s || '') +
              ' ' +
              (employee.a_p || '')
          ])
        }
      }

      let csvContent = 'data:text/csv;charset=utf-8,'
      csvItems.forEach(function (rowArray) {
        let row = rowArray.map((x) => `"${x.toString().replace(/"/g, '""')}"`).join(',')
        csvContent += row + '\r\n'
      })
      csvData = encodeURI(csvContent)

      var items = []
      var stripeColor = '#deedf7'

      items.push([
        { text: 'Name', style: 'tableHeader', bold: true, border: [false, false, false, true], fillColor: stripeColor },
        {
          text: 'Email',
          style: 'tableHeader',
          bold: true,
          border: [false, false, false, true],
          fillColor: stripeColor
        },
        {
          text: 'Phone',
          style: 'tableHeader',
          bold: true,
          border: [false, false, false, true],
          fillColor: stripeColor
        },
        {
          text: 'Address',
          style: 'tableHeader',
          bold: true,
          border: [false, false, false, true],
          fillColor: stripeColor
        }
      ])

      for (let index = 0; index < $rootScope.employees.length; index++) {
        const employee = Service.sanitizeObj(angular.copy($rootScope.employees[index]))
        if (employee.s !== 'd') {
          items.push([
            { text: employee.f + ' ' + employee.l, style: ['table'], border: [false, false, false, false] },
            { text: employee.e || '', style: ['table'], border: [false, false, false, false] },
            { text: employee.ph || '', style: ['table'], border: [false, false, false, false] },
            {
              text:
                (employee.a_1 || '') +
                ' ' +
                (employee.a_2 || '') +
                ' ' +
                (employee.a_c || '') +
                ' ' +
                (employee.a_s || '') +
                ' ' +
                (employee.a_p || ''),
              style: ['table'],
              border: [false, false, false, false]
            }
          ])
        }
      }

      let pdf = {
        content: [
          {
            columns: [
              {
                text: [
                  // {text: "Report Group : " + data.reportName + "\n", bold: true},
                  { text: $rootScope.company.c + '\n\n', bold: true },
                  { text: 'ABN: ' + $rootScope.company.abn }
                  // {text: (data.employeeData ? (data.employeeData.f + " " + data.employeeData.l +"\n"): "" ) + data.dateRange, bold:true},
                ],
                margin: [10, 5, 0, 10]
              },
              {
                alignment: 'right',
                text: [{ text: 'Employee Contacts\n', fontSize: 16, bold: true, marginBottom: 10 }],
                margin: [0, 0, 10, 10]
              }
            ]
          },
          '\n',
          {
            style: 'tableExample',
            table: {
              headerRows: 1,
              widths: [80, 100, 80, '*'],
              body: items
            },
            layout: {
              hLineColor: function (i, node) {
                return '#badbef'
              }
            }
          }
        ],

        styles: {
          tableHeader: {
            bold: true,
            marginTop: 4,
            marginBottom: 2,
            marginLeft: 2,
            marginRight: 5
          },
          table: {
            marginTop: 4,
            marginBottom: 2,
            marginLeft: 2,
            marginRight: 5
          },
          default: {
            marginTop: 3,
            marginBottom: 1,
            marginLeft: 5,
            marginRight: 5
          }
        },
        defaultStyle: {
          columnGap: 30,
          fontSize: 8.5,
          lineHeight: 1.2,
          font: 'dejavu'
        }
      }

      var fontDescriptors = {
        dejavu: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf'
        }
      }
      pdfMake.fonts = fontDescriptors
      pdf = pdfMake.createPdf(pdf)
      pdf.getBase64(function (output) {
        deferred.resolve({ csvData, pdfData: opt === 'base64' ? output : base64ToUint8Array(output) })
      })

      return deferred.promise
    }

    function base64ToUint8Array(base64) {
      var raw = atob(base64)
      var uint8Array = new Uint8Array(raw.length)
      for (var i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    }
  })
