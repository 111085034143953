import React from 'react'

import {
  PublishRoster,
  RosterConflictView,
  BusinessDetails,
  AgentErrorModal,
  AddShift as AddRosterShift,
  AgentProgramWelcomeModal,
  SelectIndustryModal,
  AnnualPlanSwitchModal,
  STPReportMigrationModal,
  BankDetailsForm
} from '@payroller/pds'

const SuperSummaryModalSelect = React.lazy(() => import('./SuperSummary'))
const AddEmployee = React.lazy(() => import('./AddEmployee'))
const SendPayslipsNow = React.lazy(() => import('./SendPayslipsNow'))
const PaySuperNow = React.lazy(() => import('./PaySuperNow'))
const SetupBusiness = React.lazy(() => import('./SetupBusiness'))
const EnableSTPInfo = React.lazy(() => import('./EnableSTPInfo'))
const EnableSTP = React.lazy(() => import('./EnableSTP'))
const Notice = React.lazy(() => import('./Notice'))
const Test = React.lazy(() => import('./Test'))
const FreeTrial = React.lazy(() => import('./FreeTrial'))
const ReferFriends = React.lazy(() => import('./ReferFriends'))
const OnboardingChecklist = React.lazy(() => import('./OnboardingChecklist'))
const SwitchToEmployerAccount = React.lazy(() => import('./SwitchToEmployerAccount'))
const Roster = React.lazy(() => import('./Roster'))
const Timesheet = React.lazy(() => import('./Timesheet'))
const PDFReportModal = React.lazy(() => import('./PDFReportModal'))
const PriceLockInSummaryModal = React.lazy(() => import('./PriceLockInSummaryModal'))

export * from './RemoveSubscription'
export * from './Paywall'
export * from './QRCode'
export * from './CheckLists'
export * from './OnboardingChecklist/OnboardingVideos'
export * from './PriceLockInModal'
export * from './RemoveSubscription/DiscountOfferModal'

export {
  SuperSummaryModalSelect,
  AddEmployee,
  PublishRoster,
  RosterConflictView,
  BusinessDetails,
  AgentErrorModal,
  AgentProgramWelcomeModal,
  AddRosterShift,
  SelectIndustryModal,
  AnnualPlanSwitchModal,
  STPReportMigrationModal,
  BankDetailsForm,
  SendPayslipsNow,
  PaySuperNow,
  SetupBusiness,
  EnableSTPInfo,
  EnableSTP,
  Notice,
  Test,
  FreeTrial,
  ReferFriends,
  OnboardingChecklist,
  SwitchToEmployerAccount,
  Roster,
  Timesheet,
  PDFReportModal,
  PriceLockInSummaryModal
}
