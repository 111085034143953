import { useDispatch, useSelector } from 'react-redux'
import * as appSlice from '../../reducers/appSlice'

export function useAlerts() {
  const dispatch = useDispatch()
  const alerts = useSelector((state) => state.app.alerts)

  const setAlerts = (val) => {
    dispatch(appSlice.setAlerts(val))
  }
  const setAlert = (val) => {
    dispatch(appSlice.setAlert(val))
  }

  const hideAlert = async (id) => {
    setAlerts(alerts.filter((alert) => alert.id !== id))
  }

  return { alerts, setAlerts, setAlert, hideAlert }
}
