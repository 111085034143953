angular.module('starter.services').factory('PdfViewerModalService', function (Service) {
  return function (base64, title, filename) {
    Service.modal(
      'pdfViewerModal.html',
      [
        '$scope',
        '$uibModalInstance',
        function ($scope, $uibModalInstance) {
          $scope.cancel = function () {
            $uibModalInstance.dismiss()
          }
          $scope.title = title
          setTimeout(() => {
            PDFObject.embed(base64, '#example1')
          }, 500)

          $scope.documentDownload = () => {
            const linkSource = base64
            const downloadLink = document.createElement('a')
            const fileName = filename + '.pdf'

            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
          }
        }
      ],
      { size: 'xxl' }
    )
  }
})
