import { createSlice, createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

const superSummarySlice = createSlice({
  name: 'superSummary',
  initialState: {
    open: false,
    selectedEmployee: 'all',
    /** @type {'thisMonth'|'lastMonth'|'thisQuarter'|'lastQuarter'|'thisFinancialYear'|'lastFinancialYear'|'custom'} */
    selectedPeriod: 'thisMonth',
    customRangeStart: moment.utc().startOf('month').toDate().getTime(),
    customRangeEnd: moment.utc().endOf('month').toDate().getTime(),
    step: 'select' /* @type {'select' | 'view'} */
  },
  reducers: {
    setPeriod(state, action) {
      state.selectedPeriod = action.payload || 'thisMonth'
    },
    setEmployee(state, action) {
      state.selectedEmployee = action.payload || 'all'
    },
    setDateRange(state, action) {
      state.selectedPeriod = 'custom'
      // NO-15263 Change to ISOString to serialise time
      state.customRangeStart = moment(action.payload.start).toISOString()
      state.customRangeEnd = moment(action.payload.end).toISOString()
    },
    close(state, _action) {
      state.open = false
    },
    open(state, _action) {
      state.open = true
      // reset states
      state.step = 'select'
      state.selectedEmployee = 'all'
      state.selectedPeriod = 'thisMonth'
      state.customRangeStart = moment.utc().startOf('month').toDate().getTime()
      state.customRangeEnd = moment.utc().endOf('month').toDate().getTime()
    },
    init(state, _action) {
      state.selectedEmployee = 'all'
      state.selectedPeriod = 'thisMonth'
      state.customRangeStart = moment.utc().startOf('month').toDate().getTime()
      state.customRangeEnd = moment.utc().endOf('month').toDate().getTime()
    },
    view(state, _action) {
      state.step = 'view'
    }
  }
})

function getDateRange(state) {
  let startDate, endDate
  switch (state.selectedPeriod) {
    case 'thisMonth':
      startDate = moment.utc().startOf('month').toDate()
      endDate = moment.utc().endOf('month').toDate()
      break
    case 'lastMonth':
      startDate = moment.utc().subtract(1, 'months').startOf('month').toDate()
      endDate = moment.utc().subtract(1, 'months').endOf('month').toDate()
      break
    case 'thisQuarter':
      startDate = moment.utc().startOf('quarter').toDate()
      endDate = moment.utc().endOf('quarter').toDate()
      break
    case 'lastQuarter':
      startDate = moment.utc().subtract(3, 'months').startOf('quarter').toDate()
      endDate = moment.utc().subtract(3, 'months').endOf('quarter').toDate()
      break
    case 'thisFinancialYear':
      if (new Date() < new Date(new Date().getFullYear(), 6, 1)) {
        startDate = moment.utc().startOf('year').subtract(6, 'months').toDate()
        endDate = moment.utc().endOf('year').subtract(6, 'months').toDate()
      } else {
        startDate = moment.utc().startOf('year').add(6, 'months').toDate()
        endDate = moment.utc().endOf('year').add(6, 'months').toDate()
      }

      break
    case 'lastFinancialYear':
      if (new Date() < new Date(new Date().getFullYear(), 6, 1)) {
        startDate = moment.utc().startOf('year').subtract(18, 'months').toDate()
        endDate = moment.utc().endOf('year').subtract(18, 'months').toDate()
      } else {
        startDate = moment.utc().startOf('year').subtract(6, 'months').toDate()
        endDate = moment.utc().endOf('year').subtract(6, 'months').toDate()
      }

      break
    case 'custom':
      startDate = new Date(state.customRangeStart)
      endDate = new Date(state.customRangeEnd)
      break
    default:
      break
  }

  return {
    startDate,
    endDate
  }
}

export const selectSuperSummary = createSelector(
  (state) => state.superSummary,
  (summary) => {
    let { startDate, endDate } = getDateRange(summary)
    return {
      startDate,
      endDate,
      selectedEmployee: summary.selectedEmployee
    }
  }
)

export default superSummarySlice.reducer
export const { close, open, init, setPeriod, setEmployee, view, setDateRange } = superSummarySlice.actions
