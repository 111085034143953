import { setAlert } from './reducers/appSlice'
import moment from 'moment'
import { Capacitor } from '@capacitor/core'
import { setMyEmployees, setStpTransitionCompany } from './reducers/stpTransitionSlice'
import { getInitial } from './scheduling/react-subscription/utils/share'
import { isABN, TFNCheck, rounding } from '@payroller/pds'
import _ from 'lodash'

export function r(val, digits = 2) {
  if (!val) return 0
  return rounding(val, digits)
}

export function r2(val) {
  if (!val) return 0
  return rounding(val, 2)
}

export function r5(val) {
  if (!val) return 0
  return rounding(val, 5)
}

export function r2Add(val1, val2) {
  return r2(r2(val1) + r2(val2))
}

export function calculateOrdinaryWithoutLeave(empYtd) {
  return r2(
    r2(empYtd.ordinary) -
      r2(empYtd.paidLeaveObj?.annualLeave) -
      r2(empYtd.paidLeaveObj?.sickLeave) -
      r2(empYtd.paidLeaveObj?.longServiceLeave)
  )
}

export function getFinancialYear(theDate = new Date(), indexMonth = 7) {
  if (typeof theDate === 'string' || theDate instanceof String) {
    theDate = new Date(theDate)
  } else if (moment.isMoment(theDate)) {
    theDate = theDate.toDate()
  }

  let year = theDate.getFullYear()
  let month = theDate.getMonth() + 1

  if (month < indexMonth) {
    return year
  } else {
    return year + 1
  }
}
export function financialYearToSuperRate(fy) {
  if (!fy) {
    fy = getFinancialYear(new Date())
  }
  switch (fy) {
    case 2010:
    case 2011:
    case 2012:
    case 2013:
      return 0.09
    case 2014:
      return 0.0925
    case 2015:
    case 2016:
    case 2017:
    case 2018:
    case 2019:
    case 2020:
    case 2021:
      return 0.095
    case 2022:
      return 0.1
    case 2023:
      return 0.105
    case 2024:
      return 0.11
    case 2025:
      return 0.115
    case 2026:
    case 2027:
    case 2028:
    case 2029:
    case 2030:
      return 0.12
    default:
      throw new Error(`financial year ${fy} outside range 2010 - 2030`)
  }
}

export function CSVToArray(strData, strDelimiter) {
  strDelimiter = strDelimiter || ','
  var objPattern = new RegExp(
    '(\\' + strDelimiter + '|\\r?\\n|\\r|^)' + '(?:"([^"]*(?:""[^"]*)*)"|' + '([^"\\' + strDelimiter + '\\r\\n]*))',
    'gi'
  )
  var arrData = [[]]
  var arrMatches = null
  while ((arrMatches = objPattern.exec(strData))) {
    var strMatchedDelimiter = arrMatches[1]
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([])
    }
    var strMatchedValue
    if (arrMatches[2]) {
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
    } else {
      strMatchedValue = arrMatches[3]
    }
    arrData[arrData.length - 1].push(strMatchedValue)
  }
  return arrData
}

export function ngGetInitialData({
  $ngRedux,
  $rootScope,
  Service,
  $window,
  $state,
  Analytics,
  HttpPromiseAndLocalStorage,
  successCallback
}) {
  return () => {
    return $ngRedux
      .dispatch(getInitial({ HttpPromise: HttpPromiseAndLocalStorage, userType: $window.localStorage.userType }))
      .unwrap()
      .then(async (res) => {
        $rootScope.user = res.user

        /**
         * This will ensure userType is added in storage
         */
        if (res?.user?.userType) localStorage.setItem('userType', res.user.userType)

        // $rootScope.company = {};
        // sendSuperForm will be replaced backend data (res)
        const features = { sendSuperForm: false, ...res.company.features }
        const company = Service.sanitizeObj({ ...res.company, features })
        // save data in localstorage
        localStorage.setItem('pr_company', JSON.stringify(company))
        $rootScope.company = company
        $rootScope.company.tz ||= 'Australia/Sydney'
        $rootScope.company.features ||= {} // sometimes .features is undefined
        $rootScope.employees = Service.sanitizeObj(res.employees)
        $rootScope.payRuns = res.payRuns
        $rootScope.leaves = res.leaves
        $rootScope.holidays = res.holidays
        $rootScope.finalAdjustments = res.finalAdjustments
        $rootScope.pendingLeave = false
        $rootScope.pendingLeaveList = []
        if (res?.employeeData) $rootScope.employeeData = res?.employeeData
        if ($rootScope.company.features.beta2020 && !$state.current.url.startsWith('/payroll')) {
          $ngRedux.dispatch(setStpTransitionCompany($rootScope.company))
          // $ngRedux.dispatch(
          $ngRedux.dispatch(
            setMyEmployees(
              res.employees.filter((emp) => {
                return emp.s !== 'i' && emp.s !== 'd'
              })
            )
          )
        } else {
          $ngRedux.dispatch(setMyEmployees([]))
        }
        window.Intercom('boot', {
          app_id: 'z7jnrfnh',
          user_id: res.user._id,
          name: `${res.user.f} ${res.user.l}`,
          email: res.user.e,
          created_at: new Date(res.user.at).getTime(),
          frontend_version: APP_VERSION
        })

        const intercomLauncher = document.querySelector('.intercom-lightweight-app-launcher')
        if (intercomLauncher && process.env.APP === 'people') {
          intercomLauncher.classList.add('intercom-messenger-launcher')
        }

        // leaves (Payroller Employee)
        let employeesObj = {}
        if (
          window.location.pathname.includes('/business/settings') &&
          window.location.search.includes('?open=open%3DclientSubscribe')
        ) {
          $window.localStorage.url_client_pay = window.location.search
        }
        for (let index = 0; index < $rootScope.employees.length; index++) {
          let x = $rootScope.employees[index]
          employeesObj[x._id] = x
        }

        for (let i = 0; i < res.leaves.length; i++) {
          if (res.leaves[i].s === 'p') {
            $rootScope.pendingLeave = true
            let leaveObj = res.leaves[i]
            leaveObj.employeeData = employeesObj[leaveObj.ei] || {}

            $rootScope.pendingLeaveList.push(leaveObj)
          }
        }

        // if (!$rootScope.company.abn && !$rootScope.company.wpn) {
        //   $scope.openGetStartedModal()
        // }
        if (!$rootScope.isAgent || $window.localStorage.pr_agent_id === $rootScope.company._id) {
          if ($window.localStorage.pr_agent_id !== $rootScope.company._id) {
            let activeEmployeeNumber = 0
            $rootScope.employees.map((eachEmployee) => {
              if (eachEmployee.s === 'a') {
                activeEmployeeNumber++
              }
            })
            Analytics.setUserId(res.user._id)
            Analytics.setProperties(
              {
                email: res.user.e,
                first_name: res.user.f,
                last_name: res.user.l,
                user_type: res.user.userType || '',
                company: res.company.c,
                company_id: res.company._id,
                account_created_time: res.user.at,
                is_agent: 'false',
                user_status: res.user.lv === 'm' ? 'Member' : 'Admin',
                ...(res.user.q1 === 'e' && res.user.q2 && { num_of_total_employees: res.user.q2 }),
                num_of_active_employees: activeEmployeeNumber,
                default_pay_period:
                  res.company.pp === 'w'
                    ? 'Weekly'
                    : res.company.pp === 'f'
                    ? 'Fortnightly'
                    : res.company.pp === 'm'
                    ? 'Monthly'
                    : res.company.pp === 'q'
                    ? 'Quarterly'
                    : 'Not set',
                stp_enabled: (!!(res.company.stp && res.company.stp.on)).toString(),
                use_aba: (!!(res.company.b_b && res.company.b_no && res.company.b_n)).toString(),
                use_long_service_leave: (!!(res.company.le && res.company.le.lsl)).toString(),
                xero_connected: (!!(
                  res.company.xero &&
                  res.company.xero.tokenSet &&
                  res.company.xero.tokenSet.access_token
                )).toString(),
                beam_connected: (!!(res.company.superStream && res.company.superStream.connectedDate)).toString()
              },
              res.user._id
            )
          }
        }
      })
      .then(successCallback)
      .catch(() => {
        $rootScope.user = {}
        $rootScope.company = {}
        $rootScope.employees = []
        $rootScope.payRuns = []
        $rootScope.leaves = []
        $rootScope.holidays = []
        $rootScope.finalAdjustments = []
      })
  }
}

angular.module('starter.services', []).factory(
  'Service',
  function (
    $q,
    $timeout,
    $rootScope,
    cfpLoadingBar,
    ngDialog,
    $uibModal,
    // $mdDialog
    $ngConfirm,
    $sanitize,
    GLOBALS,
    $ngRedux
  ) {
    var findIndexByKeyValue = function (arraytosearch, key, valuetosearch) {
      for (var i = 0; i < arraytosearch.length; i++) {
        if (arraytosearch[i][key] == valuetosearch) {
          return i
        }
      }
      return null
    }

    var findObjByKeyValue = function (arraytosearch, key, valuetosearch) {
      for (var i = 0; i < arraytosearch.length; i++) {
        if (arraytosearch[i][key] == valuetosearch) {
          return arraytosearch[i]
        }
      }
      return null
    }

    var modal = function (fileName, ctrlFunction, option, templateUrl = '') {
      // option = {
      //   size: 'lg','md',sm'
      //   backdrop: Boolean
      // }
      if (ctrlFunction) {
        var controller = ctrlFunction
      } else {
        // eslint-disable-next-line
        var controller = [
          '$scope',
          '$uibModalInstance',
          function ($scope, $uibModalInstance) {
            $scope.cancel = function () {
              $uibModalInstance.dismiss()
            }
          }
        ]
      }
      $uibModal.open({
        animation: !GLOBALS.noAnimation,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: templateUrl || './views/modals/' + fileName,
        size: option && option.size ? option.size : 'md',
        backdrop: option && option.backdrop ? 'static' : null,
        controller: controller,
        windowClass: Capacitor.isNativePlatform() ? 'modal-capacitor' : null
      })
    }

    var alert = function (title, msg, option, version = 'v1') {
      if (!option) option = 'success'

      // An alert dialog
      if (!title) {
        title = 'Message'
        option = 'error'
      }
      if (!msg) {
        msg = 'Sorry, there was a problem processing your request. Please try again.'
        option = 'error'
      }

      $ngRedux.dispatch(setAlert({ title, message: msg, kind: option, version }))
    }
    var showAlert = function ({ title, message, option, version = 'v2', ...rest }) {
      if (!title && !message) return false
      $ngRedux.dispatch(setAlert({ title, message, kind: option, version, ...rest }))
    }
    var confirm = function (option) {
      // option = {
      //   title :
      //   contents :
      //   confirmText :
      //   cancelText :
      //   confirmCallback : function,
      //   cancelCallback : function,
      // }

      var options = {
        title: option.title,
        content: option.contents,
        // contentUrl: "template.html", // if contentUrl is provided, 'content' is ignored.
        // scope: $scope,
        buttons: {
          // long hand button definition
          ok: {
            text: option.confirmText,
            btnClass: 'new-confirm-btn modal-confirm-button',
            keys: ['enter'], // will trigger when enter is pressed
            action: option.confirmCallback ? option.confirmCallback : () => {}
          },
          // short hand button definition
          close: {
            text: option.cancelText ?? 'Cancel',
            btnClass: 'new-default-btn  modal-cancel-button',
            action: option.cancelCallback ? option.cancelCallback : () => {}
          }
        }
      }

      if (option.secondText) {
        options.buttons.secondButton = {
          text: option.secondText,
          btnClass: 'new-default-btn',
          keys: ['enter'], // will trigger when enter is pressed
          action: option.secondCallback ? option.secondCallback : () => {}
        }
      }

      if (option.removeCancel) {
        delete options.buttons.close
      }
      $ngConfirm(options)
    }

    function isJSON(something) {
      if (typeof something !== 'string') {
        something = JSON.stringify(something)
      }

      try {
        JSON.parse(something)
        return true
      } catch (e) {
        return false
      }
    }

    var infoPopup = function (option) {
      // option = {
      //   title :
      //   contents :
      //   okText :
      //   cancelText :
      //   okCallback : function,
      // }

      // var deferred = $q.defer();
      $ngConfirm({
        title: option.title,
        content: option.contents,
        // contentUrl: "template.html", // if contentUrl is provided, 'content' is ignored.
        // scope: $scope,
        buttons: {
          // long hand button definition
          // short hand button definition
          close: option.okCallback ? option.okCallback : () => {}
        }
      })
    }

    var imageResize = function (src) {
      var deferred = $q.defer()
      var MAX_HEIGHT = 500
      var image = new Image()
      image.onload = function () {
        var canvas = document.createElement('canvas')
        if (image.height > MAX_HEIGHT) {
          image.width *= MAX_HEIGHT / image.height
          image.height = MAX_HEIGHT
        }
        var ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        canvas.width = image.width
        canvas.height = image.height
        ctx.drawImage(image, 0, 0, image.width, image.height)

        var dataURL = canvas.toDataURL('image/png')
        var blob = dataURItoBlob(dataURL)
        deferred.resolve(blob)
      }
      image.onerror = function () {
        deferred.resolve(false)
      }
      image.src = src

      return deferred.promise
    }

    var showLoading = function (message) {
      cfpLoadingBar.start()
    }

    var hideLoading = function () {
      cfpLoadingBar.complete()
      document.querySelector('.g-login.loading')?.classList.remove('loading') // hide loading of the GoogleLoginButton
    }

    var dialog = function (message, status) {
      if (status == 'alert') {
        $('#simple-popup').css('background-color', '#F53D3D')
      } else {
        $('#simple-popup').css('background-color', '#86B322')
      }
      $('#simple-popup')
        .html(message)
        .fadeIn(300, function () {
          $timeout(function () {
            $('#simple-popup').fadeOut(300)
          }, 3500)
        })
      // $('#simple-popup').removeClass('fadeOutRight').addClass('fadeInLeft').html(message).show(0,function(){
      //   $timeout(function () {
      //     $('#simple-popup').removeClass('fadeInLeft').addClass('fadeOutRight').show();
      //   }, 3500);
      // })
    }

    // functions
    function dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1])
      } else {
        byteString = unescape(dataURI.split(',')[1])
      }

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    }

    function dataURItoBase64(file) {
      var deferred = $q.defer()
      var reader = new FileReader()
      reader.onloadend = function (evt) {
        deferred.resolve(evt.target.result)
      }
      reader.onerror = function () {
        deferred.reject('Greeting ' + name + ' is not allowed.')
      }
      reader.readAsDataURL(file)
      return deferred.promise
    }

    function capitalFirstString(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    function mergeObj(obj1, obj2) {
      var obj3 = {}
      for (var attrname1 in obj1) {
        obj3[attrname1] = obj1[attrname1]
      }
      for (var attrname2 in obj2) {
        obj3[attrname2] = obj2[attrname2]
      }
      return obj3
    }

    function validateEmail(email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    }

    function sleep(milliseconds) {
      var start = new Date().getTime()
      for (var i = 0; i < 1e7; i++) {
        if (new Date().getTime() - start > milliseconds) {
          break
        }
      }
    }

    function getUTCDate(date) {
      var today = date ? new Date(date) : new Date()
      if (isNaN(today)) {
        today = new Date()
      }
      var dd = today.getDate()
      var mm = today.getMonth()
      var yyyy = today.getFullYear()
      return new Date(Date.UTC(yyyy, mm, dd))
    }

    function getSSID(softwareId) {
      const ssid_db = softwareId.toString()

      var sumOfSsid = 0
      for (var i = 0; i < ssid_db.length; i += 1) {
        sumOfSsid += parseInt(ssid_db[i])
      }
      var numOfZero = 9 - ssid_db.length

      var ssid = ''
      for (let index = 0; index < numOfZero; index++) {
        ssid += '0'
      }
      ssid += ssid_db + (sumOfSsid % 10).toString()
      return ssid
    }

    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace)
    }

    function sanitizeObj(object) {
      if (typeof object === 'string') {
        object = $sanitize(object)
        object = replaceAll(object.toString(), '&amp;', '&')
      } else {
        Object.keys(object).forEach(function (k) {
          if (object[k] && typeof object[k] === 'object') {
            object[k] = sanitizeObj(object[k])
          }
          if (typeof object[k] === 'string') {
            object[k] = $sanitize(object[k])
            object[k] = _.unescape(object[k].toString(), { level: 'html5' })
          }
        })
      }

      return object
    }

    const addEPS = (x) => {
      return Math.round(((x || 0) + (x < 0 ? -1e-10 : +1e-10)) * 1e9) / 1e9
    }

    function currencyRounding(value) {
      return Math.round(Math.round(value * 1000) / 10) / 100
    }

    function camelToSentence(text) {
      var result = (text || '').replace(/([A-Z])/g, ' $1')
      var finalResult = result.replace(/A B N/g, 'ABN').replace(/I D/g, 'ID')
      return finalResult
    }

    function startWithJKStartFinishTier(value) {
      return (
        value &&
        (value.startsWith('JOBKEEPER-START') ||
          value.startsWith('JOBKEEPER-FINISH') ||
          value.startsWith('JK-ST-FN') ||
          value.startsWith('JK-FI-FN') ||
          value.startsWith('JK-TIER'))
      )
    }

    let getObjectId = () => {
      let radix = 16
      let toHex = (val) => Math.floor(val).toString(radix)
      let a = toHex(Date.now() / 1000)
      let b = Array(radix + 1)
        .join(' ')
        .replace(/./g, () => toHex(Math.random() * radix))
      return a + b
    }

    let round2 = (x) => {
      return rounding(x)
    }
    let round5 = (x) => {
      return rounding(x, 5)
    }

    /**
     * @param  {object} options
     * @param  {string} url
     * @param  {string} outputFormat
     */
    const convertImageURLToBase64 = (options) => {
      return new Promise((resolve, reject) => {
        var img = new Image()
        img.crossOrigin = 'Anonymous'
        img.onload = function () {
          var canvas = document.createElement('CANVAS')
          var ctx = canvas.getContext('2d')
          var dataURL
          canvas.height = this.height
          canvas.width = this.width
          ctx.drawImage(this, 0, 0)
          dataURL = canvas.toDataURL(options.outputFormat)
          resolve(dataURL)
          canvas = null
        }
        img.onerror = function () {
          // eslint-disable-next-line
          reject()
        }
        img.src = options.url
      })
    }

    // const getPayrunStpEndpoint = (payrunId) => {
    //   return 2020
    // }

    return {
      findIndexByKeyValue: findIndexByKeyValue,
      findObjByKeyValue: findObjByKeyValue,
      alert: alert,
      showAlert,
      confirm: confirm,
      infoPopup: infoPopup,
      dialog: dialog,
      modal: modal,
      isJSON: isJSON,
      imageResize: imageResize,
      showLoading: showLoading,
      hideLoading: hideLoading,
      dataURItoBlob: dataURItoBlob,
      dataURItoBase64: dataURItoBase64,
      capitalFirstString: capitalFirstString,
      mergeObj: mergeObj,
      validateEmail: validateEmail,
      CSVToArray: CSVToArray,
      TFNCheck: TFNCheck,
      isABN: isABN,
      sleep: sleep,
      getUTCDate: getUTCDate,
      getSSID: getSSID,
      sanitizeObj: sanitizeObj,
      rounding: rounding,
      r: r,
      r2: r2,
      r5: r5,
      getFinancialYear: getFinancialYear,
      financialYearToSuperRate,
      currencyRounding: currencyRounding,
      camelToSentence: camelToSentence,
      startWithJKStartFinishTier: startWithJKStartFinishTier,
      getObjectId,
      round2,
      round5,
      convertImageURLToBase64
    }
  }
)
