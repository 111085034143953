const { setPayRuns } = require('../reducers/appSlice')

angular.module('starter.services').factory('PayRunAction', function ($rootScope, Service, $ngRedux) {
  return {
    add: function (newPayRun) {
      var payRuns_temp = angular.copy($rootScope.payRuns || [])
      payRuns_temp.push(newPayRun)
      // resort
      payRuns_temp.sort((obj1, obj2) => (new Date(obj1.ed) > new Date(obj2.ed) ? -1 : 1))
      $rootScope.payRuns = payRuns_temp
      $ngRedux.dispatch(setPayRuns(payRuns_temp))
    },
    update: function (newPayRun) {
      var payRuns_temp = angular.copy($rootScope.payRuns)

      for (var index = 0; index < payRuns_temp.length; index++) {
        const element = payRuns_temp[index]
        if (element._id === newPayRun._id) {
          payRuns_temp[index] = newPayRun
        }
      }

      // resort
      payRuns_temp.sort((obj1, obj2) => (new Date(obj1.ed) > new Date(obj2.ed) ? -1 : 1))
      $rootScope.payRuns = payRuns_temp
      $ngRedux.dispatch(setPayRuns(payRuns_temp))
    },
    delete: function (payRunId) {
      var index = Service.findIndexByKeyValue($rootScope.payRuns, '_id', payRunId)
      $rootScope.payRuns.splice(index, 1)
      $ngRedux.dispatch(setPayRuns($rootScope.payRuns))
    }
  }
})
