angular
  .module('starter.directives', [])
  // .directive('updateStatus', function($timeout) {
  //     return function(scope, elem, attrs) {
  //         scope.$on('event:updateStatus', function(obj) {
  //           if(obj.status==1){
  //             $('.inv-status').html('Sent');
  //             $('.inv-status').removeClass('stat-s-0').removeClass('stat-s-'+status);
  //           }
  //         });
  //
  //
  //     };
  // })
  .directive('hideInitial', function ($timeout) {
    return function (scope, elem, attrs) {
      $timeout(function () {
        elem.css('display', 'block')
        // $('hide-initial').show();
      }, 2000)
    }
  })
