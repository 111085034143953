import React from 'react'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { growthbook } from './growthbook'
import { useGrowthBookFeatures } from './useGrowthBookFeatures'
import { useAuth } from '/scheduling/hooks/useAuth'

export const PayrollerGrowthBookProvider = ({ children }) => {
  const { ready } = useAuth()
  useGrowthBookFeatures()
  if (!ready) return null
  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}
