import { setMyEmployees } from '/reducers/stpTransitionSlice'

angular
  .module('starter.agent.controllers')
  .controller(
    'agentBulkRunSelectStpCtrl',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      Http,
      $window,
      Service,
      STPService,
      $filter,
      Analytics,
      $ngRedux
    ) {
      // ready to run
      if (!$rootScope.clients || $rootScope.clients[0] == 'no data') {
        $state.go('agent.clients')
        return false
      }

      $scope.pageLoading = true
      var bulkStpList = []
      $rootScope.clients.map((eachClient) => {
        // stp on 안되어 있으면 안되게
        // stp payrun이 없으면 실행안되게

        if (
          eachClient._id !== eachClient.ai ||
          !$rootScope.agent.agd ||
          !$rootScope.agent.agd.ma_h ||
          ($rootScope.agent.agd.ma_h == 2 && $rootScope.agentUser._id === $rootScope.agent._id)
        ) {
          var eachClient_temp = angular.copy(eachClient)
          if (
            !eachClient.stp ||
            !eachClient.stp.on ||
            !eachClient.payRun[0] ||
            eachClient.payRun[0].ed > new Date() ||
            eachClient.payRun[0].s == 's' ||
            eachClient.payRun[0].s == 'sp' ||
            eachClient.payRun[0].s == 'd'
          ) {
            eachClient_temp.disabled = true
          }

          bulkStpList.push(eachClient_temp)
        }
      })

      $scope.today = new Date()
      $scope.bulkStpList = bulkStpList

      const getPayrunEmployees = (client) => {
        const payrunEmployeeIds = client.payRun.length
          ? new Set(
              client.payRun[0].psl.map((payslip) => {
                return payslip.ei
              })
            )
          : new Set([])
        return ($scope.stpCompanyToEmployees[client._id] || []).filter((emp) => {
          return payrunEmployeeIds.has(emp._id)
        })
      }

      const isPhaseTwoClient = (client) => {
        const payrunEmployees = getPayrunEmployees(client)
        return !payrunEmployees.some((emp) => {
          return !emp.tx.stp2020
        })
      }

      $scope.openSTPModal = (client) => {
        const payrunEmployees = getPayrunEmployees(client)
        $ngRedux.dispatch(setMyEmployees(payrunEmployees))
      }

      Http(
        {
          method: 'post',
          api: '/v1/employees-for-bulk/',
          data: {
            companyIdArray: $rootScope.clients.map((client) => {
              return { ci: client._id }
            })
          }
        },
        (res) => {
          $scope.stpCompanyToEmployees = {}
          res.employees.forEach((emp) => {
            if (!$scope.stpCompanyToEmployees[emp.ci]) {
              $scope.stpCompanyToEmployees[emp.ci] = []
            }
            $scope.stpCompanyToEmployees[emp.ci].push(emp)
          })
          $scope.clientStpPhases = {}
          $rootScope.clients.forEach((client) => {
            $scope.clientStpPhases[client._id] = isPhaseTwoClient(client)
          })
          $scope.pageLoading = false
        },
        (_) => {
          $state.go('agent.clients')
          return false
        }
      )

      $rootScope.stpTransitionState = {
        callback: (companyId, newEmployees) => {
          $scope.stpCompanyToEmployees[companyId] = newEmployees
          $scope.clientStpPhases[companyId] = true
        }
      }

      // make run list
      $scope.selectAll_stp = (checkedAll) => {
        $scope.bulkStpList.map((eachClient, index) => {
          if (!eachClient.disabled) {
            $scope.bulkStpList[index].checked = checkedAll
          }
        })
      }

      $scope.validation = () => {
        var serverDownStart = new Date('2019-12-23T12:50:00.004Z')
        var serverDownEnd = new Date('2020-01-01T19:00:00.004Z')

        if (serverDownStart < new Date() && new Date() < serverDownEnd) {
          alert(
            "The ATO's server will be down for maintenance from 24 December to the 2 January. \n\nThere will be no penalties for late or missed reports during this time."
          )
          return false
        }
        // validate checked employees
        // console.log($ngRedux.getState().subscription)
        var bulkStpList = []
        var companyIdArray = []

        var noClients = true
        $scope.bulkStpList.map((eachClient, index) => {
          if (eachClient.checked) {
            bulkStpList.push(eachClient)
            noClients = false
            companyIdArray.push({ ci: eachClient._id })
          }
        })
        const clientNoSubscription = bulkStpList.filter(
          (_client) => _client.subscriptionStatus.webapp.isValid === false
        )
        if (clientNoSubscription.length > 0 && clientNoSubscription.length <= bulkStpList.length) {
          STPService.declarationModal('', 'subscribe', undefined, {
            clientNoSubscription: clientNoSubscription,
            bulkStpList: bulkStpList
          })
          return
        }
        if (noClients) {
          Service.alert('Alert', 'You need to add at least one client!', 'error')
        } else {
          Analytics.logEvent('Agent Bulk STP Started')
          $scope.loading = true
          $scope.loading = false

          var STPValidationError = []
          var hasError = false

          $scope.bulkStpList.map((eachClient, index) => {
            if (eachClient.checked) {
              STPService.verify(
                {
                  payRun: eachClient.payRun[0],
                  company: eachClient,
                  employees: companyIdArray.reduce((employees, companyObj) => {
                    const filteredCompanyId = companyObj.ci
                    const filteredCompanyEmployees = $scope.stpCompanyToEmployees[filteredCompanyId]
                    return [...employees, ...filteredCompanyEmployees]
                  }, []),
                  bulkSTP: true
                },
                (STPError) => {
                  STPError.client = eachClient
                  STPValidationError.push(STPError)
                }
              )
            }

            STPValidationError.map((eachError) => {
              if (
                eachError.generalErrorText ||
                eachError.companyErrors.length > 0 ||
                eachError.employeeErrors.length > 0
              ) {
                hasError = true
              }
            })
          })

          if (hasError) {
            // popup을 연다
            Service.modal(
              'agent/bulkSTPErrorMessageModal.html',
              [
                '$scope',
                '$uibModalInstance',
                function ($scope, $uibModalInstance) {
                  $scope.STPValidationError = STPValidationError
                  $scope.cancel = function () {
                    $uibModalInstance.dismiss()
                  }
                }
              ],
              { size: 'md' }
            )
          } else {
            // error 가 없으면 declaration modal
            STPService.declarationModal('', 'bulkAgent', (signatoryName) => {
              var httpSubmitStp = (eachClient, index) => {
                $window.localStorage.pr_client_id = eachClient._id
                Http(
                  {
                    method: 'post',
                    api: '/v1/submit-stp/' + eachClient.payRun[0]._id,
                    data: {
                      signatoryName: signatoryName,
                      signatoryDate: $filter('date')(new Date(), 'yyyy-MM-dd')
                    },
                    disableErrorMsg: true
                  },
                  (res) => {
                    // 저장된 employee data 를 update 한다.
                    $scope.bulkStpList[index].payRun[0] = res.payRun
                    var index_clients = Service.findIndexByKeyValue($rootScope.clients, '_id', eachClient._id)
                    $rootScope.clients[index_clients].payRun[0] = res.payRun
                    Analytics.logEvent('Agent Bulk STP Success')
                  },
                  (err) => {
                    $scope.loading = false
                    $scope.success = false

                    var errorTitle = 'Error'
                    var alertType = 'error'

                    if (err && err.code) {
                      switch (err.code) {
                        case 'inProgress':
                          alertType = 'warning'
                          errorTitle = 'In progress'
                          break
                        case 'alreadyCompleted':
                          alertType = 'success'
                          errorTitle = 'Completed'
                          $scope.bulkStpList[index].payRun[0] = res.payRun
                          break
                      }
                    }

                    Service.alert(
                      eachClient.c,
                      err && err.message ? err.message : 'STP submission is not successful.',
                      alertType
                    )
                    $scope.errorMessage = err.message
                  }
                )
              }

              $scope.bulkStpList.map((eachClient, index) => {
                if (eachClient.checked) {
                  httpSubmitStp(eachClient, index)
                  Service.sleep(200)
                }
              })
            })
          }
        }
        // get all employee data.
      }

      $scope.runBulkStp = () => {
        var bulkStpList = []

        var noClients = true
        $scope.bulkStpList.map((eachClient, index) => {
          if (eachClient.checked) {
            bulkStpList.push(eachClient)
            noClients = false

            // reset checked status for next bulk run
            $scope.bulkStpList[index].checked = false
          }
        })

        if (noClients) {
          Service.alert('Alert', 'You need to add at least one client!', 'error')
        } else {
          // get all employee data from server
          // verify them all
          // open declaration modal
        }
      }
    }
  )
