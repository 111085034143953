import { useFeature } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { useModal } from '../hooks'
import { isDebug, isGuiDebug } from '../react-subscription/utils/share'
import { cx } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { GB_FEATURES } from '../../controllers/consts'
import { setIsTsrOnly as setAppIsTsrOnly } from '../../reducers/appSlice'
import isPrerenderForV2 from '../constant/isPrerenderForV2'
const DebugPanelModule = React.lazy(() => import('@payroller/pds').then((module) => ({ default: module.DebugPanel })))

if (!isPrerenderForV2) {
  // Add localStorage.setItem listener before init. (it catches all localStorage data changes)
  const originalSetItem = localStorage.setItem

  localStorage.setItem = function (key, value) {
    const event = new Event('itemInserted')
    event.value = value // Optional..
    event.key = key // Optional..

    originalSetItem.apply(this, arguments) // setItem first
    document.dispatchEvent(event) // then consume localStorage after as states
  }
}

if (!isPrerenderForV2) {
  // Init the GA
  window.dataLayer = window.dataLayer || []
}
// use this gtag() instead of window.gtag()
export function gtag() {
  if (!isPrerenderForV2) {
    window.dataLayer.push(arguments)
  }
}
gtag('js', new Date())

/**
 * Dynamically config GA with when "user_id" changes in localStorage
 */
export function GaInit() {
  const userId = useStorageWatcher('pr_user')
  useEffect(() => {
    // Let GTM handle the config setup
    window.dataLayer.push({ userId: userId })
  }, [userId])

  const showDebugPanel = useDebugShortcut({ isDebug, isGuiDebug })
  useTsrViewShortcut()
  if (showDebugPanel) return <DebugPanel userId={userId} />

  return null
}

function DebugPanel({ userId }) {
  const { setModals } = useModal()
  const company = JSON.parse(localStorage.getItem('pr_company'))
  const { loaded } = useSelector((state) => state.app.gbFeatures)

  return (
    <DebugPanelModule>
      P_NODE_ENV: {process.env.P_NODE_ENV}
      <br />
      APP_VERSION: {APP_VERSION}
      <br />
      <br />
      user_id ({userId})
      <br />
      pr_company ({company?.e} / {company?.c})
      <br />
      <br />
      <b>GrowthBook Features</b>
      <br />
      {!loaded && <div>Loading features...</div>}
      {loaded && (
        <div className="flex gap-1 flex-wrap">
          {GB_FEATURES.map((name) => (
            <GbFeatureItem key={name} name={name} />
          ))}
        </div>
      )}
      <br />
      <button
        className="cursor-pointer"
        onClick={() => {
          const modal = prompt('Please enter the modal name', 'Test')
          if (modal) setModals([{ kind: modal, options: { noAnimate: true } }])
        }}
      >
        Open Modal
      </button>
    </DebugPanelModule>
  )
}

function GbFeatureItem({ name }) {
  const { value, source } = useFeature(name)

  return (
    <div className={cx(value ? 'bg-green-300' : 'bg-gray-300', 'text-xs flex flex-col p-1 rounded-sm')}>
      {name}
      <span className="text-[8px]">({source})</span>
    </div>
  )
}

export function useStorageWatcher(key) {
  const [val, setVal] = useState(localStorage.getItem(key))

  useEffect(() => {
    const handler = () => val !== localStorage.getItem(key) && setVal(localStorage.getItem(key))

    document.addEventListener('itemInserted', handler, false)
    return () => document.removeEventListener('itemInserted', handler)
  }, [])

  useEffect(() => setVal(localStorage.getItem(key)), [localStorage.getItem(key)])

  return val
}

export function useDebugShortcut({ isDebug, isGuiDebug }) {
  const [showDebugPanel, setshowDebugPanel] = useState(isGuiDebug) // show DebugPanel at first when the isGuiDebug is true.

  const toggle = (e) => {
    // only enable this toggle feature at isDebug (Debug mode)
    if (isDebug && e.altKey && e.shiftKey && e.code === 'KeyD') setshowDebugPanel(!showDebugPanel)
  }
  useEffect(() => {
    document.addEventListener('keypress', toggle)
    return () => document.removeEventListener('keypress', toggle)
  }, [showDebugPanel])

  return showDebugPanel
}

export function useTsrViewShortcut() {
  const dispatch = useDispatch()
  const isTsrOnly = useSelector((state) => state.app.isTsrOnly)
  const setIsTsrOnly = (val) => {
    dispatch(setAppIsTsrOnly(val))
  }

  const toggle = () => {
    if (isTsrOnly) setIsTsrOnly(false) // to show & hide React modules with its custom props
    else setIsTsrOnly(true)
  }

  const keyhandler = (e) => {
    if (isDebug && e.altKey && e.shiftKey && e.code === 'KeyA') {
      e.preventDefault()
      toggle()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', keyhandler)
    return () => document.removeEventListener('keydown', keyhandler)
  }, [isTsrOnly])

  return isTsrOnly
}
