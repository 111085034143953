import { setInitialState } from '/reducers/appSlice'

angular
  .module('starter.agent.controllers')
  .controller('agentBulkRunCtrl', function ($scope, $rootScope, $state, Http, $window, Service, $timeout, $ngRedux) {
    $scope.action = {}

    var bulkRunList
    $scope.action.runBulkPayroll = (bulkRunList_temp) => {
      if (bulkRunList_temp) {
        bulkRunList = bulkRunList_temp
      }

      var nextPayRunClient
      // 1. 아직 실행이 안된 client를 찾는다.
      for (let index = 0; index < bulkRunList.length; index++) {
        if (!bulkRunList[index].done) {
          nextPayRunClient = bulkRunList[index]
          break
        }
      }

      // 2. get whole company data for pay run
      if (nextPayRunClient) {
        $scope.currentPayRunCompany = nextPayRunClient.c
        $window.localStorage.pr_client_id = nextPayRunClient._id

        Http(
          {
            method: 'get',
            api: '/v1/initial/'
          },
          (res) => {
            $scope.loading = false
            $rootScope.user = res.user
            $rootScope.company = res.company
            $rootScope.employees = res.employees
            $rootScope.payRuns = res.payRuns
            $rootScope.leaves = res.leaves
            $rootScope.holidays = res.holidays
            $ngRedux.dispatch(setInitialState(res))
            nextPayRunClient.dateObj.payPeriod = $rootScope.company.pp

            $state.go('agent.bulkRun.payroll', nextPayRunClient.dateObj)
          },
          () => {
            $scope.loading = false
          }
        )
      } else {
        Service.alert('Success!', 'Bulk payroll has been completed!', 'success')

        $timeout(() => {
          $state.go('agent.bulkRun.selectPayroll')
        }, 500)
      }
      // 2. send this data to
    }

    $scope.action.complete = (payRun) => {
      var index = Service.findIndexByKeyValue(bulkRunList, '_id', $rootScope.company._id)
      bulkRunList[index].done = true

      var index2 = Service.findIndexByKeyValue($rootScope.clients, '_id', $rootScope.company._id)
      $rootScope.clients[index2].payRun = [payRun]

      delete $rootScope.user
      delete $rootScope.company
      delete $rootScope.employees
      delete $rootScope.payRuns
      delete $rootScope.leaves
      delete $rootScope.holidays
      // 저장되어 있는 모든 client 자료를 삭제한다.
      $scope.action.runBulkPayroll()
    }
  })
