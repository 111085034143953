import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './prepareHeaders'

const endpoint = process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE

export const paywallApi = createApi({
  reducerPath: 'paywallApi',
  baseQuery: fetchBaseQuery({ baseUrl: endpoint, prepareHeaders }),
  endpoints: (build) => ({
    subscribe: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/v1/subscription/webapp',
        method: 'POST',
        body: patch
      })
    })
  })
})

export const calculatePriceApi = createApi({
  reducerPath: 'calculatePriceApi',
  baseQuery: fetchBaseQuery({ baseUrl: endpoint, prepareHeaders }),
  endpoints: (builder) => ({
    getPricingPlan: builder.query({
      query: ({ seats, category }) => `/v1/subscription/calculatePrice?seats=${seats}&category=${category}`
    })
  })
})

export const faqApi = createApi({
  reducerPath: 'faqApi',
  baseQuery: fetchBaseQuery({ baseUrl: endpoint }),
  endpoints: (builder) => ({
    getFaq: builder.query({
      query: (discountType) => `/faq/${discountType}`
    })
  })
})

export const { useMutation } = paywallApi.endpoints.subscribe
export const { useGetPricingPlanQuery } = calculatePriceApi
export const { useGetFaqQuery } = faqApi
