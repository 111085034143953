angular
  .module('starter.business.controllers')
  .controller(
    'importPeopleCtrl',
    function ($scope, $rootScope, $state, $stateParams, EmployeeAction, Http, $window, Service, Analytics) {
      $scope.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

      $scope.reactState = {
        onSubmitSuccess: (employees) => {
          if (employees) {
            employees.map((employee) => EmployeeAction.add(employee))
            $state.go('business.people')
          }
        }
      }

      $scope.downloadExcelTemplate = () => {
        console.log('Downlad')
        document.getElementById('iframe_for_download').src =
          'https://s3.amazonaws.com/bookipi.com/downloadable-files/employeeImportTemplate-employer-20230310.xlsx'
      }
    }
  )
