angular.module('starter.business.controllers').directive('ngIncludeTemplate', function () {
  return {
    templateUrl: function (elem, attrs) {
      return attrs.ngIncludeTemplate
    },
    restrict: 'A',
    scope: {
      ngIncludeVariables: '&'
    },
    link: function (scope, elem, attrs) {
      let vars = scope.ngIncludeVariables()
      Object.keys(vars).forEach(function (key) {
        scope[key] = vars[key]
      })
    }
  }
})
