import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { stripeElementsSrc } from '../../../../../../constant'
import * as s from './styles'

export const StripeElements = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true)
  const onLoad = () => {
    setLoading(false)
  }

  return (
    <div className="relative">
      <iframe id="stripeId" ref={ref} src={stripeElementsSrc} className={s.stripContainer} onLoad={onLoad} />
      {loading && (
        <div className={s.loadingContainer}>
          <CircularProgress className="!text-primary" />
        </div>
      )}
    </div>
  )
})
