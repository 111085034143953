import { Capacitor } from '@capacitor/core'
import { sleep } from '../scheduling/utils'
import { setInitialState, resetInitialState } from '/src/reducers/appSlice'
import { setModals } from '../reducers/appSlice'
import { STORAGE_NAMES } from '@payroller/pds'
angular
  .module('starter.auth.factory', [])
  .constant('AUTH_EVENTS', {
    notAuthenticated: 'auth-not-authenticated'
  })

  .factory('AuthenticationFactory', function ($window, $rootScope) {
    var auth = {
      isLogged: false,
      isAgent: false,
      isRegistered: false,
      isEmployee: false,
      isEmployer: false,
      check: function () {
        if ($window.localStorage.pr_token && $window.localStorage.pr_user) {
          auth.isLogged = true
        } else {
          auth.isLogged = false
          delete auth.user
        }
        if (!$window.sessionStorage.NonPayment) {
          auth.isRegistered = true
        } else {
          auth.isRegistered = false
        }
        if ($window.localStorage.pr_agent_id) {
          auth.isAgent = true
          $rootScope.isAgent = true
          if ($window.localStorage.pr_agent) {
            $rootScope.agent = JSON.parse($window.localStorage.pr_agent)
          }
        }
        if ($window.localStorage.userType) {
          auth.isEmployee = $window.localStorage.userType === 'employee'
          auth.isEmployer = $window.localStorage.userType === 'employer'
        }
      }
    }

    return auth
  })

  .factory('Logout', function ($window, AuthenticationFactory, $rootScope, $ngRedux, $http, API_ENDPOINT, $timeout) {
    return function () {
      if (AuthenticationFactory.isLogged) {
        window.Intercom('shutdown')
        $.ajax({
          url: API_ENDPOINT.url + '/v1/logout/',
          type: 'POST',
          contentType: false,
          beforeSend: function (request) {
            request.setRequestHeader('Authorization', 'Bearer ' + $window.localStorage.pr_token)
          },
          success: function (response) {},
          error: function (error) {},
          complete: function () {}
        })
        AuthenticationFactory.isLogged = false
        delete AuthenticationFactory.user

        delete $window.localStorage.pr_token
        delete $window.localStorage.pr_user
        delete $window.localStorage.pr_client_id
        delete $window.localStorage.pr_agent_id
        delete $window.localStorage.pr_company
        delete $window.localStorage.agent_program_banner
        delete $window.localStorage.userType
        delete $window.localStorage.activeTimesheet
        delete $window.localStorage.startTime

        $timeout(() => {
          $rootScope.user = {}
          $rootScope.company = {}
          $rootScope.employees = []
          $rootScope.payRuns = []
          $rootScope.leaves = []
          $rootScope.holidays = []
          delete $rootScope.agent

          $ngRedux.dispatch(resetInitialState())
        }, 500)
        return true
      }
      return false
    }
  })

  .factory(
    'UserAuthFactory',
    function (
      $q,
      $rootScope,
      $window,
      $location,
      $http,
      AuthenticationFactory,
      API_ENDPOINT,
      $state,
      Service,
      Http,
      $ngRedux,
      $stateParams
    ) {
      function loginSuccessCallback(data) {
        return new Promise((success) => {
          // Temporarily store what's new popup values
          let whatsNewVal = {}
          Object.keys(STORAGE_NAMES.WHATS_NEW_POPUP).map((key) => {
            const val = localStorage.getItem(STORAGE_NAMES.WHATS_NEW_POPUP[key])
            if (val) whatsNewVal[key] = val
          })

          localStorage.clear()
          const userId = data.token.user
          AuthenticationFactory.isLogged = true
          AuthenticationFactory.user = userId

          if (data.company) {
            if (!data.company.ag) {
              localStorage.setItem('pr_company', JSON.stringify(data.company))
            } else {
              localStorage.setItem('pr_agent', JSON.stringify(data.company))
            }
          }
          localStorage.setItem('pr_token', data.token.token)
          localStorage.setItem('pr_user', userId) // to fetch the user details on refresh
          localStorage.setItem('pr_expires', data.token.expires)
          localStorage.setItem('userType', data?.user?.userType)

          /**
           * Restore the what's new popup values.
           * This ensures that the popup will not be shown
           * again if the user has already seen it in this browser
           */
          Object.keys(STORAGE_NAMES.WHATS_NEW_POPUP).map((key) => {
            if (whatsNewVal[key]) localStorage.setItem(STORAGE_NAMES.WHATS_NEW_POPUP[key], whatsNewVal[key])
          })

          // Send userId to GTM before SignupSuccess event
          window.dataLayer.push({ userId })
          Service.hideLoading()
          Http(
            {
              method: 'get',
              api: '/v1/initial/'
            },
            (res) => {
              if (data.agentLinkError && data.agentLinkError.type === 'success') {
                Service.alert('Info', data.agentLinkError.message, 'success')
              } else if (data.agentLinkError && data.agentLinkError.type === 'error') {
                $ngRedux.dispatch(
                  setModals([
                    {
                      kind: 'AgentErrorModal',
                      channel: 'local',
                      options: {
                        overlay: true,
                        data: { url: API_ENDPOINT.url, agentId: $stateParams.agentId }
                      }
                    }
                  ])
                )
              }
              if (res.company?.redirectToV2Web && !data?.isRedirect && res.company?.ag) {
                const DEV_V2_URL = process.env.DEV_V2
                $window.location.replace(
                  `${DEV_V2_URL}/agent/clients?t=${data.token.token}&e=${data.token.expires}&userType=agent`
                )
                localStorage.clear()
                return
              }
              if (res.company.ag) {
                localStorage.setItem('pr_agent_id', res.company._id)
                localStorage.setItem('pr_agent', JSON.stringify(res.company))
                AuthenticationFactory.check()
              }

              window.Sentry?.setUser({ email: res.user.e, id: res.user._id })
              $ngRedux.dispatch(setInitialState(res))
              success(true)
            },
            (err) => {
              success(true)
            }
          )
        })
      }

      return {
        loginSuccess: loginSuccessCallback,
        register: function (authData, agentId, invitedByEmployer, reference, billingDetails, promo) {
          return $q(function (resolve, reject) {
            var hostArray = window.location.hostname.split('.')
            const params = new URLSearchParams(window.location.search)

            var config = {
              method: 'post',
              api: '/signup',
              data: {
                ...(authData.password && { password: authData.password }),
                userData: {
                  e: authData.e,
                  wp: 'w',
                  ...(params.has('companyName') && { c: params.get('companyName') }), // for agent invitation
                  ...(authData.f && { f: authData.f }),
                  ...(authData.l && { l: authData.l }),
                  ...(authData.c && { c: authData.c }),
                  ...(authData.q1 && { q1: authData.q1 }),
                  ...(authData.q2 && { q2: authData.q2 }),
                  ...(authData.mi && { mi: authData.mi }),
                  ...(agentId && { agentId: agentId }),
                  ...(invitedByEmployer && { referredBy: 'e' }),
                  ...(authData.contactId && { contactId: authData.contactId }),
                  ...(authData.wh && { wh: authData.wh }),
                  ...(authData.pp && { pp: authData.pp }),
                  ...(authData.agentType && { agentType: authData.agentType }),
                  ...(authData.agentTypeOther && { agentTypeOther: authData.agentTypeOther }),
                  ...(authData.referredBy && { referredBy: authData.referredBy }),
                  ...(authData.referredByFriend && { referredByFriend: authData.referredByFriend })
                },
                ...(authData.password && { password: authData.password }),
                from: 'payroller-web',
                url: hostArray[hostArray.length - 2] === 'payrolls' ? hostArray[hostArray.length - 3] : null,
                features: authData.features,
                promo: promo || ''
              },
              disableErrorMsg: true
            }

            if (billingDetails && reference) {
              config.data.billingDetails = billingDetails
              config.data.reference = reference
            }

            Http(
              config,
              (res) => {
                loginSuccessCallback(res).then(() => resolve(res))
              },
              (data) => {
                reject(data ? data.message : null)
              }
            )
          })
        },
        login: function (username, password, agentId, googleTokenId) {
          return $q(function (resolve, reject) {
            const isTsrOnly = process.env.APP === 'people'
            const data = googleTokenId
              ? { googleTokenId }
              : {
                  username: username,
                  password: password,
                  agentId
                }
            Http(
              {
                method: 'post',
                api: '/d/login',
                data,
                disableErrorMsg: true,
                ...(isTsrOnly && {
                  headers: {
                    'x-public': Capacitor.isNativePlatform() ? 'TSRApp' : 'TSR'
                  }
                })
              },
              (responseData) => {
                // check if initial setting completed

                if (isTsrOnly && responseData.user.userType === 'agent') {
                  return reject(
                    'The timesheet mobile app is accessible to employers only. To access timesheets as an agent please log in to payroller.com.au '
                  )
                }

                if (responseData.token) {
                  loginSuccessCallback(responseData).then(() => resolve(responseData))
                } else {
                  resolve(responseData)
                }
              },
              async (data) => {
                if (data) {
                  reject(data.message)

                  if (data.error === 'NO_USER_ERROR') {
                    await sleep(1) // wait for the alert. (error message)
                    window.location.href = `/signup?email=${data.email}`
                  }
                }
              }
            )
          })
        }
      }
    }
  )

  .factory('TokenInterceptor', function ($q, $window, $cookies) {
    return {
      request: function (config) {
        config.headers = config.headers || {}
        config.headers['csrf-token'] = $cookies.get('_surf')
        if ($window.localStorage.pr_token) {
          config.headers['Authorization'] = 'Bearer ' + $window.localStorage.pr_token
          config.headers['Content-Type'] = 'application/json'

          if ($window.localStorage.pr_agent_id) {
            // client id를 server 에 넘겨 줌으로 agent 가 client 의 패널을 마음껏 건드릴수 있도록 한다.
            config.headers['x-agent-id'] = $window.localStorage.pr_agent_id
            // config.headers["x-client-id"] = $window.localStorage.pr_client_id;
          }
        }
        return config || $q.when(config)
      },

      response: function (response) {
        return response || $q.when(response)
      }
    }
  })
