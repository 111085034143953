export const scrollView = 'fixed left-0 top-0 w-screen h-screen overflow-y-scroll hide-scrollbar'

export const overlay = 'fixed w-screen h-screen bg-black !bg-opacity-50'

export const positioning =
  'absolute top left-1/2 -translate-x-1/2 z-modal w-full md:!w-fit h-full flex justify-center items-center'

export const fullscreen = 'w-full h-full'

export const confetti = 'w-screen h-screen fixed top-0 left-0 z-modal pointer-events-none'

export const tsrContainer = {
  tsr_only:
    'bg-white xl:!min-w-[100vw] md:min-w-[416px] md:max-w-[768px] max-w-[416px] xl:!rounded-none rounded-lg hide-scrollbar',
  non_tsr_only:
    'bg-white sm:!min-w-[100vw] md:min-w-[416px] md:max-w-[768px] max-w-[416px] sm:!rounded-none rounded-lg sm:!mt-0 mt-16 hide-scrollbar'
}
