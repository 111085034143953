import { eventNames } from '@bookipi/utils'

angular
  .module('starter.business.controllers')
  .controller('leaveScheduleCtrl', function ($scope, Service, Http, LeaveAction, Analytics) {
    Analytics.logEvent('LeavePage Scheduled Clicked')
    $scope.getLeaveType = (type) => {
      const leaveTypes = [
        {
          title: 'Annual Leave',
          value: 'a'
        },
        {
          title: "Sick/Carer's Leave",
          value: 's'
        }
      ]
      return leaveTypes.find((leaveType) => leaveType.value === type)?.title
    }

    $scope.getLeaveStatus = (status) => {
      return status === 'p' ? 'requested_leaves' : 'scheduled_leaves'
    }

    $scope.openPendingModal = function (leave) {
      Service.modal('openPendingModal.html', [
        '$scope',
        '$uibModalInstance',
        function ($scope, $uibModalInstance) {
          $scope.leave = leave
          $scope.cancel = function () {
            $uibModalInstance.dismiss()
          }

          $scope.updateLeave = function (leave, option) {
            const leaveCopy = { ...leave }
            leaveCopy.s = option ? 'a' : 'r'
            leaveCopy.handledAt = new Date()
            leaveCopy.isDismissed = false
            Http(
              {
                method: 'post',
                api: '/v1/leave/update/' + leave._id,
                data: {
                  leave: leaveCopy
                },
                disableErrorMsg: true
              },
              () => {
                const eventName = leaveCopy.s === 'a' ? eventNames.Leave.Approved : eventNames.Leave.Declined
                Analytics.logEvent(eventName, {
                  leave_type: $scope.getLeaveType(leave?.ty),
                  leave_status: $scope.getLeaveStatus(leave?.s)
                })
                leave.s = leaveCopy.s
                $uibModalInstance.dismiss()
                $scope.loading = false
              },
              (err) => {
                if (err.error) {
                  Service.alert('Alert', err.error, 'error')
                } else {
                  Service.alert(
                    'Alert',
                    'Sorry, there was a problem processing your request. Please try again.',
                    'error'
                  )
                }
                $scope.loading = false
              }
            )
          }

          $scope.leaveOption = function (value) {
            $scope.updateLeave(leave, value)
          }

          $scope.getRange = function (startDate, endDate) {
            var formattedStartDate = moment(startDate).format('D MMM YYYY')
            var formattedEndDate = moment(endDate).format('D MMM YYYY')

            if (formattedEndDate == formattedStartDate) {
              return formattedStartDate
            } else {
              return formattedStartDate + ' ~ ' + formattedEndDate
            }
          }
        }
      ])
    }

    $scope.validateModal = function (leave) {
      if (leave.s == 'p') {
        $scope.openPendingModal(leave)
      }
    }

    $scope.getName = function (firstName, lastName) {
      return firstName + ' ' + (lastName ? lastName.charAt(0).toUpperCase() + '.' : '')
    }

    $scope.getRange = function (startDate, endDate) {
      var formattedStartDate = moment(startDate).format('D MMM YYYY')
      var formattedEndDate = moment(endDate).format('D MMM YYYY')

      if (formattedEndDate == formattedStartDate) {
        return formattedStartDate
      } else {
        return formattedStartDate + ' ~ ' + formattedEndDate
      }
    }

    $scope.deleteLeave = function (leave) {
      const leaveId = leave._id
      Analytics.logEvent(eventNames.Leave.Deleted, {
        leave_type: $scope.getLeaveType(leave?.ty),
        leave_status: $scope.getLeaveStatus(leave?.s)
      })
      $scope.loading = true
      Service.confirm({
        title: 'Delete leave',
        contents: 'Please note that this leave data cannot be restored once you delete it. Would you like to proceed?',
        confirmText: 'Yes!',
        cancelText: 'No',
        confirmCallback: function () {
          Http(
            {
              method: 'post',
              api: '/v1/leave/delete/' + leaveId
            },
            () => {
              $scope.loading = false
              // 저장된 employee data 를 update 한다.
              LeaveAction.delete(leaveId)
            },
            (err) => {
              $scope.loading = false
            }
          )
        },
        cancelCallback: () => {}
      })
    }
  })
