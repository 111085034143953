import moment from 'moment'
import { setModals } from '/reducers/appSlice'
import { getFiscalYear, getFiscalYears, getLatestFiscalYear } from '../../utils/getFiscalYear'
import { postData, eventNames } from '@bookipi/utils'
import { getInitialData } from './businessCtrl'

angular
  .module('starter.business.controllers')
  .controller(
    'peopleCtrl',
    function (
      $scope,
      $rootScope,
      $state,
      Http,
      Service,
      EmployeeAction,
      LeaveService,
      employeeModalService,
      $ngRedux,
      Http2,
      $filter,
      PdfViewerModalService,
      PayRunAction,
      Analytics
    ) {
      $scope.viewDeletedEmployees = false

      $scope.toggleViewDeletedEmployees = function () {
        $scope.viewDeletedEmployees = !$scope.viewDeletedEmployees
      }
      $scope.employeeStatusComparator = function (v1, v2) {
        if (v1.type !== 'string' || v2.type !== 'string') {
          return v1.index < v2.index ? -1 : 1
        }

        return (v2.value !== 'i') - (v1.value !== 'i')
      }

      $scope.openNewEmployeeModal = function () {
        if ($rootScope.gbFeatures.new_employee_creation) {
          const pageInitiated = 'people'
          $ngRedux.dispatch(setModals([{ kind: 'AddEmployee', options: { data: { pageInitiated } } }]))

          Analytics.logEvent(eventNames.EmployeeAdditionInitiated, {
            page_initiated: pageInitiated
          })
        } else if ($rootScope.gbFeatures.loaded) employeeModalService.new()
      }

      $scope.openImportEmployeePage = function () {
        $state.go('business.import.employees', {})
      }

      $scope.openEditEmployeeModal = function (employee) {
        employeeModalService.edit(employee)
      }

      $scope.onOptInPeopleRevamp = async () => {
        const ENDPOINT = process.env.API_ENDPOINT
        await postData({
          url: `${ENDPOINT}/v1/company/update`,
          data: {
            company: {
              optInPeopleRevamp: true
            }
          }
        })
        getInitialData()
        $state.go('business.people-v2', {})
      }

      $scope.openEmployDetailsModal = function (employee) {
        function openEditEmployeeModal() {
          $scope.openEditEmployeeModal($scope.employees.find((x) => x._id === employee._id) || employee)
        }
        Service.modal(
          'employeeDetailsModal.html',
          [
            '$scope',
            '$uibModalInstance',
            async function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.editEmployeeDetails = function () {
                $scope.cancel()
                openEditEmployeeModal()
              }
              $scope.employee = employee

              // get leave remaining data
              var leaveBalance = await LeaveService.getLeaveBalance({
                employeeId: $scope.employee._id
              })

              var leaves = []

              // get leave data
              for (let index = 0; index < $rootScope.leaves.length; index++) {
                const leave = $rootScope.leaves[index]
                if (leave.ei == employee._id) {
                  leaves.push(leave)
                }
              }
              // get payslip data
              var payslips = []
              for (let i = 0; i < $rootScope.payRuns.length; i++) {
                const payRun = angular.copy($rootScope.payRuns[i])
                for (let j = 0; j < payRun.psl.length; j++) {
                  const payslip = payRun.psl[j]
                  if (payslip.ei == employee._id) {
                    payslip.payrunData = {
                      id: payRun._id,
                      startDate: payRun.sd,
                      endDate: payRun.ed,
                      paymentDate: payRun.pd
                    }
                    payslip.endDate = payRun.ed
                    payslip.paymentDate = payRun.pd
                    payslips.push(payslip)
                  }
                }
              }
              $scope.leaveBalance = leaveBalance
              $scope.leaves = leaves
              $scope.payslips = payslips

              $scope.taxYear = payslips?.length
                ? getLatestFiscalYear(payslips, 'paymentDate')
                : getFiscalYear(new Date())

              const filterPaylips = (year) => {
                return payslips?.filter((payslip) => getFiscalYear(payslip.paymentDate) === year)
              }

              $scope.filteredPayslips = filterPaylips($scope.taxYear)

              $scope.onFilterPayslips = (year) => {
                $scope.filteredPayslips = filterPaylips(year)
              }

              $scope.fyOptions = getFiscalYears(payslips, 'paymentDate').map((year) => {
                return { name: `${year} - ${year + 1}`, val: year }
              })

              $scope.getRange = function (startDate, endDate) {
                var formattedStartDate = moment(startDate).format('D MMM YY')
                var formattedEndDate = moment(endDate).format('D MMM YY')

                if (formattedEndDate == formattedStartDate) {
                  return formattedStartDate
                } else {
                  return formattedStartDate + ' ~ ' + formattedEndDate
                }
              }

              $scope.viewPaySlip = async function (payslipArray, payRun) {
                let retData
                try {
                  retData = await Http2({
                    method: 'post',
                    api: '/v2/create-payslip',
                    data: {
                      payslips: payslipArray,
                      startDate: payRun.startDate,
                      endDate: payRun.endDate,
                      paymentDate: payRun.paymentDate
                    }
                  })
                } catch (err) {
                  console.error(err)
                  window.Sentry?.captureException(err)
                }
                var filename =
                  payslipArray.length > 1
                    ? 'PayslipsEnding' + $filter('date')(new Date(), 'ddMMMyy', 'UTC')
                    : 'PayslipEnding' +
                      $filter('date')(new Date(), 'ddMMMyy', 'UTC') +
                      (payslipArray[0].f ? '-' + payslipArray[0].f : '')
                PdfViewerModalService(retData.base64, 'Payslip', filename)
                $scope.cancel()
              }

              $scope.sendPaySlip = function (payRun, payslipId) {
                const payRunId = payRun.id
                const fullPayrunData = $rootScope.payRuns.find((p) => p?._id == payRunId)
                const tmpPayrun = angular.copy(fullPayrunData)
                Service.confirm({
                  title: 'Send payslip',
                  contents: 'Are you sure you want to send a payslip?',
                  confirmText: 'Yes!',
                  cancelText: 'No',
                  confirmCallback: async function () {
                    // $scope.cancel()
                    $scope.loading = true
                    let retData
                    try {
                      retData = await Http2({
                        method: 'post',
                        api: '/v2/send-payslip',
                        data: {
                          payRunId,
                          payslipId
                        }
                      })
                      // Update sent field if Successful.
                      if (retData?.success) {
                        if (tmpPayrun) {
                          const index = tmpPayrun.psl.findIndex((payslip) => payslip._id === payslipId)
                          if (index >= 0) {
                            tmpPayrun.psl[index]['sent'] = new Date().toISOString()
                            $scope.payRun = tmpPayrun
                            PayRunAction.update(tmpPayrun)
                          }
                        }
                      }
                    } catch (error) {}
                    Service.alert('Success!', 'Payslip has been successfully sent!', 'success')
                  },
                  cancelCallback: () => {}
                })
              }
            }
          ],
          { size: 'lg' }
        )
      }
    }
  )
