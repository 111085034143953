import moment from 'moment-timezone'
import { eventNames } from '@bookipi/utils'

angular
  .module('starter.business.controllers')
  .controller(
    'leaveRequestCtrl',
    function ($scope, $rootScope, Service, DateService, Http, $ngRedux, LeaveAction, LeaveService, $filter, Analytics) {
      $scope.sideBarState = { type: 'LEAVES' }

      $scope.openLeaveRequestModal = function (leaveType) {
        Analytics.logEvent(eventNames.Leave.AdditionInitiated, {
          leave_type: leaveType
        })
        Service.modal(
          'leaveRequestModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                Analytics.logEvent(eventNames.Leave.AdditionCancelled, {
                  leave_type: leaveType,
                  leave_status: 'requested_leave'
                })
                $uibModalInstance.dismiss()
              }
              $scope.isTsrOnly = false
              $scope.isKeyboardVisible = false
              $scope.leaveType = leaveType
              $scope.leaveNote = null
              $scope.randomName = Math.random().toString(36).substring(7)
              $scope.leaveRequestDetails = {}
              var employeeArray = $rootScope.employees

              $ngRedux.connect((state) => ({
                isTsrOnly: state.app.isTsrOnly,
                isKeyboardVisible: state.app.isKeyboardVisible
              }))($scope)

              $scope.autoCompleteOptions_leaveRequest = {
                activateOnFocus: true,
                maxItemsToRender: 10,
                containerCssClass: 'color-codes',
                selectedTextAttr: 'name',
                itemTemplateUrl: 'views/autoComplete/employeeSearchAutoComplete.html',
                noMatchTemplateEnabled: false,
                data: function (searchText) {
                  searchText = searchText.toUpperCase()
                  return _.filter(employeeArray, function (employee) {
                    return employee.s != 'i' && employee.s != 'd' && employee.f
                      ? (employee.f + ' ' + (employee.l || '')).toUpperCase().includes(searchText)
                      : false
                  })
                },
                itemSelected: async function (e) {
                  $scope.leaveRequestDetails.name = e.item.f + ' ' + e.item.l
                  $scope.leaveRequestDetails.firstName = e.item.f
                  $scope.leaveRequestDetails.lastName = e.item.l
                  $scope.leaveRequestDetails.employeeId = e.item._id
                  $scope.leaveRequestDetails.employeeState = e.item.a_s || 'NSW'
                  getEstimatedRemainingLeave()
                }
              }

              $scope.leaveDatePicker = { startDate: moment(), endDate: moment(), hoursAway: 1 }
              $scope.showHoursAway = true
              $scope.applyDateRangePicker = function () {
                const { startDate, endDate } = $scope.leaveDatePicker
                $scope.showHoursAway = startDate?.isSame(endDate, 'date')
                // get estimated leave hours on that day.
                getEstimatedRemainingLeave()
              }
              $scope.handleLeaveHoursChange = function () {
                getEstimatedRemainingLeave()
              }

              function formValidation(option) {
                var valid = true
                $scope.leaveRequestDetailsError = {}
                if (!$scope.leaveRequestDetails.employeeId) {
                  valid = false
                  if (option != 'noAlert') {
                    $scope.leaveRequestDetailsError.name = true
                  }
                }
                if (!$scope.leaveDatePicker.startDate || !$scope.leaveDatePicker.endDate) {
                  valid = false
                  if (option != 'noAlert') {
                    $scope.leaveRequestDetailsError.date = true
                  }
                }
                return valid
              }

              function getEstimatedRemainingLeave() {
                if (!formValidation('noAlert')) {
                  return false
                }

                var leaveStartDate = Service.getUTCDate($scope.leaveDatePicker.startDate)
                var leaveEndDate = Service.getUTCDate($scope.leaveDatePicker.endDate)
                let leaveHoursAway = $scope.leaveDatePicker.hoursAway

                var leaveBalance = LeaveService.getProjectionLeaveBalance(
                  $scope.leaveRequestDetails.employeeId,
                  leaveStartDate,
                  leaveType
                )
                // 기존에 있는 leave 합산하여 remaining after value 구하기

                $scope.scheduledLeaves = []
                var scheduledLeaveHours = 0
                for (let index = 0; index < $rootScope.leaves.length; index++) {
                  const leave = $rootScope.leaves[index]
                  if (
                    $scope.leaveRequestDetails.employeeId === leave.ei &&
                    ((leaveType === 'annual' && leave.ty == 'a') || (leaveType !== 'annual' && leave.ty == 's')) &&
                    Service.getUTCDate(leave.ed) < leaveStartDate &&
                    Service.getUTCDate(leaveBalance.lastPayRunEndDate) <= Service.getUTCDate(leave.ed)
                  ) {
                    var numOfDays = 0
                    var startDate
                    let leaveHours = 0
                    if (
                      Service.getUTCDate(leave.sd) <= Service.getUTCDate(leaveBalance.lastPayRunEndDate) &&
                      Service.getUTCDate(leaveBalance.lastPayRunEndDate) <= Service.getUTCDate(leave.ed)
                    ) {
                      startDate = Service.getUTCDate(moment(leaveBalance.lastPayRunEndDate).add(1, 'd')._d)
                      numOfDays = DateService.businessDiff(
                        startDate,
                        moment(Service.getUTCDate(leave.ed)).add(1, 'd'),
                        true
                      )
                    } else {
                      numOfDays = leave.nd || 0
                      startDate = leave.sd
                      leaveHours = leave.h || 0
                    }
                    let totalLeaveHours = numOfDays === 1 ? leaveHours : numOfDays * ($rootScope.company.wh || 7.6)
                    scheduledLeaveHours += totalLeaveHours
                    if (numOfDays) {
                      $scope.scheduledLeaves.push({
                        dates:
                          moment(startDate).format('YYYY-MM-DD') == moment(leave.ed).format('YYYY-MM-DD')
                            ? $filter('date')(startDate, 'dd MMM yyyy', 'UTC') + ' (' + leaveHours + 'hrs)'
                            : $filter('date')(startDate, 'dd MMM yyyy', 'UTC') +
                              ' ~ ' +
                              $filter('date')(leave.ed, 'dd MMM yyyy', 'UTC'),
                        hours: totalLeaveHours
                      })
                    }
                  }
                }
                $scope.leaveInfo = leaveBalance

                var workingDays = DateService.businessDiff(leaveStartDate, moment(leaveEndDate).add(1, 'd'), true)
                $scope.leaveInfo.leaveDays = workingDays
                $scope.leaveInfo.leaveHours =
                  workingDays === 1 ? leaveHoursAway : workingDays * ($rootScope.company.wh || 7.6)
                $scope.leaveInfo.remainingAfter =
                  $scope.leaveInfo.expectedRemaining -
                  ($scope.leaveInfo.leaveDays === 1
                    ? leaveHoursAway
                    : $scope.leaveInfo.leaveDays * ($rootScope.company.wh || 7.6)) -
                  scheduledLeaveHours
              }

              $scope.submitLeaveRequest = function () {
                const leaveStartDate = $scope.leaveDatePicker.startDate
                const leaveEndDate = $scope.leaveDatePicker.endDate
                const leaveHoursAway = $scope.leaveDatePicker.hoursAway

                if (!formValidation()) {
                  return false
                }

                function saveOnServer() {
                  $scope.loading = true
                  Http(
                    {
                      method: 'post',
                      api: '/v1/leave/',
                      data: {
                        leave: {
                          ci: $rootScope.company._id,
                          ei: $scope.leaveRequestDetails.employeeId,
                          f: $scope.leaveRequestDetails.firstName,
                          l: $scope.leaveRequestDetails.lastName,
                          nd: $scope.leaveInfo.leaveDays, // number of days
                          sd: Service.getUTCDate(leaveStartDate),
                          ed: Service.getUTCDate(leaveEndDate),
                          h: leaveHoursAway,
                          ty: leaveType == 'annual' ? 'a' : leaveType == 'sick' ? 's' : null,
                          s: 'a',
                          nt: $scope.leaveNote
                        }
                      },
                      disableErrorMsg: true
                    },
                    (res) => {
                      Analytics.logEvent(eventNames.Leave.Created, {
                        leave_type: leaveType,
                        leave_status: 'requested_leave'
                      })
                      LeaveAction.add(res.leave)
                      $scope.cancel()
                      $scope.loading = false
                    },
                    (err) => {
                      if (err.error) {
                        Service.alert('Alert', err.error, 'error')
                      } else {
                        Service.alert(
                          'Alert',
                          'Sorry, there was a problem processing your request. Please try again.',
                          'error'
                        )
                      }
                      $scope.loading = false
                    }
                  )
                }

                if ($scope.leaveInfo.remainingAfter < 0) {
                  Service.confirm({
                    title: 'Alert',
                    contents:
                      'Would you like to allow a negative leave balance of ' +
                      Math.round(Math.abs($scope.leaveInfo.remainingAfter) * 10) / 10 +
                      ' hour(s)?',
                    confirmText: 'Yes!',
                    cancelText: 'No',
                    confirmCallback: function () {
                      saveOnServer()
                      Analytics.logEvent('LeavePage Alert Popup Yes Clicked')
                    },
                    cancelCallback: () => {}
                  })
                } else {
                  saveOnServer()
                }
              }
            }
          ],
          { size: 'md' }
        )
      }
    }
  )
