import loglevel from 'loglevel'
import { angularApp } from './app'
import { isDebug } from './scheduling/react-subscription/utils/share'
import { createContext } from 'react'
import { initExternalLibs } from '/services/externalLibsService'

initExternalLibs()

// for AngularJs
angularApp
  .constant('API_ENDPOINT', {
    url: process.env.API_ENDPOINT,
    socketIo: process.env.socketIo
  })
  .constant('BEAM_URL', process.env.BEAM_URL)
  .constant('GLOBALS', {
    LOGIN_SUB: false, // do not redirect to subscription plan page after login
    GUI_DEBUG: process.env.GUI_DEBUG,
    TESTMAIL_PFX: process.env.TESTMAIL_PFX || '',
    ROUNDING_DEBUG: false
  })

// for AngularJs & React
global.BPAY_PUBLIC_URL = process.env.BPAY_PUBLIC_URL

// ----------------------------- Logs ----------------------------------
// Ignore Framer Handshake warnings (override console.error method)
const backup = console.error
console.error = function filterWarnings(msg) {
  const supressedWarnings = [
    'Warning: React does not recognize the',
    'Warning: Invalid value for prop',
    'Warning: Received'
  ]

  // sometimes msg is not a string
  if (!supressedWarnings.some((entry) => msg.includes?.(entry))) {
    backup.apply(console, arguments)
  }
}

// loglevel
global.log = loglevel // use the loglevel globally

if (isDebug) {
  localStorage.setItem('debug', 'app.payroller.com.au:*')
  // show trace / debug / warn / error on DEV env
  log.setLevel('trace')
}

// global context for framer modules
const ModuleContext = createContext({})
global.ModuleContext = ModuleContext

// To search address
const autocompleteService = typeof google !== 'undefined' && new google.maps.places.AutocompleteService()
const geocoder = typeof google !== 'undefined' && new google.maps.Geocoder()

global.autocompleteService = autocompleteService
global.geocoder = geocoder
