export const other = 'Other (Please specify below)'

export const reasons = [
  'I only need the free version',
  'I have closed my business',
  "I don't need it right now but I will be back",
  "It's too expensive",
  'I no longer need payroll software',
  'I found a better product',
  'It does not have a feature that I need',
  'It is too hard to use',
  'My business has outgrown it',
  "I'm experiencing technical issues",
  'Lack of phone support',
  other
]
