export const category = {
  webapp: 'webapp',
  timesheet: 'timesheet'
}

export const expire = {
  timeExpire: 'timeExpire',
  timeExpire2: 'timeExpire2'
}

export const COLOR = {
  Aqua: '#4CAAAC',
  Light_Aqua: '#E2F1F1',
  Secondary: '#79838E',
  Warning: '#f7e4b1',
  Error: 'red',
  C_1E2022: '#1E2022',
  C_333333: '#333333',
  C_364152: '#364152',
  C_F3F4F8: '#F3F4F8',
  C_334152: '#334152',
  negative400: '#FBD2D3',
  negative600: '#ED1C24'
}

export * from './twConfig'

export * from './links'
export * from './paywall'
export * from './stp'
