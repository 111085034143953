import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './prepareHeaders'

export const testApi = createApi({
  reducerPath: 'testApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://httpbin.org', prepareHeaders }),
  endpoints: (build) => ({
    postTest: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/post',
        method: 'POST',
        body: patch
      })
    })
  })
})

export const { useMutation } = testApi.endpoints.postTest
