"use strict";
exports.__esModule = true;
exports.convertTimeToCompanyFormat = exports.is24HourFormat = exports.is12HourFormat = exports.convertTo12HourFormat = exports.convertTo24HourFormat = void 0;
var time_1 = require("../values/time");
var convertTo24HourFormat = function (time12h) {
    var match = time12h.match(/^(\d{1,2}:\d{2})(AM|PM)$/);
    if (!match) {
        throw new Error('Invalid time format');
    }
    var _ = match[0], time = match[1], modifier = match[2];
    var _a = time.split(':').map(Number), hours = _a[0], minutes = _a[1];
    if (modifier === 'PM' && hours !== 12) {
        hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
        hours = 0;
    }
    // Ensure hours and minutes are two digits
    var hoursStr = hours.toString().padStart(2, '0');
    var minutesStr = minutes.toString().padStart(2, '0');
    return "".concat(hoursStr, ":").concat(minutesStr);
};
exports.convertTo24HourFormat = convertTo24HourFormat;
var convertTo12HourFormat = function (time24h) {
    var _a = time24h.split(':').map(Number), hours = _a[0], minutes = _a[1];
    var period = 'AM';
    var hour = hours;
    if (hour >= 12) {
        period = 'PM';
        if (hour > 12) {
            hour -= 12;
        }
    }
    if (hour === 0) {
        hour = 12;
    }
    var hourStr = hour.toString();
    var minutesStr = minutes.toString().padStart(2, '0');
    return "".concat(hourStr, ":").concat(minutesStr).concat(period);
};
exports.convertTo12HourFormat = convertTo12HourFormat;
var is12HourFormat = function (time) {
    var timeRegex = /^([1-9]|1[0-2]):[0-5][0-9](AM|PM)$/;
    return timeRegex.test(time);
};
exports.is12HourFormat = is12HourFormat;
var is24HourFormat = function (time) {
    var timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(time);
};
exports.is24HourFormat = is24HourFormat;
var convertTimeToCompanyFormat = function (time, timeFormat) {
    var timeFormatIs24 = timeFormat === time_1.TIME_FORMATS[1].value;
    if (timeFormatIs24 && is12HourFormat(time))
        time = convertTo24HourFormat(time);
    if (!timeFormatIs24 && is24HourFormat(time))
        time = convertTo12HourFormat(time);
    return time;
};
exports.convertTimeToCompanyFormat = convertTimeToCompanyFormat;
