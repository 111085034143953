import { AppLauncher } from '@capacitor/app-launcher'

import { serializeParams } from '@payroller/pds'

angular.module('starter.auth.controllers').controller('payrollerTsrCtrl', function ($rootScope, $scope, $stateParams) {
  new WOW().init()
  $scope.message = "Well done! You've finished setting up your account."

  /**
   * This method will attempt to open the TSR Scheduling app and
   * force the employer to be redirected to roster page.
   */
  const checkCanOpenUrl = async () => {
    try {
      const { value } = await AppLauncher.canOpenUrl({ url: 'timesheets.employee.scheduling' })
      if (value) {
        const urlScheme = 'timesheets.employee.scheduling://'
        const queryObj = {}
        const keys = ['t', 'e', 'u', 's', 'newUser']
        keys.forEach((key) => {
          const val = $stateParams[key]
          if (val) queryObj[key] = val
        })
        const payload = serializeParams(queryObj)
        window.open(`${urlScheme}tsr-signup${payload}`, '_self')
      }
    } catch (e) {
      console.log('checkCanOpenUrl error', e)
    }
  }

  if ($stateParams.action) {
    switch ($stateParams.action) {
      case 'reset-password':
        $scope.message = "Well done! You've successfully reset your password."
        break
    }
  } else {
    checkCanOpenUrl()
  }
})
