import readXlsxFile from 'read-excel-file'

angular
  .module('starter.agent.controllers')
  .controller(
    'agentImportBusinessCtrl',
    function ($scope, $rootScope, $state, $stateParams, Http, $window, Service, Analytics) {
      $scope.downloadExcelTemplate = () => {
        document.getElementById('iframe_for_download').src =
          'https://s3.amazonaws.com/bookipi.com/downloadable-files/business-import-template.xlsx'
      }

      $scope.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
      $scope.uploadItemList = async function (file) {
        if (file) {
          const isCSV = /(\.csv)$/i.exec(file.name)
          const isExcel = /(\.xlsx)$/i.exec(file.name)

          if (isCSV) {
            var r = new FileReader()
            r.onload = function (e) {
              var contents = e.target.result
              var importedData = Service.CSVToArray(contents)
              processExcelData(importedData)
            }

            r.readAsText(file)
          } else if (isExcel) {
            const importedData = await readXlsxFile(file)
            processExcelData(importedData)
          }
        }
      }

      function processExcelData(data) {
        const clientList = []

        if (data[0][0] && data[0][0].toLowerCase() === 'first name') {
          data.splice(0, 1)
        }

        for (let index = 0; index < data.length; index++) {
          if (data[index][0]) {
            clientList.push(data[index])
          }
        }

        // validation
        doValidation(clientList)
        $scope.$apply(function () {
          $scope.importedClients = clientList
        })
      }

      var doValidation = (clientList) => {
        var invalid = false
        var errors = []

        clientList.map((client, index) => {
          clientList[index][4] = clientList[index][4] ? clientList[index][4].replace(/\s/g, '') : ''
          clientList[index][29] = clientList[index][29] ? clientList[index][29].replace(/\s/g, '') : ''
        })

        var duplicationCounts = []

        clientList.map((client) => {
          var errorLog = {
            hasError: false,
            errorArray: [
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false,
              false
            ]
          }
          // email duplication check
          if (client) {
            if (duplicationCounts[client[2]] === undefined) {
              duplicationCounts[client[2]] = 1
            } else {
              errorLog.errorArray[2] = true
              Service.alert('Email error!', 'You cannot use the same email address (' + client[2] + ').', 'error')
              invalid = true
            }
          }

          // 0: first name
          if (!client[0] || !/^[A-Za-z\d\s]+$/.test(client[0])) {
            console.log('errorLog.errorArray[0] = true;')
            errorLog.errorArray[0] = true
            errorLog.hasError = true
            invalid = true
          }
          // 1: last name
          if (!client[1] || !/^[A-Za-z\d\s]+$/.test(client[1])) {
            console.log('errorLog.errorArray[1] = true;')
            errorLog.errorArray[1] = true
            errorLog.hasError = true
            invalid = true
          }
          // 2: email validation
          if (
            !client[2] ||
            !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
              client[2]
            )
          ) {
            console.log('errorLog.errorArray[2] = true;')
            errorLog.errorArray[2] = true
            errorLog.hasError = true
            invalid = true
          }
          // 3: phone
          if (!client[3]) {
            console.log('errorLog.errorArray[3] = true;')
            errorLog.errorArray[3] = true
            errorLog.hasError = true
            invalid = true
          }
          // 4: ABN
          if (!client[4] || !Service.isABN(client[4])) {
            console.log('errorLog.errorArray[4] = true;')
            errorLog.errorArray[4] = true
            errorLog.hasError = true
            invalid = true
          }
          // 5: business name
          if (!client[5]) {
            console.log('errorLog.errorArray[5] = true;')
            errorLog.errorArray[5] = true
            errorLog.hasError = true
            invalid = true
          }
          // 6: address 1
          if (!client[6]) {
            console.log('errorLog.errorArray[6] = true;')
            errorLog.errorArray[6] = true
            errorLog.hasError = true
            invalid = true
          }
          // 8: suburb
          if (!client[8] || !/^[A-Za-z\d\s]+$/.test(client[1])) {
            console.log('errorLog.errorArray[8] = true;')
            errorLog.errorArray[8] = true
            errorLog.hasError = true
            invalid = true
          }
          // 9: postcode
          if (!client[9]) {
            console.log('errorLog.errorArray[9] = true;')
            errorLog.errorArray[9] = true
            errorLog.hasError = true
            invalid = true
          }
          // 10: state
          if (
            !(
              client[10] === 'ACT' ||
              client[10] === 'NSW' ||
              client[10] === 'NT' ||
              client[10] === 'QLD' ||
              client[10] === 'SA' ||
              client[10] === 'TAS' ||
              client[10] === 'VIC' ||
              client[10] === 'WA'
            )
          ) {
            console.log('errorLog.errorArray[10] = true;')
            errorLog.errorArray[10] = true
            errorLog.hasError = true
            invalid = true
          }
          // 11: pay period
          if (!client[11]) {
            console.log('errorLog.errorArray[11] = true;')
            errorLog.errorArray[11] = true
            errorLog.hasError = true
            invalid = true
          }
          // 12: work hours
          if (!client[12]) {
            console.log('errorLog.errorArray[12] = true;')
            errorLog.errorArray[12] = true
            errorLog.hasError = true
            invalid = true
          }
          errors.push(errorLog)
        })
        $scope.invalid = invalid
        $scope.errors = errors
        // update importedClients for UI
        return !invalid
      }

      $scope.importClient = () => {
        if (doValidation($scope.importedClients)) {
          // send clients data to server
          $scope.loading = true
          Http(
            {
              method: 'post',
              api: '/v1/agent/import-clients',
              data: {
                clients: $scope.importedClients,
                url: $rootScope.agent.ag_u
              },
              disableErrorMsg: true
            },
            (res) => {
              $scope.loading = false
              Service.alert('Success!', 'Successfully imported!', 'success')
              $state.go('agent.clients')
              $scope.init()
              Analytics.logEvent('Agent Import Client')
            },
            (err) => {
              $scope.loading = false
              Service.alert(err.error || 'Failed!', err.message, 'error')
            }
          )
        }
      }

      $scope.$watch('importFile', function (nv, ov) {
        if (nv) {
          var allowedExtensions = /(\.csv|\.xlsx)$/i
          if (!allowedExtensions.exec(nv.name)) {
            Service.alert('', 'Please upload file having extensions .csv or .xlsx only.', 'danger')
            nv = ''
            return false
          } else {
            $scope.uploadItemList(nv)
          }
        }
      })
    }
  )
