angular
  .module('starter.services')
  .factory(
    'AddonService',
    function ($rootScope, Http, Service, PayRunAction, $state, CompanyAction, API_ENDPOINT, Analytics) {
      function connectXero(data) {
        if ($rootScope.company) {
          // 1. 서버에서 xero redirect url을 만들어 낸다.
          // 2. 새로 탭을 만들어 xero 에 접속한다.
          // 3. access tokenㅇ을 서버에 저장한다.
          // 4. child 탭에서 parent 탭으로 broadcast 하여 child 탭을 닫는다.
          let config = {
            onHandshakeCallback: function (res) {
              setTimeout(function () {
                parent.closeAllTabs()
              }, 5000)
            },
            onPollingCallback: function () {},
            onChildCommunication: function (res) {}
          }

          // var parent = new AcrossTabs.Parent(config);
          let queryString = data && data.action ? `action=${data.action}&id=${data.id}` : ''

          let win = window.open(`${API_ENDPOINT.url}/xero/connect/${$rootScope.company._id}?${queryString}`, '_self')
          // var win = window.open("https://b10d7b8c3883.ngrok.io/xero/connect/" + $rootScope.company._id + queryString, "_self");
          win.focus()
          // communication between 2 windows
          // https://github.com/wingify/across-tabs
          Analytics.logEvent('Accounting Platform Connection Started', {
            provider: 'Xero'
          })
          // window.open("/","_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");
        }
      }

      function exportXero(payRun, successCallback, errorCallback, closeModal) {
        Http(
          {
            method: 'post',
            api: '/v1/xero/export',
            data: {
              payRunId: payRun._id
            },
            disableErrorMsg: true
          },
          (res) => {
            try {
              // success
              if (res.success) {
                Service.alert('Success', 'This pay run has been exported successfully.', 'success')
                PayRunAction.update(res.payRun)

                if (res.xeroData.StatusAttributeString === 'WARNING') {
                  var warningMsg = ''
                  res.xeroData.Warnings.map((error, index) => {
                    // error;
                    warningMsg += (index ? '<br><br>' : '') + error.Message
                  })

                  Service.confirm({
                    title: 'Warning',
                    contents: warningMsg,
                    confirmText: 'Go to Xero Settings',
                    cancelText: 'No',
                    confirmCallback: function () {
                      if (closeModal) {
                        closeModal()
                      }
                      // $state.go("business.addons");
                      openXeroSettings()
                    },
                    cancelCallback: () => {}
                  })
                }
                successCallback(res.payRun)
                Analytics.logEvent('Accounting Platform Export', {
                  provider: 'Xero'
                })

                if (
                  $rootScope.user &&
                  $rootScope.user.e &&
                  (!$rootScope.user.askedReview || !$rootScope.user.askedReview.xero)
                ) {
                  Service.confirm({
                    title: 'Enjoying Payroller?',
                    contents: 'Do you like Payroller as much as we do?',
                    confirmText: 'Yes!',
                    confirmCallback: function () {
                      Service.confirm({
                        title: 'Would you like to rate us on Xero review?',
                        contents: 'Give us a 5 star rating',
                        confirmText: 'Ok, sure!',
                        confirmCallback: function () {
                          window.open('https://community.xero.com/business/discussion/118772903/', '_blank')
                        },
                        secondText: "Don't ask me again",
                        removeCancel: true
                      })
                    },
                    removeCancel: true,
                    secondText: 'Not really',
                    secondCallback: () => {
                      Service.confirm({
                        title: 'Would you mind giving us some feedback?',
                        contents: '',
                        confirmText: 'Ok, sure!',
                        confirmCallback: function () {
                          window.open('https://payroller.nolt.io/', '_blank')
                        },
                        secondText: 'No, thanks',
                        removeCancel: true
                      })
                    }
                  })

                  Http(
                    {
                      method: 'post',
                      api: '/v1/user/update',
                      data: {
                        user: { 'askedReview.xero': true }
                      },
                      disableErrorMsg: true
                    },
                    (res) => {
                      $rootScope.user = res.user
                    },
                    () => {}
                  )
                }
              } else {
                // failure
                var noAccountList = ''

                if (res.accountCodeErrorArray) {
                  res.accountCodeErrorArray.map((eachAccount) => {
                    if (eachAccount.name) {
                      noAccountList += '<br>&nbsp;&nbsp;- ' + eachAccount.name
                    }
                  })
                } else if (res.xeroData.LineItems) {
                  res.xeroData.LineItems.map((lineItem) => {
                    if (!lineItem.AccountCode) {
                      noAccountList += '<br>&nbsp;&nbsp;- ' + lineItem.Description
                    }
                  })
                } else if (res.xeroData.JournalLines) {
                  res.xeroData.JournalLines.map((lineItem) => {
                    if (!lineItem.AccountCode) {
                      noAccountList += '<br>&nbsp;&nbsp;- ' + lineItem.Description
                    }
                  })
                }

                if (noAccountList) {
                  // var contents = "Please reselect account for <b>" + noAccountList + "</b> in Xero settings.";
                  var contents =
                    'The following account(s) cannot be found. Please reselect in Xero settings.<br><b>' +
                    noAccountList +
                    '</b>'
                  Service.confirm({
                    title: "Couldn't export journals",
                    contents: contents,
                    confirmText: 'Go to Xero Settings',
                    cancelText: 'No',
                    confirmCallback: function () {
                      if (closeModal) {
                        closeModal()
                      }
                      // $state.go("business.addons");
                      openXeroSettings()
                    },
                    cancelCallback: () => {}
                  })
                  return false
                }

                var errorMsg = ''

                // if(res.xeroData && res.xeroData.Invoices && res.xeroData && res.xeroData.ValidationErrors){
                if (res.xeroData && res.xeroData.ValidationErrors) {
                  res.xeroData.ValidationErrors.map((error, index) => {
                    // error;
                    errorMsg += (index ? '<br>' : '') + error.Message
                  })
                  Service.alert('Error', errorMsg, 'error')
                }

                errorCallback()

                // switch (res.xeroData.ValidationErrors) {
                // case "ERROR":

                // // case "WARNING":
                // // 	res.xeroData.Warnings.map((error, index)=>{
                // // 		// error;
                // // 		errorMsg += (index ? "<br>":"") + error.Message;
                // // 	});
                // // 	Service.alert("Error", errorMsg,"error");
                // // 	break;
                // default:
                // 	break;
                // }
              }
            } catch (error) {}

            // 저장된 employee data 를 update 한다.
            // PayRunAction.delete(payRun._id);
          },
          (err) => {
            if (err && err.code === 'setUpXeroAccount') {
              Service.confirm({
                title: "Couldn't export journals",
                contents:
                  "Journals couldn't be exported because your chart of accounts hasn't been set up.<br><br>Finish this setup and click the Export to Xero button again.",
                confirmText: 'Go to Xero Settings',
                cancelText: 'No',
                confirmCallback: function () {
                  if (closeModal) {
                    closeModal()
                  }
                  // $state.go("business.addons");
                  openXeroSettings()
                },
                cancelCallback: () => {}
              })
            } else if (
              err?.code == 'reconnect' ||
              err?.title === 'Xero authorization expired' ||
              err?.title === 'Xero session expired'
            ) {
              connectXero({})
            } else {
              Service.alert(err.title, err.message, 'error')
            }
            errorCallback(err)
          }
        )
      }

      function openXeroSettings() {
        Http(
          {
            method: 'get',
            api: '/v1/xero/accounts'
            // disableErrorMsg: true
          },
          (res) => {
            Service.modal(
              'xeroSetupModal.html',
              [
                '$scope',
                '$uibModalInstance',
                function ($scope, $uibModalInstance) {
                  $scope.cancel = function () {
                    $uibModalInstance.dismiss()
                  }

                  if (res.organisations[0].countryCode !== 'AU') {
                    $scope.isNotAustralianUser = true
                  }

                  if ($rootScope.company.xero && $rootScope.company.xero.accounts) {
                    $scope.xeroAccounts = angular.copy($rootScope.company.xero.accounts)
                  } else {
                    $scope.xeroAccounts = {
                      wageExpense: {
                        // Wages Expense
                        code: '',
                        name: ''
                      },
                      wagePayable: {
                        // Wages Payable
                        code: '',
                        name: ''
                      },
                      paygLiability: {
                        // PAYG liability
                        code: '',
                        name: ''
                      },
                      defaultPayrollLiability: {
                        // PAYG liability
                        code: '',
                        name: ''
                      },
                      superLiability: {
                        // Superannuation Liability
                        code: '',
                        name: ''
                      },
                      superExpense: {
                        // Superannuation Expense
                        code: '',
                        name: ''
                      },
                      employeeExpense: {
                        // Employee Expense Account (reimbursement)
                        code: '',
                        name: ''
                      }
                    }
                  }

                  $scope.postAsDraft = !!($rootScope.company.xero && $rootScope.company.xero.postAsDraft)
                  $scope.exportAs =
                    $rootScope.company.xero && $rootScope.company.xero.exportAs
                      ? $rootScope.company.xero.exportAs
                      : null

                  $scope.disconnectXero = () => {
                    Service.confirm({
                      title: 'Disconnect Xero',
                      contents: 'Are you sure you want to disconnect?',
                      confirmText: 'Yes!',
                      cancelText: 'No',
                      confirmCallback: function () {
                        $scope.loading = true
                        Http(
                          {
                            method: 'post',
                            api: '/v1/xero/disconnect'
                          },
                          (res) => {
                            $scope.loading = false

                            $rootScope.company.xero.tokenSet = {}
                            $rootScope.company.xero.accessToken = {}
                            $scope.cancel()
                          },
                          (err) => {
                            $scope.loading = false
                          }
                        )
                      },
                      cancelCallback: () => {}
                    })
                  }

                  function templateOptions(categories, objectName) {
                    return {
                      minimumChars: 0,
                      activateOnFocus: true,
                      maxItemsToRender: 1000,
                      containerCssClass: 'color-codes',
                      selectedTextAttr: 'Name',
                      itemTemplateUrl: 'views/autoComplete/charteredAccountAutoComplete.html',
                      noMatchTemplateEnabled: true,
                      // itemTemplate : $templateCache.get('color-item-template'),
                      data: function (searchText) {
                        searchText = searchText.toUpperCase()
                        return _.filter(res.accounts, function (liabilityItem) {
                          // Cannot export a payrun to a bank
                          if (liabilityItem.type === 'BANK') {
                            return false
                          }
                          if (liabilityItem.status !== 'ACTIVE') {
                            return false
                          }
                          if (categories.includes(liabilityItem._class)) {
                            return (
                              liabilityItem.name.toUpperCase().includes(searchText) ||
                              liabilityItem.code.includes(searchText)
                            )
                          } else {
                            return false
                          }
                        })
                      },
                      itemSelected: function (e) {
                        $scope.xeroAccounts[objectName] = {
                          code: e.item.code,
                          name: e.item.name,
                          codeName: e.item.code + ' - ' + e.item.name
                        }
                      }
                    }
                  }

                  $scope.autoCompleteOptions_paymentAccount = templateOptions(['ASSET', 'LIABILITY'], 'payment')
                  $scope.autoCompleteOptions_wagesPayable = templateOptions(['LIABILITY'], 'wagePayable')
                  $scope.autoCompleteOptions_wagesExpense = templateOptions(['EXPENSE'], 'wageExpense')
                  $scope.autoCompleteOptions_PAYGLiability = templateOptions(['LIABILITY'], 'paygLiability')
                  $scope.autoCompleteOptions_defaultPayrollLiability = templateOptions(
                    ['LIABILITY'],
                    'defaultPayrollLiability'
                  )
                  $scope.autoCompleteOptions_superLiability = templateOptions(['LIABILITY'], 'superLiability')
                  $scope.autoCompleteOptions_superExpense = templateOptions(['EXPENSE'], 'superExpense')
                  $scope.autoCompleteOptions_employeeExpense = templateOptions(['LIABILITY'], 'employeeExpense')

                  $scope.saveXeroSettings = () => {
                    var error = false
                    $scope.xeroAccountError = {}

                    if (
                      $scope.exportAs === 'manualJournal' &&
                      (!$scope.xeroAccounts.payment ||
                        !$scope.xeroAccounts.payment.code ||
                        !$scope.xeroAccounts.payment.name)
                    ) {
                      $scope.xeroAccountError.payment = true
                      error = true
                    }

                    if (
                      $scope.exportAs === 'accountPayable' &&
                      (!$scope.xeroAccounts.wagePayable ||
                        !$scope.xeroAccounts.wagePayable.code ||
                        !$scope.xeroAccounts.wagePayable.name)
                    ) {
                      $scope.xeroAccountError.wagePayable = true
                      error = true
                    }

                    if (
                      !$scope.xeroAccounts.wageExpense ||
                      !$scope.xeroAccounts.wageExpense.code ||
                      !$scope.xeroAccounts.wageExpense.name
                    ) {
                      $scope.xeroAccountError.wageExpense = true
                      error = true
                    }

                    if (
                      !$scope.xeroAccounts.paygLiability ||
                      !$scope.xeroAccounts.paygLiability.code ||
                      !$scope.xeroAccounts.paygLiability.name
                    ) {
                      $scope.xeroAccountError.paygLiability = true
                      error = true
                    }

                    if (
                      !$scope.xeroAccounts.defaultPayrollLiability ||
                      !$scope.xeroAccounts.defaultPayrollLiability.code ||
                      !$scope.xeroAccounts.defaultPayrollLiability.name
                    ) {
                      $scope.xeroAccountError.defaultPayrollLiability = true
                      error = true
                    }

                    if (
                      !$scope.xeroAccounts.superLiability ||
                      !$scope.xeroAccounts.superLiability.code ||
                      !$scope.xeroAccounts.superLiability.name
                    ) {
                      $scope.xeroAccountError.superLiability = true
                      error = true
                    }

                    if (
                      !$scope.xeroAccounts.superExpense ||
                      !$scope.xeroAccounts.superExpense.code ||
                      !$scope.xeroAccounts.superExpense.name
                    ) {
                      $scope.xeroAccountError.superExpense = true
                      error = true
                    }

                    if (
                      !$scope.xeroAccounts.employeeExpense ||
                      !$scope.xeroAccounts.employeeExpense.code ||
                      !$scope.xeroAccounts.employeeExpense.name
                    ) {
                      $scope.xeroAccountError.employeeExpense = true
                      error = true
                    }

                    if (!$scope.exportAs) {
                      $scope.xeroAccountError.exportAs = true
                      error = true
                    }

                    if (error) {
                      return false
                    }

                    $scope.loading = true

                    CompanyAction.update(
                      {
                        'xero.accounts': $scope.xeroAccounts,
                        'xero.postAsDraft': $scope.postAsDraft,
                        'xero.autoSync': $scope.autoSync,
                        'xero.exportAs': $scope.exportAs
                      },
                      () => {
                        $scope.loading = false
                        $scope.cancel()
                        Analytics.logEvent('Accounting Platform Setup Completed', {
                          provider: 'Xero'
                        })
                      },
                      () => {
                        $scope.loading = false
                      }
                    )
                  }

                  $scope.getInfo_defaultPayrollLiabilityAccount = () => {
                    Service.infoPopup({
                      title: 'Default Payroll Liability Account',
                      contents:
                        'This account is where deductions will be journaled if you have no specific liability account set up.'
                    })
                  }

                  $scope.getInfo_employeeExpenseAccount = () => {
                    Service.infoPopup({
                      title: 'Employee Expense Account',
                      contents:
                        'This account is where reimbursements will be journaled if you have no specific liability account set up.'
                    })
                  }
                }
              ],
              { size: 'md' }
            )
          },
          (err) => {
            if (err && err.code === 'reconnect') {
              connectXero()
            }
          }
        )
      }

      return {
        connectXero: connectXero,
        exportXero: exportXero,
        openXeroSettings: openXeroSettings
      }
    }
  )
