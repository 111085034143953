import { configureStore } from '@reduxjs/toolkit'
import appReducer from './reducers/appSlice'
import paywallReducer from './reducers/paywallSlice'
import newEmployeeReducer from './reducers/newEmployeeSlice'
import subscriptionReducer from './reducers/subscriptionSlice'
import superReminderReducer from './reducers/superReminderSlice'
import stpTransitionSlice from './reducers/stpTransitionSlice'
import rosteringReducer from './reducers/rosterSlice'
import superSummaryReducer from './reducers/superSummarySlice'
import { useDispatch } from 'react-redux'
import { surveyApi } from './api/survey'
import { calculatePriceApi, faqApi, paywallApi } from './api/paywall'
import { companyApi } from './api/company'
import { employeeApi } from './api/employee'
import { stpApi } from './api/stp'
import { reportApi } from './api/report'
import { setupListeners } from '@reduxjs/toolkit/query'
import { testApi } from './api/test'
import { referralApi } from './api/referral'

const store = configureStore({
  reducer: {
    app: appReducer,
    paywall: paywallReducer,
    newEmployee: newEmployeeReducer,
    superReminder: superReminderReducer,
    stpTransition: stpTransitionSlice,
    subscription: subscriptionReducer,
    rostering: rosteringReducer,
    superSummary: superSummaryReducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
    [paywallApi.reducerPath]: paywallApi.reducer,
    [calculatePriceApi.reducerPath]: calculatePriceApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [stpApi.reducerPath]: stpApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [testApi.reducerPath]: testApi.reducer,
    [referralApi.reducerPath]: referralApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    })
      .concat(surveyApi.middleware)
      .concat(paywallApi.middleware)
      .concat(faqApi.middleware)
      .concat(calculatePriceApi.middleware)
      .concat(companyApi.middleware)
      .concat(employeeApi.middleware)
      .concat(stpApi.middleware)
      .concat(reportApi.middleware)
      .concat(testApi.middleware)
      .concat(referralApi.middleware)
})

setupListeners(store.dispatch)

/** @return {typeof store.dispatch} */
export const useAppDispatch = () => useDispatch()

export default store
