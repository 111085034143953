import { setEmployees } from '/src/reducers/appSlice'

angular.module('starter.services').factory('EmployeeAction', function ($rootScope, Service, $ngRedux) {
  return {
    add: function (newEmployee) {
      var employeesTemp = angular.copy($rootScope.employees)
      employeesTemp.push(newEmployee)
      // resort
      employeesTemp.sort((obj1, obj2) => (obj1.f < obj2.f ? -1 : 1))
      $rootScope.employees = employeesTemp
      $ngRedux.dispatch(setEmployees(employeesTemp))
    },
    update: function (newEmployee) {
      var employeesTemp = angular.copy($rootScope.employees)

      for (var index = 0; index < employeesTemp.length; index++) {
        const element = employeesTemp[index]
        if (element._id === newEmployee._id) {
          employeesTemp[index] = newEmployee
        }
      }

      // resort
      employeesTemp.sort((obj1, obj2) => (obj1.f < obj2.f ? -1 : 1))
      $rootScope.employees = employeesTemp
      $ngRedux.dispatch(setEmployees(employeesTemp))
    },
    delete: function (employeeId) {
      var index = Service.findIndexByKeyValue($rootScope.employees, '_id', employeeId)
      $rootScope.employees.splice(index, 1)
      $ngRedux.dispatch(setEmployees($rootScope.employees))
    }
  }
})
