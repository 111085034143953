import React from 'react'
import { button, kindStyles } from './styles'
import { cx } from '../../utils'

export function Button({ children, kind = 'inactive', className, ...rest }) {
  return (
    <button className={cx(button, className, kindStyles[kind])} {...rest}>
      {children}
    </button>
  )
}
