import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useStorageWatcher } from '../components/GaInit'
import { isEmpty } from 'lodash'

/**
 * A hook to check localStorage & redux store has valid auth data for HTTP requests.
 */
export function useAuth() {
  const [ready, setReady] = useState(false)

  const user = useSelector((state) => state.app.user)
  const agent = JSON.parse(useStorageWatcher('pr_agent'))

  const prToken = useStorageWatcher('pr_token')
  const prCompany = useStorageWatcher('pr_company')
  useEffect(() => {
    // initial value of user is undefined or {}
    if (prToken && (prCompany || agent) && !isEmpty(user)) setReady(true)
  }, [prToken, prCompany, user])

  return { ready, user, agent }
}
