export function getSSID(softwareId) {
  const ssid_db = softwareId.toString()

  var sumOfSsid = 0
  for (var i = 0; i < ssid_db.length; i += 1) {
    sumOfSsid += parseInt(ssid_db[i])
  }
  var numOfZero = 9 - ssid_db.length

  var ssid = ''
  for (let index = 0; index < numOfZero; index++) {
    ssid += '0'
  }
  ssid += ssid_db + (sumOfSsid % 10).toString()
  return ssid
}
