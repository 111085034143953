angular.module('starter.services').factory('CompanyAction', function ($rootScope, Service, $window, Http) {
  return {
    update: function (updateObj, successCallback, errCallback, forceDisconnectAgent) {
      Http(
        {
          method: 'post',
          api: '/v1/company/update',
          data: {
            forceDisconnectAgent,
            company: updateObj
          }
        },
        (res) => {
          $rootScope.company = res.company
          if (successCallback) {
            successCallback(res)
          }
        },
        (err) => {
          if (errCallback) {
            errCallback(err)
          }
        }
      )
    },
    agentUpdate: function (updateObj, successCallback, errCallback) {
      // agent 자체 detail server 에서 사용하기 위해
      // localStorage.pr_client_id 를 삭제 하면 agent 자체 데이터를 사용할수 있다.
      // 이해가 안가면 validateRequest.js (server)를 볼 것
      delete $window.localStorage.pr_client_id

      Http(
        {
          method: 'post',
          api: '/v1/company/update',
          data: {
            company: updateObj
          }
        },
        (res) => {
          $rootScope.agent = res.company
          Service.alert('Success', 'Agent settings have been updated')

          if (successCallback) {
            successCallback(res)
          }
        },
        (err) => {
          if (errCallback) {
            errCallback(err)
          }
        }
      )
    }
  }
})
