module.exports = {
  presets: [require('tailwindconfig')],
  content: [
    './src/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './client/views/**/*.html',
    'node_modules/flowbite-react/**/*.{js,jsx,ts,tsx}'
  ]
}
