import React from 'react'
import { useSelector } from 'react-redux'
import { appConfig } from '@bookipi/utils'

import { SignUpAndVerify } from '@payroller/pds/client'

export default function Component({ state }) {
  const { loginSuccess, registerSuccess, stateParams, authData, analytics } = state
  const isTsrOnly = useSelector((state) => state.app.isTsrOnly)
  return (
    <SignUpAndVerify
      isTsrOnly={isTsrOnly}
      signUpVerifySuccessCb={async ({ data, result }) => {
        loginSuccess(result).then((r) => registerSuccess(data, result))
      }}
      authData={authData}
      stateParams={stateParams}
      analytics={analytics}
      googleAuth={appConfig.googleAuth}
    />
  )
}
