angular
  .module('starter.business.controllers')
  // .controller('AppCtrl', function($sce, $rootScope, $scope, $http, InvoiceService, API_ENDPOINT, $timeout, UserAuthFactory, $state, Service, $filter, InvoicePdfService, $ionicActionSheet, $window, $q, $cordovaInAppBrowser, InvoicePdf, UpdateDB, SubscriptionFactory){
  .controller('getStartedCtrl', function ($scope, $rootScope, Http, Service, $uibModal, $state, $timeout, DateService) {
    $scope.companyData = {
      workHours: '7.6',
      workStart: '0',
      payPeriod: 'f'
    }
    $scope.datePicker_payrollStartDate = new Date()
    $scope.datePicker_paymentDate = {}
    $scope.companyError = {}
    $scope.step = 1

    $scope.resetPaymentDate = function () {
      // get end date
      var endDate
      switch ($scope.companyData.payPeriod) {
        case 'w':
          endDate = moment($scope.datePicker_payrollStartDate).add(6, 'days')
          break
        case 'f':
          endDate = moment($scope.datePicker_payrollStartDate).add(13, 'days')
          break
        case 'm':
          endDate = moment($scope.datePicker_payrollStartDate).add(1, 'months').subtract(1, 'days')
          break
        case 'q':
          endDate = moment($scope.datePicker_payrollStartDate).add(3, 'months').subtract(1, 'days')
          break
        default:
          break
      }
      $scope.datePicker_payrollEndDate = endDate
      // $scope.datePicker_paymentDate.startDate = DateService.addWeekDays(endDate,3);
      $scope.datePicker_paymentDate = moment(DateService.addWeekDays(endDate, 3))
      // $scope.datePicker_paymentDate.startDate = moment(DateService.addWeekDays(endDate,3));
    }

    $scope.resetPaymentDate()

    $scope.checkABN = function () {
      if (!$scope.abn_temp) {
        Service.alert(null, 'ABN is required')
        return false
      }
      const abn = $scope.abn_temp.replace(/\s/g, '')
      if (!abn || abn.length !== 11) {
        Service.alert(null, 'ABN is invalid')
        return false
      }

      Http(
        {
          method: 'get',
          api: '/v1/check-abn/' + abn,
          disableErrorMsg: true,
          forceSend: true
        },
        (res) => {
          if (!res.Abn || res.Message) {
            Service.alert(null, res.Message)
            return false
          }

          $scope.companyData.abn = abn
          $scope.companyData.legalName = res.EntityName
          $scope.companyData.businessName = res.BusinessName[0] || res.EntityName
          $scope.companyData.postcode = res.AddressPostcode
          $scope.companyData.state = res.AddressState
        },
        (_) => {}
      )
    }

    $scope.completeCompanyDetail = function () {
      // form validation
      $scope.companyError = {}
      var error = false
      if ($scope.useWPN) {
        if (
          !$scope.companyData.wpn ||
          !/^[0-9-]{8,11}$/i.test($scope.companyData.wpn) ||
          $scope.companyData.wpn.substring(0, 2) != '00' ||
          !Service.TFNCheck($scope.companyData.wpn.substring(2))
        ) {
          Service.alert('Error!', 'WPN is invalid', 'error')
          $scope.companyError.wpn = true
          error = true
        }
        if (!$scope.companyData.c) {
          $scope.companyError.c = true
          error = true
        }
        $scope.companyData.abn = null
      } else {
        if (!$scope.companyData.abn) {
          $scope.companyError.abn = true
          error = true
        }
        $scope.companyData.wpn = null
      }

      if (!$scope.companyData.phone) {
        $scope.companyError.phone = true
        error = true
      }
      if (!$scope.companyData.address1) {
        $scope.companyError.address1 = true
        error = true
      }
      if (!$scope.companyData.suburb) {
        $scope.companyError.suburb = true
        error = true
      }
      if (!$scope.companyData.postcode) {
        $scope.companyError.postcode = true
        error = true
      }

      if (error) {
        return false
      } else {
        $scope.step = 2
      }
    }

    $scope.completePayrollDetail = function () {
      var startDate = new Date($scope.datePicker_payrollStartDate)

      var payDateDiff = DateService.businessDiff($scope.datePicker_payrollEndDate, $scope.datePicker_paymentDate)

      // form validation
      $scope.companyError = {}
      if ($scope.companyData.workHours == '0' && !$scope.companyData.workHours_other) {
        $scope.companyError.workHours_other = true
        return false
      }

      // return false;
      // 서버에 데이터를 보낸다.
      Http(
        {
          method: 'post',
          api: '/v1/company/update',
          data: {
            company: {
              abn: $scope.companyData.abn || null,
              wpn: $scope.companyData.wpn || null,
              c: $scope.companyData.legalName,
              ph: $scope.companyData.phone,
              a_1: $scope.companyData.address1,
              a_2: $scope.companyData.address2,
              a_c: $scope.companyData.suburb,
              a_s: $scope.companyData.state,
              a_p: $scope.companyData.postcode,
              wh:
                $scope.companyData.workHours == '0' ? $scope.companyData.workHours_other : $scope.companyData.workHours, // if work hours is 0 then get workHours_other
              sd: startDate,
              pp: $scope.companyData.payPeriod,
              pd_df: payDateDiff
            }
          },
          forceSend: true
        },
        (res) => {
          $rootScope.company = res.company
          $timeout(() => {
            $('#get-stared-button').slideUp()
          }, 100)
          // $rootScope.user = res.user;
          $state.go('business.run.overview')
          Service.modal('companyRegistrationSuccessModal.html')
          $scope.getInitialData()
        },
        (_) => {}
      )
    }

    $scope.query = ''
    $scope.paOptions = {
      updateModel: true
    }
    $scope.paTrigger = {}
    $scope.paDetails = {}
    $scope.placesCallback = function (error, details) {
      if (error) {
        return console.error(error)
      }
      $scope.paDetails = details
    }
  })
