angular.module('starter.services').factory('BetaTestService', function () {
  let hexToInt = (s) => {
    let i
    let j
    let digits = [0]
    let carry
    for (i = 0; i < s.length; i += 1) {
      carry = parseInt(s.charAt(i), 16)
      for (j = 0; j < digits.length; j += 1) {
        digits[j] = digits[j] * 16 + carry
        carry = (digits[j] / 10) | 0
        digits[j] %= 10
      }
      while (carry > 0) {
        digits.push(carry % 10)
        carry = (carry / 10) | 0
      }
    }
    return parseInt(digits.reverse().join(''))
  }

  let idToRand = (id) => {
    let hex = hexToInt(id)
    return (hex % 1e2) / 1e2
  }

  let isBeta = (id) => {
    if (!id) {
      return false
    }
    return idToRand(id) <= 1.0
  }

  return {
    isBeta
  }
})
