import { createSlice } from '@reduxjs/toolkit'
import { getInitial } from '../scheduling/react-subscription/utils/share'
import { filterActiveEmployees } from '../utils/filterEmployees'

export const STEPS = ['Available plans', 'Payment summary']

const paywallSlice = createSlice({
  name: 'paywall',
  initialState: {
    step: 0,
    period: 'year',
    seats: 1,
    tokenizeStatus: 'init', // init, pending, error, success
    tokenReference: '',
    paymentMethod: null
  },
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload
    },
    setPeriod: (state, action) => {
      state.period = action.payload
    },
    setSeats: (state, action) => {
      state.seats = action.payload
    },
    setTokenizeStatus: (state, action) => {
      state.tokenizeStatus = action.payload.status
      if (action.payload.status === 'success') {
        state.tokenReference = action.payload.reference
      } else {
        state.tokenReference = ''
      }
      if (action.payload.paymentMethod) {
        state.paymentMethod = action.payload.paymentMethod
      }
    },
    removeStoredPaymentmethod: (state) => {
      state.paymentMethod = null
      state.tokenizeStatus = 'init'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInitial.fulfilled, (state, action) => {
      const numOfActiveEmployees = filterActiveEmployees(action.payload.employees)
      state.seats = numOfActiveEmployees || 1
    })
  }
})

export const { setStep, setPeriod, seats, setSeats, setTokenizeStatus, removeStoredPaymentmethod, setInitSeats } =
  paywallSlice.actions
export default paywallSlice.reducer
