import { createSlice } from '@reduxjs/toolkit'
import { setInitialState } from './appSlice'
import {
  updateSubscription,
  getSubscriptionStatus,
  getInitial,
  agentInit,
  setModalSubscription
} from '../scheduling/react-subscription/utils/share'
import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import { paywallApi } from '../api/paywall'

function handleSubsriptionStatus(state, status) {
  let dateSubscriptionTrial = 0
  let haveReceivedPayments =
    status?.webapp?.payments && status?.webapp?.payments.some((p) => p.type !== 'trial' && p.received > 0)
  if (status?.webapp?.trial) {
    dateSubscriptionTrial = moment(moment.utc(status?.webapp?.trial?.end).format())
      .tz('Australia/Sydney')
      .diff(moment.utc().format(), 'days')
  }

  state.isSubscribed = status.webapp.isValid
  state.subscriptionError = status.webapp.error
  state.errorCode = status.webapp.code || status.webapp.paymentErrorMessage
  state.cardInfo = status.webapp.paymentMethod?.card
  state.isTrialOnly =
    Boolean(status?.webapp?.trial?.end && status.webapp.status === 'cancelled') ||
    (status?.webapp?.trial?.end && status?.webapp?.trial?.end === status.webapp?.end)
  if (dateSubscriptionTrial > 0) {
    state.dateTrial = dateSubscriptionTrial
  } else if (!haveReceivedPayments && status?.webapp?.trial?.end) {
    state.dateTrial = 1
  } else {
    state.dateTrial = 0
  }
}
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    isTrialOnly: false,
    listAgentClient: [], // all agent's customers
    currentClient: null,
    savedDataPoint: null,
    cardInfo: null,
    pricingPlan: null, // current calculated Price follow seats and webapp
    anchorElPopover: null, // properties popover
    idPopover: undefined, // properties popover
    disabled: false,
    openModalSubscription: false,
    openModalFromOnBoarding: false,
    openModalAgentSubscription: false,
    isSubscribed: false,
    subscriptionError: '',
    errorCode: '',
    hideBanner: false,
    openModalInviteYourEmployee: false,
    openModalUpdateSubscription: false,
    tokenizeStatus: 'init', // init, pending, error, success
    tokenReference: '',
    spotIsFull: false,
    listInvoices: [],
    isInviteClientPopup: false,
    dateTrial: 0,
    showCardDetail: false,
    paymentMethod: null,
    isAddSeatsPopup: false,
    missingEmployee: 0
  },
  reducers: {
    setSubscriptionState: (state, action) => {
      state.listAgentClient = _.cloneDeep(action.payload)
    },
    setCurrentClient: (state, action) => {
      state.currentClient = _.cloneDeep(action.payload)
    },
    setSavedDataPoint: (state, action) => {
      state.savedDataPoint = _.cloneDeep(action.payload)
    },
    setCardInfo: (state, action) => {
      state.cardInfo = action.payload
    },
    setPricingPlan: (state, action) => {
      state.pricingPlan = action.payload
    },
    setAnchorElPopover: (state, action) => {
      state.anchorElPopover = action.payload
      if (action.payload) {
        state.idPopover = 'simple-popover'
      } else {
        state.idPopover = undefined
      }
    },
    setDisabled: (state, action) => {
      state.disabled = action.payload
    },
    setInvoices: (state, action) => {
      state.listInvoices = action.payload
    },
    setModalFromOnBoarding: (state, action) => {
      state.openModalFromOnBoarding = action.payload
    },
    setModalAgentSubscription: (state, action) => {
      state.openModalAgentSubscription = action.payload
      state.tokenizeStatus = 'init'
    },
    setOpenModalUpdateSubscription: (state, action) => {
      state.openModalUpdateSubscription = action.payload
      state.tokenizeStatus = 'init'
    },
    setOpenModalInviteYourEmployee: (state, action) => {
      state.openModalInviteYourEmployee = action.payload
    },
    hideSubBanner: (state) => {
      state.hideBanner = true
    },
    setDisplayInviteClient: (state, action) => {
      state.isInviteClientPopup = action.payload
    },
    setTokenizeStatus: (state, action) => {
      state.tokenizeStatus = action.payload.status
      if (action.payload.status === 'success') {
        state.tokenReference = action.payload.reference
      } else {
        state.tokenReference = ''
      }
      if (action.payload.paymentMethod) {
        state.paymentMethod = action.payload.paymentMethod
      }
    },
    removeStoredPaymentmethod: (state) => {
      state.paymentMethod = null
      state.tokenizeStatus = 'init'
    },
    setShowCardDetail: (state, action) => {
      state.showCardDetail = action.payload
    },
    setDisplayAddSeats: (state, action) => {
      state.isAddSeatsPopup = action.payload
    },
    setEmployeeMissing: (state, action) => {
      state.missingEmployee = action.payload
    },
    setIsSubscribed: (state, action) => {
      state.isSubscribed = action.payload
    },
    setFreeTrialSubscription: (state) => {
      state.isSubscribed = true
      state.dateTrial = 14
      state.isTrialOnly = true
      state.hideBanner = false
      state.errorCode = ''
      state.subscriptionError = ''
    },
    setSubscribed: (state) => {
      state.isSubscribed = true
      state.isTrialOnly = false
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialState, (state, action) => {
        if (!action.payload || (action?.payload?.user?.userType === 'employee' && process.env.APP === 'people')) {
          return
        }
        const status = action.payload.subscriptionStatus
        handleSubsriptionStatus(state, status)
      })
      .addCase(getInitial.fulfilled, (state, action) => {
        const status = action.payload.subscriptionStatus
        handleSubsriptionStatus(state, status)
        if (action.payload.company?.ai) {
          state.currentClient = _.cloneDeep(action.payload.company)
          state.currentClient.subscriptionStatus = _.cloneDeep(action.payload.subscriptionStatus)
        }
      })
      .addCase(getSubscriptionStatus.fulfilled, (state, action) => {
        handleSubsriptionStatus(state, action.payload.subscriptionStatus)
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        handleSubsriptionStatus(state, {
          webapp: {
            isValid: action.payload.subscription[0],
            code: action.payload.subscription[1].code || '',
            error: action.payload.subscription[1].error || '',
            ...action.payload.subscription[1]
          }
        })

        state.isTrialOnly = false // after a successful subscription we shouldn't be trialOnly anymore
        if (state.currentClient && state.currentClient.subscriptionStatus) {
          Object.assign(state.currentClient.subscriptionStatus.webapp, {
            isValid: action.payload.subscription[0],
            code: action.payload.subscription[1].code || '',
            error: action.payload.subscription[1].error || '',
            ...action.payload.subscription[1]
          })
        }
        if (state.listAgentClient && state.currentClient) {
          let idx = state.listAgentClient.findIndex((client) => client._id === state.currentClient._id)
          if (idx >= 0) {
            state.listAgentClient[idx] = state.currentClient
          }
        }
        if (state.savedDataPoint) {
          state.savedDataPoint = state.currentClient
        }
      })
      .addCase(agentInit.fulfilled, (state, action) => {
        state.listAgentClient = _.cloneDeep(action.payload.clients)
      })
      .addMatcher(paywallApi.endpoints.subscribe.matchFulfilled, (state, action) => {
        if (!action.payload) {
          return
        }
        const webapp = {
          isValid: action.payload.subscription[0],
          ...action.payload.subscription[1]
        }
        handleSubsriptionStatus(state, { webapp })
      })
    // to show the existing paywall modal
  }
})

export const {
  setSubscriptionState,
  setDisplayInviteClient,
  setCurrentClient,
  setSavedDataPoint,
  setInvoices,
  setCardInfo,
  setPricingPlan,
  setAnchorElPopover,
  setDisabled,
  setModalFromOnBoarding,
  setTokenizeStatus,
  hideSubBanner,
  setOpenModalUpdateSubscription,
  setOpenModalInviteYourEmployee,
  setShowCardDetail,
  removeStoredPaymentmethod,
  setAllEmployee,
  setDisplayAddSeats,
  setEmployeeMissing,
  setModalAgentSubscription,
  setIsSubscribed,
  setFreeTrialSubscription,
  setSubscribed,
  setPaymentMethod
} = subscriptionSlice.actions
export const stateSubscription = (state) => state.subscription
export const listAgentClientNotEnoughRoom = createSelector(
  // agent's customers who is not registered
  (state) => state.subscription.listAgentClient,
  (list) => list.filter((client) => !client?.subscriptionStatus?.webapp?.isValid)
)
export default subscriptionSlice.reducer
