angular
  .module('starter.agent.controllers')
  .controller('resellerOverviewCtrl', function ($scope, $stateParams, Http, $window, Service) {
    $scope.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']
    $scope.series = ['Series A']
    $scope.data = [[10002, 15402, 22402, 20002, 28000, 30402, 32402]]
    $scope.onClick = function (points, evt) {
      console.log(points, evt)
    }
    $scope.datasetOverride = [{ yAxisID: 'y-axis-1' }]
    $scope.options = {
      scales: {
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            display: true,
            position: 'left'
          }
          // {
          // 	id: "y-axis-2",
          // 	type: "linear",
          // 	display: true,
          // 	position: "right"
          // }
        ]
      }
    }
  })
