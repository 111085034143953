import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone'

const appSlice = createSlice({
  name: 'rostering',
  initialState: {
    startWindow: null,
    endWindow: null,
    filteredLeaves: []
  },
  reducers: {
    setFilteredLeaves: (state, action) => {
      state.filteredLeaves = action.payload
    },
    setWindows: (state, action) => {
      // NO-15263 Change to ISOString to serialise time
      state.startWindow = moment(action.payload.start).toISOString()
      state.endWindow = moment(action.payload.end).toISOString()
    }
  }
})

export const { setFilteredLeaves, setWindows } = appSlice.actions
export default appSlice.reducer
