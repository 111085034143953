import React from 'react'
import CardExtend from './comps/CardExtend'
import { useGetFaqQuery } from '../../../../../api/paywall'
import * as s from './styles'
import { usePaywall } from './usePaywall'

export const FAQ = React.forwardRef((props, ref) => {
  const { discount, discountCode } = usePaywall()
  let apiUrl = 'subscription'
  if (discountCode) {
    const isNoDiscount = !discount
    if (isNoDiscount) {
      apiUrl = 'subscription_no_discount'
    } else {
      apiUrl = 'subscription_1m_discount'
    }
  }
  const { data } = useGetFaqQuery(apiUrl)
  return (
    <div className={s.FAQContainer}>
      <h2 className={s.FAQtitle}>Frequently Asked Questions</h2>
      {/* add a mock div for the anchor position of long FAQ */}
      <div ref={ref} />
      <div className={s.FAQItems}>
        {data?.map((faq) => (
          <CardExtend key={faq.key} id={faq.key} title={faq.title} content={faq.content} />
        ))}
      </div>
    </div>
  )
})
