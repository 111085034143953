angular.module('starter.home.controllers', []).controller('HomeCtrl', function ($scope, $state, $window, $timeout) {
  $scope.closeNavBar = function () {
    if ($('.fancynavbar-toggler').hasClass('collapsed')) {
      $('.fancynavbar-toggler').trigger('click')
    }
  }
  $scope.owlOptionsTestimonials = {
    autoPlay: 4000,
    stopOnHover: true,
    slideSpeed: 300,
    paginationSpeed: 600,
    items: 2
  }
})
