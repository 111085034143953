import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const endpoint = process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE

export const surveyApi = createApi({
  reducerPath: 'survey',
  baseQuery: fetchBaseQuery({ baseUrl: endpoint }),
  endpoints: (build) => ({
    new: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/survey',
        method: 'POST',
        body: patch
      })
    })
  })
})

export const { useMutation } = surveyApi.endpoints.new
