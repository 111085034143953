angular.module('starter.directives').directive('loading', function ($compile) {
  return {
    restrict: 'E',
    scope: {
      items: '='
    },
    link: function (scope, element, attrs) {
      var html =
        '<div class="loading-boxes"> <div class="box"> <div></div><div></div><div></div><div></div></div><div class="box"> <div></div><div></div><div></div><div></div></div><div class="box"> <div></div><div></div><div></div><div></div></div><div class="box"> <div></div><div></div><div></div><div></div></div></div>'
      var e = $compile(html)(scope)
      element.replaceWith(e)
    }
  }
})
