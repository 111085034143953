import React from 'react'
import { useModal } from '../../../../hooks'
import { Button } from '@payroller/pds'

export function OnboardingVideos(props) {
  const { data } = props

  if (!data && data?.url) return null
  const { hideModal } = useModal()
  const hide = () => hideModal('OnboardingVideos')

  return (
    <div className="flex flex-column items-center">
      <iframe
        className="md:w-[95vw] w-[70vw] md:h-[55vw] h-[40vw] md:mt-[40%] mt-[3%]"
        src={data?.url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <div className="pt-2">
        <Button onClick={hide} variant="secondary" text="Close" />
      </div>
    </div>
  )
}
