const superAnnuationQuarters = (from, to) => ({
  Q1: {
    start: moment.utc('07/01/' + from),
    end: moment.utc('09/30/' + from),
    textDisplay: `Q1 ${from}-${to}
        1 Jul ${from} - 30 Sep ${from}`,
    year: `${from}-${to}`,
    q: 'Q1'
  },
  Q2: {
    start: moment.utc('10/01/' + from),
    end: moment.utc('12/31/' + from),
    textDisplay: `Q2 ${from}-${to}
        1 Oct ${from} - 31 Dec ${from}`,
    year: `${from}-${to}`,
    q: 'Q2'
  },
  Q3: {
    start: moment.utc('01/01/' + to),
    end: moment.utc('03/31/' + to),
    textDisplay: `Q3 ${from}-${to}
        1 Jan ${to} - 31 Mar ${to}`,
    year: `${from}-${to}`,
    q: 'Q3'
  },
  Q4: {
    start: moment.utc('04/01/' + to),
    end: moment.utc('06/30/' + to),
    textDisplay: `Q4 ${from}-${to}
        1 Apr ${to} - 30 Jun ${to}`,
    year: `${from}-${to}`,
    q: 'Q4'
  }
})

export function getQuarter(date) {
  const year = date.year()
  let from = year
  let to = year

  const isFirstHalf = date.isBetween(moment.utc('01/01/' + year), moment.utc('06/30/' + year), 'days', '[]')
  const isSecondHalf = date.isBetween(moment.utc('07/01/' + year), moment.utc('12/31/' + year), 'days', '[]')

  if (isFirstHalf) {
    from -= 1
  } else if (isSecondHalf) {
    to += 1
  }

  const quarters = superAnnuationQuarters(from, to)

  let res
  for (const [key, value] of Object.entries(quarters)) {
    if (date.isBetween(value.start, value.end, 'days', '[]')) res = key
  }
  if (res) return quarters[res]
}
