import React, { useState } from 'react'
import { Login } from '@payroller/pds/client'
import { appConfig } from '@bookipi/utils'
import { isTsrOnly } from '../../controllers/consts'

export default function Component({ state }) {
  const [loading, setLoading] = useState(false)
  const onSubmit = async (data) => {
    setLoading(true)
    state.doLogIn(null, null, data, () => setLoading(false))
  }
  return (
    <Login
      onSubmit={onSubmit}
      buttonVariant={loading ? 'primary / loading' : 'primary'}
      onClickForgotPassword={() => state.goToForgotPassword()}
      onClickSignUp={() => state.go('signup')}
      googleAuth={appConfig.googleAuth}
      isTsrOnly={isTsrOnly}
    />
  )
}
